import { useEffect, useState } from "react";
import axios from "../../utils/axiosconfig";
import {
  Avatar,
  Typography,
  Box,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
} from "@mui/material";
import c_circle from "../../images/call-circle.svg";
import m_circle from "../../images/message-circle.svg";
import bill from "../../images/bill.png";
import larrow from "../../images/arrow-left.svg";
import invoice from "../../images/invoiceIcon.svg";
import Status from "../../images/Vectors.png";
import Three_Dots from "../../images/Three_Dots.svg";
import { useSnackbar } from "notistack";
import Conformation from "../Conformation";
import { Convert, ConvertDt, getDateTime } from "src/utils/methods";
const BookingDetailsTab = (props) => {
  const hidePayment = () => setShow(true);
  const [show, setShow] = useState(true);
  const [packageTypes, setPackageTypes] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [cancelReason, setCancelReason] = useState(null);
  const [rate, setRate] = useState(null);
  const [openConf, setOpenConf] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let totalPrice = 0;
  const {
    setReloadData,
    bookingDetailsPage,
    user,
    booking,
    currentDate,
    yesterday,
    tomorrow,
  } = props.data;
  useEffect(() => {
    getRate();
    getReasons();
  }, []);
  const getRate = () => {
    const payload = {};
    axios
      .post(`/rates/get/all`, payload)
      .then((res) => {
        const obj = res.data.result[0];
        if (obj) setRate(obj);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getReasons = async () => {
    await axios
      .get("/reasons/get")
      .then((res) => {
        setReasons(res.data.result || []);
      })
      .catch((err) => {
        console.log("Reasons data fetch error", err);
      });
  };
  useEffect(() => {
    if (booking) {
      const inst = [];
      const packageTypesData = [];
      booking?.tasksList?.map((task) => {
        if (task?.instruction) inst.push(task?.instruction);
        if (task?.packageType) packageTypesData.push(task?.packageType);
        return null;
      });
      setPackageTypes(packageTypesData);
    }
  }, [booking]);

  const generateMapLink = (location) => {
    if (!location) return "";
    const { _lat, _long } = location;
    const baseUrl = "https://www.google.com/maps";
    const query = `q=${_lat},${_long}`;
    return `${baseUrl}?${query}`;
  };

  const openPay = () => {
    setShow(false);
  };

  const handleClickEvent = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    const scheduleTime = `⏰ ${getDateTime(
      booking?.scheduleDateTime
    )}, ${Convert(booking?.scheduleDateTime)}`;
    let formattedText = "";
    if (booking?.tasksList?.length === 1) {
      const dropObj = booking?.tasksList[0]?.endDest;
      const dropAddress = ` *Drop* - ${dropObj?.address || ""},${
        dropObj.landmark
          ? dropObj?.landmark?.replace(
              /(\w)(\w*)/g,
              (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
            )
          : "no landmark"
      }`;
      const dropPhone = `☎️ ${dropObj?.mobile || ""}`;
      const mapLinkDropDestination = generateMapLink(dropObj?.location);
      formattedText = `*${booking?.bookingNumber || ""} - ${
        user?.display_name ? user?.display_name : ""
      } ${scheduleTime}*
      ❗${booking?.message || ""}
  
  ${dropAddress}
  ${dropPhone}
  📍${mapLinkDropDestination}
     `;
    } else {
      const pickupObj = booking?.tasksList[1]?.startDest;
      const dropObj = booking?.tasksList[1]?.endDest;
      const mapLinkPickupDestination = generateMapLink(pickupObj?.location);
      const mapLinkDropDestination = generateMapLink(dropObj?.location);
      const pickupAddress = `*Pickup* - ${pickupObj?.address || ""}, ${
        pickupObj?.landMark
          ? pickupObj?.landMark?.replace(
              /(\w)(\w*)/g,
              (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
            )
          : "no landmark"
      }`;
      const pickupPhone = `☎️ ${pickupObj?.mobile || ""}`;
      const dropAddress = ` *Drop* - ${dropObj?.address || ""}, ${
        dropObj?.landMark
          ? dropObj?.landMark?.replace(
              /(\w)(\w*)/g,
              (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
            )
          : "no landmark"
      }`;
      const dropPhone = `☎️ ${dropObj?.mobile || ""}`;
      formattedText = `*${booking?.bookingNumber || ""} - ${
        user?.display_name
      } ${scheduleTime}*
      ${booking?.message ? `! ${booking?.message}` : ""}
  ${pickupAddress}
  ${pickupPhone}
  📍${mapLinkPickupDestination}
  
  ${dropAddress}
  ${dropPhone}
  📍${mapLinkDropDestination}
     `;
    }

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        enqueueSnackbar("Copied to clipboard.", {
          style: { marginTop: "100px" },
        });
        console.log("Link copied to clipboard:", formattedText);
      })
      .catch((error) => {
        console.log("Failed to copy link:", error);
      });
    handleClose();
  };

  const copyText = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar("Copied to clipboard.", {
          style: { marginTop: "100px" },
        });
      })
      .catch((error) => {
        console.log("Failed to copy link:", error);
      });
  };
  const getPrice = (booking, task) => {
    const baseKm = rate?.baseKm;
    const perKm = rate?.distanceRate;
    let perPoint = rate?.nonFragilePerPointRate;
    if (task?.packageType?.fragile) perPoint = rate?.fragilePerPointRate;
    let distance = Math.ceil(task?.distance / 1000);
    if (distance < baseKm) distance = baseKm;
    const taskPrice =
      distance * perKm + (booking?.tasksList?.length - 1) * perPoint;
    totalPrice = totalPrice + taskPrice;
    return taskPrice ? taskPrice : 0;
  };

  const statusChip = () => {
    if (booking?.stageData?.stage) {
      return (
        <Box
          className="statusChip"
          sx={{
            backgroundColor: "#75CEF8",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "7px",
            padding: "7px",
            marginBottom: "4px",
          }}
        >
          <Box>
            <img src={Status} alt="" />
          </Box>

          <Box>
            <Typography
              style={{
                color: "black",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              {booking?.stageData?.stage}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return (
        <>
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="5vw"
            height="4vh"
            sx={{
              marginBottom: "5px",
            }}
          />
        </>
      );
    }
  };
  const timeDetail = () => {
    if (booking?.scheduleDateTime) {
      return (
        <strong>
          {Convert(booking?.scheduleDateTime) || ""},&nbsp;
          {ConvertDt(booking?.scheduleDateTime) === ConvertDt(currentDate)
            ? "Today"
            : ConvertDt(booking?.scheduleDateTime) === ConvertDt(tomorrow)
            ? "Tomorrow"
            : ConvertDt(booking?.scheduleDateTime) === ConvertDt(yesterday)
            ? "Yesterday"
            : ConvertDt(booking?.scheduleDateTime) || ""}
        </strong>
      );
    } else {
      return (
        <>
          <Box
            sx={{
              width: "8vw",
            }}
          >
            <Typography variant="body1">
              <Skeleton variant="text" sx={{ fontSize: "0.83vw" }} />
            </Typography>
          </Box>
        </>
      );
    }
  };

  const cancelBooking = async () => {
    if (cancelReason === null) {
      enqueueSnackbar("Cancel Reason is Required.", {
        variant: "error",
        style: { marginTop: "100px" },
      });
      return;
    }
    handleClose();
    setOpenConf(false);
    if (booking?._id != null) {
      await axios
        .post(`/bookings/cancel-booking/${booking?._id}`, {
          cancelReason: cancelReason,
        })
        .then((res) => {
          // setReloadData(true);
          setCancelReason(null);
          enqueueSnackbar("Booking Canceled Successfully.", {
            style: { marginTop: "100px" },
          });
        })
        .catch((err) => {
          setCancelReason(null);
          console.log("booking data fetch error", err);
        });
    }
  };

  const getUserInfo = () => {
    if (user) {
      const totalDistance =
        booking["tasksList"]?.reduce((total, task) => {
          if (task.order) return total + (task?.distance || 0);
          else return total;
        }, 0) / 1000;

      return (
        <Box>
          <Conformation
            open={openConf}
            onClose={() => {
              setOpenConf(false);
              handleClose();
            }}
            cancelBooking={true}
            setCancelReason={setCancelReason}
            cancelReasons={reasons}
            handleSubmit={cancelBooking}
            title={`Cancel Booking Confirmation`}
            subTitle={`Do you want to cancel booking?`}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "6px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Avatar
                className="avtar"
                alt={user?.display_name}
                src={user?.photo_url}
                style={{
                  height: "60px",
                  width: "60px",
                  marginRight: "9px",
                }}
              />
              <Box
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  className="sameSizeP displayName"
                  style={{
                    color: "#002236",
                    fontWeight: 800,
                    lineHeight: 1.4,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {user?.display_name?.replace(
                    /(\w)(\w*)/g,
                    (_, g1, g2) => g1?.toUpperCase() + g2?.toLowerCase()
                  ) || ""}
                </Typography>
                <Typography
                  className="sameSizeP"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    fontWeight: 400,
                    lineHeight: 1.4,
                    fontFamily: "Inter,sans-serif",
                  }}
                >
                  {booking?.bookingNumber || ""}
                </Typography>
                <Typography
                  className="sameSizeP"
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    lineHeight: 1.4,
                    fontWeight: 400,
                    fontFamily: "Inter,sans-serif",
                  }}
                >
                  {user?.phone_number || ""}
                </Typography>
                <Typography
                  sx={{
                    color: "#9D9E9E",
                    fontSize: "8px",
                    fontWeight: 500,
                    fontFamily: "Inter,sans-serif",
                  }}
                >
                  {ConvertDt(booking?.created)} | {Convert(booking?.created)}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "5px",
                flexDirection: "column",
                marginLeft: "auto",
                alignItems: "flex-end",
              }}
            >
              <Box
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <Box>
                  <Typography
                    className="totalDistance"
                    sx={{ fontSize: "16px" }}
                  >
                    <strong>{totalDistance?.toFixed(1) || "0"} km</strong>
                  </Typography>
                </Box>
                <Tooltip title={"Copy mobile number"} placement="bottom">
                  <Box
                    className="icnBtns"
                    onClick={() => copyText(user?.phone_number)}
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      background: "#E3552E",
                      cursor: "pointer",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={m_circle}
                      alt=""
                      style={{
                        position: "absolute",
                        height: "50%",
                        width: "50%",
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={"Invoice details"} placement="bottom">
                  <Box
                    className="icnBtns"
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "50%",
                      background: "#E3552E",
                      cursor: "pointer",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => openPay()}
                  >
                    <img
                      src={bill}
                      alt=""
                      style={{
                        position: "absolute",
                        height: "60%",
                        width: "60%",
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box sx={{ marginTop: "auto" }}>{statusChip()}</Box>

              {/* <Tooltip title={"Booking's chat"} placement="bottom">
                <Box className="icnBtns"
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    background: "#E3552E",
                    cursor: "pointer",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={c_circle}
                    alt=""
                    style={{
                      position: "absolute",
                      height: "50%",
                      width: "50%",
                    }}
                  />
                </Box>
              </Tooltip> */}
            </Box>
          </Box>

          <Typography
            className="statusMsg"
            style={{
              marginBottom: "6px",
              color: "#141414",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            CURRENT STATUS :
            <span
              style={{
                color: "#141414",
                fontWeight: "700",
              }}
            >
              {" "}
              {booking?.stageData?.message}
            </span>
          </Typography>
          <Box sx={{ background: "#F9F9F9", p: 2, borderRadius: "12px" }}>
            <Typography
              className="statusMsg"
              style={{
                color: "#e3552e",
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "'Cabin', sans-serif",
                paddingBottom: "2px",
              }}
            >
              Instructions
            </Typography>
            <Typography
              className="statusMsg"
              style={{
                color: "#9D9E9E",
                fontSize: "14px",
                fontWeight: "700",
                fontFamily: "'Cabin', sans-serif",
              }}
            >
              Ground floor, Esplanade One Mall, Bhubaneswar,
            </Typography>
          </Box>
          {booking?.cancelReason?.length > 0 && (
            <Typography
              style={{
                marginTop: "10px",
                color: "#141414",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              CANCEL REASON :
              <span
                style={{
                  color: "#141414",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                {" "}
                {booking?.cancelReason}
              </span>
            </Typography>
          )}
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
          >
            <Skeleton
              variant="circular"
              animation="wave"
              width={60}
              height={60}
              sx={{ marginRight: "15px" }}
            />
            <Box
              sx={{
                flexDirection: "column",
                width: "40%",
              }}
            >
              <Typography variant="body1">
                <Skeleton
                  variant="text"
                  width="40%"
                  sx={{ fontSize: "0.83vw" }}
                />
              </Typography>
              <Typography variant="body2">
                <Skeleton
                  variant="text"
                  width="45%"
                  sx={{ fontSize: "0.83vw" }}
                />
              </Typography>
              <Typography variant="body1">
                <Skeleton
                  variant="text"
                  width="70%"
                  sx={{ fontSize: "0.83vw" }}
                />
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                gap: "5px",
              }}
            >
              <Skeleton
                variant="circular"
                animation="wave"
                width="2.08vw"
                height="2.08vw"
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width="2.08vw"
                height="2.08vw"
              />
              <Skeleton
                variant="circular"
                animation="wave"
                width="2.08vw"
                height="2.08vw"
              />
            </Box>
          </Box>

          <Box sx={{}}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="100%"
              height="7vh"
              sx={{
                borderRadius: "10px",
              }}
            />
          </Box>
        </Box>
      );
    }
  };
  return (
    <>
      <Box
        sx={{
          background: "white",
          // height: bookingDetailsPage ? "310px" : "310px",
        }}
      >
        {!show ? (
          <>
            <Box sx={{ p: 2 }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #E04E2C",
                  marginBottom: "15px",
                }}
              >
                <img
                  alt="arrow icon"
                  src={larrow}
                  onClick={hidePayment}
                  height={20}
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />

                <Typography
                  className="tabLabel"
                  style={{
                    color: "#030303",
                    fontWeight: 300,
                    fontSize: "18px",
                  }}
                >
                  INVOICE DETAILS
                </Typography>
                <Box
                  className="toPay"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  {/* <img src={invoice} alt="" style={{  }} /> */}
                  <Typography className="locationtitle" style={{}}>
                    To Pay :&nbsp;
                  </Typography>
                  <Typography style={{ fontWeight: "600" }}>
                    ₹{booking?.amount}
                  </Typography>
                </Box>
              </Box>

              {booking?.couponCode && (
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginLeft: "20px" }}>
                    <Typography style={{ fontWeight: "600", padding: "5px" }}>
                      Coupon code:{" "}
                      <Tooltip title={"Copy code"} placement="bottom">
                        <span
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => copyText(booking?.couponCode)}
                        >
                          {booking?.couponCode}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* <Typography
                className="paymentdetails"
                style={{ padding: "6px 0", fontSize: "16px" }}
              >
                Payment Details
              </Typography> */}

              {booking?.tasksList?.map((task) => {
                if (task?.order === 0) return null;
                return (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "6px 0",
                    }}
                  >
                    <div className="bill">
                      <Typography
                        className="billcost sameSizeP"
                        sx={{ fontSize: "16px" }}
                      >
                        Delivery, {Math.ceil(task?.distance / 1000) || "0"} km -{" "}
                        {Math.ceil(task?.duration / 60)} mins
                      </Typography>
                    </div>
                    <Typography
                      style={{
                        flex: "1",
                        borderBottom: "2px dashed #ccc",
                        margin: "0 10px",
                      }}
                    ></Typography>
                    <Typography className="sameSizeP">
                      ₹{getPrice(booking, task)}
                    </Typography>
                  </Box>
                );
              })}
              {booking?.couponRef && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "6px 0",
                  }}
                >
                  <div className="bill sameSizeP">
                    <Typography style={{ color: "red" }}>Discount</Typography>
                  </div>
                  <Typography
                    style={{
                      flex: "1",
                      borderBottom: "2px dashed #ccc",
                      margin: "0 10px",
                    }}
                  ></Typography>
                  <Typography className="sameSizeP" style={{ color: "red" }}>
                    - ₹{booking?.discount}
                  </Typography>
                </Box>
              )}
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "6px 0",
                }}
              >
                <div className="bill">
                  <Typography className="billcost" sx={{ fontWeight: "600" }}>
                    Total
                  </Typography>
                </div>
                <Typography
                  style={{
                    flex: "1",
                    borderBottom: "2px dashed #ccc",
                    margin: "0 10px",
                  }}
                ></Typography>
                <Typography style={{ fontWeight: "600" }}>
                  ₹
                  {booking?.discount > 0
                    ? totalPrice - booking?.discount
                    : totalPrice}
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            {" "}
            <Box
              sx={{
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid rgb(227, 85, 46)",
                }}
              >
                <Typography
                  className="tabLabel"
                  style={{
                    fontFamily: "Inter,sans-serif",
                    fontWeight: 300,
                    color: "#030303",
                    fontSize: "18px",
                  }}
                >
                  BOOKING DETAILS
                </Typography>

                {packageTypes?.map((obj) => (
                  <Tooltip title={obj?.type || "Tooltip"} placement="bottom">
                    <Avatar
                      alt={"Package Type Avatar."}
                      src={obj?.icon}
                      style={{
                        padding: "6px",
                        background: "#f0f0f0",
                        marginLeft: "10px",
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </Tooltip>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <Box>
                    <Typography
                      className="dtDetail"
                      style={{ fontSize: "16px", fontWeight: "700" }}
                    >
                      {timeDetail()}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title={"More options"} placement="bottom">
                      <img
                        style={{ cursor: "pointer" }}
                        alt="Three dots icon"
                        className="threeDotIcn"
                        src={Three_Dots}
                        onClick={handleClickEvent}
                      />
                    </Tooltip>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 0.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: 2,
                            mr: 1,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        className="dropdownMenuItem"
                        onClick={() => handleCopyLink()}
                      >
                        Copy
                      </MenuItem>
                      <MenuItem
                        className="dropdownMenuItem"
                        onClick={() => setOpenConf(true)}
                      >
                        Cancel
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                {getUserInfo()}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default BookingDetailsTab;
