import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchRates = createAsyncThunk("getRates", async (payload) => {
  const response = await axios.post("/rates/get/all", payload);
  return response;
});
const getRates = createSlice({
  name: "getRates",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRates.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchRates.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { reset } = getRates.actions;
export default getRates.reducer;
