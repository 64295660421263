import React from "react";
import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import RateList from "../pages/rates/rates";
import CouponList from "../pages/coupons/couponList";
import NewCoupon from "../pages/coupons/newCoupon";
import ManagerList from "../pages/managers/Managers";
import NewManager from "../pages/managers/newManager";
import GroupList from "../pages/users/groupList";
import NewGroup from "../pages/users/newGroup";
import AllUsers from "../pages/users/allUsers";
import AllRiders from "../pages/riders/allRiders";
import NewRider from "../pages/riders/newRider";
import NotificationList from "../pages/notifications/notifications";
import NewNotification from "../pages/notifications/newNotification";
import TransactionList from "src/pages/payments/transactionList";
import BookingList from "src/pages/bookings/bookingList";
import BusinessList from "src/pages/business/BusinessList";
import BusinessDetails from "src/pages/business/BusinessDetails";
import BusinessForm from "src/pages/business/BusinessForm";
import Chat from "src/pages/chat/chat";
import Cities from "src/pages/cities/cities";
import PackageTypes from "src/pages/packageTypes/packageTypes";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import RiderDetails from "src/pages/riders/riderDetails";
import UserDetails from "src/pages/users/userDetails";
import AuthGuard from "../guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../utils/config";
import LoadingScreen from "../components/LoadingScreen";
import BookingDetailsgs from "src/pages/bookings/bookingDetails";
import Campaigns from "src/pages/campaigns/campaigns";
import NewCampaign from "src/pages/campaigns/newCampaign";
import NewCity from "src/pages/cities/newCity";
import NewPackage from "src/pages/packageTypes/newPackage";
import { useSelector } from "react-redux";
import DefaultNotificationList from "src/pages/defaultNotifications/notifications";
import DefaultNewNotification from "src/pages/defaultNotifications/newNotification";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const getLoginUser = useSelector((state) => state.getLoginUser.data);
  const routesForAdmin = [
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        {
          path: "register",
          element: (
            <AuthGuard>
              <Register />
            </AuthGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },
    {
      path: "",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <GeneralApp /> },
        {
          path: "activity",
          element: (
            <AuthGuard>
              <GeneralBooking />
            </AuthGuard>
          ),
        },
        {
          path: "notifications/:activeTab",
          children: [
            { element: <NotificationList />, index: true },
            { path: "", element: <NotificationList /> },

            { path: "add", element: <NewNotification /> },
            { path: "edit/:id", element: <NewNotification /> },
          ],
        },
        {
          path: "default-notifications",
          children: [
            { element: <DefaultNotificationList />, index: true },
            { path: "", element: <DefaultNotificationList /> },

            { path: "add", element: <DefaultNewNotification /> },
            { path: "edit/:id", element: <DefaultNewNotification /> },
          ],
        },
        {
          path: "campaigns",
          children: [
            { element: <Campaigns />, index: true },
            { path: "", element: <Campaigns /> },
            { path: "add", element: <NewCampaign /> },
            { path: "edit/:id", element: <NewCampaign /> },
          ],
        },
        {
          path: "settings",
          children: [
            { element: <RateList />, index: true },
            { path: "", element: <RateList /> },
          ],
        },
        {
          path: "coupons",
          children: [
            { element: <CouponList />, index: true },
            { path: "", element: <CouponList /> },
            { path: "add", element: <NewCoupon /> },
            { path: "edit/:id", element: <NewCoupon /> },
          ],
        },
        {
          path: "managers",
          children: [
            { element: <ManagerList />, index: true },
            { path: "", element: <ManagerList /> },
            { path: "add", element: <NewManager /> },
            { path: "edit/:id", element: <NewManager /> },
          ],
        },
        {
          path: "groups",
          children: [
            { element: <GroupList />, index: true },
            { path: "", element: <GroupList /> },

            { path: "add", element: <NewGroup /> },
            { path: "edit/:id", element: <NewGroup /> },
          ],
        },
        {
          path: "users",
          children: [
            { element: <AllUsers />, index: true },
            { path: "details", element: <UserDetails /> },
            { path: ":activeTab", element: <AllUsers /> },
         
          ],
        },

        {
          path: "riders",
          children: [
            { element: <AllRiders />, index: true },
            { path: "", element: <AllRiders /> },
            { path: "add", element: <NewRider /> },
            { path: "edit/:id", element: <NewRider /> },
            { path: "details", element: <RiderDetails /> },
          ],
        },

        {
          path: "transactions",
          children: [
            { element: <TransactionList />, index: true },
            { path: "", element: <TransactionList /> },
          ],
        },
        {
          path: "bookings",
          children: [
            { element: <BookingList />, index: true },
            { path: "", element: <BookingList /> },
            { path: "details", element: <BookingDetailsgs /> },
          ],
        },
        {
          path: "business",
          children: [
            { element: <BusinessList />, index: true },
            { path: "", element: <BusinessList /> },
            { path: "details", element: <BusinessDetails /> },
            { path: "form", element: < BusinessForm/> },
            { path: "form/:id", element: < BusinessForm/> },
          ],
        },

        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "add", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },

        {
          path: "cities",
          children: [
            { element: <Cities />, index: true },
            {
              path: "",
              element: (
                <AuthGuard>
                  <Cities />
                </AuthGuard>
              ),
            },
            { path: "add", element: <NewCity /> },
            { path: "edit/:id", element: <NewCity /> },
          ],
        },
        {
          path: "packages",
          children: [
            { element: <PackageTypes />, index: true },
            {
              path: "",
              element: (
                <AuthGuard>
                  <PackageTypes />
                </AuthGuard>
              ),
            },
            { path: "add", element: <NewPackage /> },
            { path: "edit/:id", element: <NewPackage /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  const routesForManager = [
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <AuthGuard>
              <Login />
            </AuthGuard>
          ),
        },
        {
          path: "register",
          element: (
            <AuthGuard>
              <Register />
            </AuthGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },
    {
      path: "",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <GeneralApp /> },
        {
          path: "activity",
          element: (
            <AuthGuard>
              <GeneralBooking />
            </AuthGuard>
          ),
        },
        {
          path: "notifications/:activeTab",
          children: [
            { element: <NotificationList />, index: true },
            { path: "", element: <NotificationList /> },

            { path: "add", element: <NewNotification /> },
            { path: "edit/:id", element: <NewNotification /> },
          ],
        },
        {
          path: "campaigns",
          children: [
            { element: <Campaigns />, index: true },
            { path: "", element: <Campaigns /> },

            { path: "add", element: <NewCampaign /> },
          ],
        },
        {
          path: "settings",
          children: [
            { element: <RateList />, index: true },
            { path: "", element: <RateList /> },
          ],
        },
        {
          path: "coupons",
          children: [
            { element: <CouponList />, index: true },
            { path: "", element: <CouponList /> },
            { path: "add", element: <NewCoupon /> },
            { path: "edit/:id", element: <NewCoupon /> },
          ],
        },
        {
          path: "groups",
          children: [
            { element: <GroupList />, index: true },
            { path: "", element: <GroupList /> },

            { path: "add", element: <NewGroup /> },
            { path: "edit/:id", element: <NewGroup /> },
          ],
        },
        {
          path: "users/:activeTab",
          children: [
            { element: <AllUsers />, index: true },
            { path: "", element: <AllUsers /> },
            { path: "details", element: <UserDetails /> },
          ],
        },

        {
          path: "riders",
          children: [
            { element: <AllRiders />, index: true },
            { path: "", element: <AllRiders /> },
            { path: "add", element: <NewRider /> },
            { path: "edit/:id", element: <NewRider /> },
            { path: "details", element: <RiderDetails /> },
          ],
        },

        {
          path: "transactions",
          children: [
            { element: <TransactionList />, index: true },
            { path: "", element: <TransactionList /> },
          ],
        },
        {
          path: "bookings",
          children: [
            { element: <BookingList />, index: true },
            { path: "", element: <BookingList /> },
            { path: "details", element: <BookingDetailsgs /> },
          ],
        },
        {
          path: "business",
          children: [
            { element: <BusinessList />, index: true },
            { path: "", element: <BusinessList /> },
            { path: "details", element: <BusinessDetails /> },
          ],
        },

        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "add", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
  return useRoutes(
    getLoginUser?.role === "ADMIN_ROLE" ? routesForAdmin : routesForManager
  );
}

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));
// Dashboard
const GeneralApp = Loadable(lazy(() => import("../pages/dashboard/dashboard")));
const GeneralBooking = Loadable(
  lazy(() => import("../pages/activities/activities"))
);
const Pricing = Loadable(lazy(() => import("../pages/pricings/pricing")));
const Payment = Loadable(lazy(() => import("../pages/payments/payment")));
const Page500 = Loadable(lazy(() => import("../pages/common/page500")));
const NotFound = Loadable(lazy(() => import("../pages/common/page404")));
