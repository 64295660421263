import * as Yup from "yup";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import larrow from "../../images/arrow-left.svg";
import Switch from "@mui/material/Switch";
import { ComingSoonIllustration } from "../../assets";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Card,
  Container,
  Stack,
  TextField,
  Typography,
  Box,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
  RHFSwitch,
  RHFUploadAvatar,
} from "../../components/hook-form";
import useSettings from "../../hooks/useSettings";
import UserNewForm from "src/sections/@dashboard/user/UserNewForm";
import Label from "src/components/Label";
import { UploadSingleFile } from "src/components/upload";
import { fData } from "src/utils/formatNumber";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
const employeeTypes = [
  { code: 1, label: "Permanent" },
  { code: 2, label: "Freelancer" },
];
export default function NewCoupon() {
  const defaultValues = {
    display_name: "",
    email: "",
    phone_number: "",
    cityRef: "",
    currentAddress: "",
    permanentAddress: "",
    aadharNumber: "",
    panNumber: "",
    type: "",
    avatar: "",
  };

  const NewRiderSchema = Yup.object().shape({
    display_name: Yup.string().required("Full name is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phone_number: Yup.string().required("Phone number is required"),
    cityRef: Yup.string().required("City reference is required"),
    currentAddress: Yup.string().required("Current address is required"),
    permanentAddress: Yup.string().required("Permanent address is required"),
    aadharNumber: Yup.string().required("Aadhar number is required"),
    panNumber: Yup.string().required("PAN number is required"),
    type: Yup.string().required("Type is required"),
    avatar: Yup.mixed().test(
      "required",
      "Avatar is required",
      (value) => value !== ""
    ),
  });

  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const [loading, setLoading] = useState(false);
  const cityId = localStorage.getItem("manager_city");
  const { enqueueSnackbar } = useSnackbar();
  const [avatar, setAvatar] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [adhaar, setAdhaar] = useState(null);
  const [dl, setDl] = useState(null);
  const [rider, setRider] = useState(defaultValues);
  const [cities, setCities] = useState([]);
  const [files, setFiles] = useState({
    avatar: null,
    panCard: null,
    adhaar: null,
    dl: null,
  });

  // For mobile otp varification
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const auth = getAuth();
  auth.settings.appVerificationDisabledForTesting = true;
  // Set up reCAPTCHA verifier
  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container", // ID of the HTML div where reCAPTCHA will render
        {
          size: "normal", // Use 'invisible' for invisible reCAPTCHA if needed
          callback: (response) => {
            console.log("reCAPTCHA solved successfully");
          },
          "expired-callback": () => {
            console.warn("reCAPTCHA expired, user must retry.");
          },
        },
        auth
      );

      // Ensure reCAPTCHA gets rendered
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
       
    }
  };

  const sendOtp = () => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+91${rider.phone_number}`, appVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setIsOtpSent(true);
        setError("");
      })
      .catch((error) => {
        setError(error.message);
        console.log(error);
        
      });
  };

  const verifyOtp = () => {
    const credential = window.firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      otp
    );
    auth
      .signInWithCredential(credential)
      .then((result) => {
        console.log("User signed in successfully:", result);
        setError("");
        // Handle successful login here (e.g., navigate to another page)
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const methods = useForm({
    // resolver: yupResolver(NewRiderSchema),
    defaultValues,
  });

  const {
    reset,

    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    if (id) {
    }
  }, []);

  // init data for city
  useEffect(() => {
    getCity();
    setUpRecaptcha();
  }, []);

  const getCity = () => {
    axios
      .post(`/cities/get/all`, {})
      .then((res) => {
        console.log("Cities....", res.data.result);
        setCities(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleOnClick = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    // debugger;
    setLoading(true);
    if (!isEdit) {
      axios
        .post("/rider/add", rider)
        .then((res) => {
          console.log("testing", res);
          setLoading(false);
          navigate(PATH_DASHBOARD.riders.root);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      axios
        .put(`/rider/update/${id}`, rider)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.riders.root);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleDrop = (name, acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setFiles((prevFiles) => ({
        ...prevFiles,
        [name]: file,
      }));

      setRider((preState) => ({
        ...preState,
        [name]: Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      }));
    }
  };

  return (
    <>
      <ThemeProvider theme={{}}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            marginTop: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0" }}
                style={{
                  display: "flex",
                  borderBottom: "1px solid #E04E2C",
                }}
              >
                <Box>
                  <img
                    alt="back icon"
                    src={larrow}
                    onClick={handleOnClick}
                    height={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Box>
                  {!isEdit ? (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      CREATE NEW RIDER
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      EDIT RIDER
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
              >
                <HeaderBreadcrumbs
                  heading={""}
                  links={[
                    { name: "Riders", href: PATH_DASHBOARD.riders.root },
                    {
                      name: "Add new",
                      href: PATH_DASHBOARD.riders.root,
                    },
                  ]}
                />
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} style={{ display: "flex", gap: 5 }}>
                  <UploadSingleFile
                    file={files.panCard}
                    onDrop={(acceptedFiles) =>
                      handleDrop("panCard", acceptedFiles)
                    }
                    heading="Upload Pan Card"
                  />
                  <UploadSingleFile
                    file={files.adhaar}
                    onDrop={(acceptedFiles) =>
                      handleDrop("adhaar", acceptedFiles)
                    }
                    heading="Upload Aadhar Card"
                  />
                  <UploadSingleFile
                    file={files.dl}
                    onDrop={(acceptedFiles) => handleDrop("dl", acceptedFiles)}
                    heading="Upload Driving License"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card sx={{ py: 10, px: 3 }}>
                    {isEdit && (
                      <Label
                        color={rider.status !== "active" ? "error" : "success"}
                        sx={{
                          textTransform: "uppercase",
                          position: "absolute",
                          top: 24,
                          right: 24,
                        }}
                      >
                        {rider.status}
                      </Label>
                    )}

                    <Box sx={{ mb: 5 }}>
                      <RHFUploadAvatar
                        name="avatar"
                        file={files.avatar}
                        accept="image/*"
                        maxSize={3145728}
                        onDrop={(acceptedFiles) =>
                          handleDrop("avatar", acceptedFiles)
                        }
                        helperText={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 2,
                              mx: "auto",
                              display: "block",
                              textAlign: "center",
                              color: "text.secondary",
                            }}
                          >
                            Allowed *.jpeg, *.jpg, *.png, *.gif
                            <br /> max size of {fData(3145728)}
                          </Typography>
                        }
                      />
                    </Box>

                    <RHFSwitch
                      name="isVerified"
                      labelPlacement="start"
                      label={
                        <>
                          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                            Email Verified
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                          >
                            Disabling this will automatically send the user a
                            verification email
                          </Typography>
                        </>
                      }
                      sx={{ mx: 0, width: 1, justifyContent: "space-between" }}
                    />
                  </Card>
                </Grid>
                <div id="recaptcha-container"></div>

                <Grid item xs={12} md={8}>
                  <Card sx={{ p: 3, mb: 10 }}>
                    <Box
                      sx={{
                        display: "grid",
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                      }}
                    >
                      <RHFTextField
                        name="display_name"
                        label={"Full Name"}
                        value={rider?.display_name}
                        onChange={(e) =>
                          setRider((preState) => ({
                            ...preState,
                            display_name: e.target.value,
                          }))
                        }
                      />

                      <RHFTextField
                        name="email"
                        label="Email Address"
                        type="email"
                        value={rider?.email}
                        onChange={(e) =>
                          setRider((preState) => ({
                            ...preState,
                            email: e.target.value,
                          }))
                        }
                      />

                      {!isOtpSent ? (
                        <div>
                          <RHFTextField
                            label="Phone Number"
                            value={rider.phone_number}
                            onChange={(e) =>
                              setRider((preState) => ({
                                ...preState,
                                phone_number: e.target.value,
                              }))
                            }
                            type="tel"
                            placeholder="+1234567890"
                            fullWidth
                          />
                          <LoadingButton onClick={sendOtp} variant="contained" style={{ marginTop: '4px' }}>
                            Send OTP
                          </LoadingButton>
                        </div>
                      ) : (
                        <div>
                          <TextField
                            label="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            type="text"
                            fullWidth
                          />
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={verifyOtp}
                          >
                            Verify OTP
                          </LoadingButton>
                        </div>
                      )}

                      <RHFSelect name="city" label="City" placeholder="City">
                        <option value="" />
                        {cities.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.city}
                          </option>
                        ))}
                      </RHFSelect>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography className="para_label">
                          Current Address
                        </Typography>
                        <textarea
                          placeholder="Type current address hereeeree..."
                          style={{
                            height: "155px",
                            fontSize: "16px",
                            width: "100%",
                            fontFamily: "Inter,sans-serif",
                            borderRadius: "8px",
                            resize: "none",
                            border: "1px solid #E2E1E5",
                            padding: "10px",
                          }}
                          name="currentAddress"
                          label="Message"
                          value={rider?.currentAddress}
                          onChange={(e) =>
                            setRider((preState) => ({
                              ...preState,
                              currentAddress: e.target.value,
                            }))
                          }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography className="para_label">
                          Permanent Address
                        </Typography>
                        <textarea
                          placeholder="Type permanent address here..."
                          style={{
                            height: "155px",
                            fontSize: "16px",
                            width: "100%",
                            fontFamily: "Inter,sans-serif",
                            borderRadius: "8px",
                            resize: "none",
                            border: "1px solid #E2E1E5",
                            padding: "10px",
                          }}
                          name="permanentAddress"
                          label="Message"
                          value={rider?.permanentAddress}
                          onChange={(e) =>
                            setRider((preState) => ({
                              ...preState,
                              permanentAddress: e.target.value,
                            }))
                          }
                          required
                        />
                      </Grid>
                      <RHFSelect
                        name="type"
                        label="Employee Type"
                        placeholder="Employee Type"
                        onChange={(e) =>
                          setRider((preState) => ({
                            ...preState,
                            type: e.target.value,
                          }))
                        }
                      >
                        <option value="" />
                        {employeeTypes.map((option) => (
                          <option key={option.code} value={option.label}>
                            {option.label}
                          </option>
                        ))}
                      </RHFSelect>
                      <RHFTextField
                        name="panNumber"
                        label="Pan Number"
                        type="text"
                        value={rider?.panNumber}
                        onChange={(e) =>
                          setRider((preState) => ({
                            ...preState,
                            panNumber: e.target.value,
                          }))
                        }
                        required
                      />
                      <RHFTextField
                        name="aadharNumber"
                        label="Aadhar Number"
                        type="number"
                        value={rider?.aadharNumber}
                        onChange={(e) =>
                          setRider((preState) => ({
                            ...preState,
                            aadharNumber: e.target.value,
                          }))
                        }
                        required
                      />
                    </Box>

                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {!isEdit ? "Create Rider" : "Save Changes"}
                      </LoadingButton>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
