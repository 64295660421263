import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Conformation from "../../components/Conformation";
import {
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  CircularProgress,
  Tooltip,
  Button,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchRiders, reset } from "src/redux/slices/GetRiders";
import LoadingScreen from "src/components/LoadingScreen";
import { getDate } from "src/utils/methods";

function abc(index) {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "company", label: "Rider Code", alignRight: false },
  { id: "role", label: "Type", alignRight: false },
  { id: "isVerified", label: "Mobile", alignRight: false },
  { id: "status", label: "Joined on", alignRight: true },
  { id: "isVerified", label: "  Online", alignRight: true },
  { id: "isVerified", label: "  Active", alignRight: true },
  { id: "status", label: "Verified", alignRight: true },
  { id: "Action", label: "Action", alignRight: true },
];

// ----------------------------------------------------------------------

export default function AllRiders() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getRiders);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [riderList, setRiderList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [type, setType] = useState("all");
  const [filterData, setFilterData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({ id: null, state: false });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [c_Data, setc_Data] = useState(0);

  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = riderList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = riderList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setRiderList(deleteUsers);
  };
  const updateRider = () => {
    setOpen(false);
    const payload = {};
    payload.isActive = !updateObj.state;
    payload.isOnline = false;
    axios
      .put(`/riders/update/${updateObj.id}`, payload)
      .then((res) => {
        console.log(res);
        setUpdated(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const isNotFound = riderList?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  // get all rider and filter
  useEffect(() => {
    if (filterValue?.length > 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [type, rowsPerPage, page, updated]);
  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.cityId = localStorage.getItem("manager_city");
      payload.type = type;
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      await axios
        .post("/riders/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchRiders(payload));
          } else {
            setRiderList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getRiders();
  }, [state.data]);
  const getRiders = async () => {
    try {
      if (state?.data?.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchRiders(payload));
      } else {
        setRiderList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Riders">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="RIDERS"
                  {...a11yProps(0)}
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">
            <Button
                className="createBtn"
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.riders.newRider}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                style={{ background: "#E04E2C" }}
              >
                New Rider
              </Button>
              <Box style={{ padding: "0" }}>
                <Card sx={{ borderRadius: "0" }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      background: "#f4f6f8",
                      borderRadius: "0",
                      padding: "10px 10px 0 10px",
                    }}
                  >
                    <Tabs
                      value={c_Data}
                      onChange={pageChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="All"
                        {...abc(0)}
                        onClick={() => {
                          setType("all");
                          setFilterValue("");
                          setSearchValue("");
                        }}
                      />
                      <Tab
                        label="Online"
                        {...abc(1)}
                        onClick={() => {
                          setType("isOnline");
                          setFilterValue("");
                          setSearchValue("");
                        }}
                      />
                      <Tab
                        label="Verified"
                        {...abc(2)}
                        onClick={() => {
                          setType("isVerified");
                          setFilterValue("");
                          setSearchValue("");
                        }}
                      />
                      <Tab
                        label="Pending"
                        {...abc(3)}
                        onClick={() => {
                          setType("isPending");
                          setFilterValue("");
                          setSearchValue("");
                        }}
                      />
                      <Tab
                        label="Rejected"
                        {...abc(4)}
                        onClick={() => {
                          setType("isRejected");
                          setFilterValue("");
                          setSearchValue("");
                        }}
                      />
                    </Tabs>
                  </Box>
                  <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    onDeleteUsers={() => handleDeleteMultiUser(selected)}
                    setFilterValue={setFilterValue}
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    filterData={filterData}
                  />

                  <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
                    <Scrollbar>
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={riderList.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {riderList?.map((row, index) => {
                            const isItemSelected =
                              selected.indexOf(row.id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                                sx={{ textDecoration: "none" }}
                              >
                                <Tooltip
                                  title="Go to Rider's Details"
                                  placement="bottom"
                                >
                                  <TableCell
                                    component={RouterLink}
                                    to={PATH_DASHBOARD.riders.riderDetails}
                                    state={row.id}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Avatar
                                      alt={"Rider's Photo url."}
                                      src={row?.photo_url}
                                    />
                                    <Typography variant="subtitle2" noWrap>
                                      {" "}
                                      {row?.display_name?.replace(
                                        /(\w)(\w*)/g,
                                        (_, g1, g2) =>
                                          g1.toUpperCase() + g2.toLowerCase()
                                      ) || ""}
                                    </Typography>
                                  </TableCell>
                                </Tooltip>
                                <TableCell>{row?.code}</TableCell>
                                <TableCell>{row?.type}</TableCell>
                                <TableCell>{row?.phone_number}</TableCell>
                                <TableCell align="right">
                                  {getDate(row?.created_time)}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.isOnline === true ? (
                                    <DoneIcon sx={{ color: "green" }} />
                                  ) : (
                                    <CloseIcon sx={{ color: "red" }} />
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.isActive === true ? (
                                    <DoneIcon sx={{ color: "green" }} />
                                  ) : (
                                    <CloseIcon sx={{ color: "red" }} />
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  {row?.isVerified === true ? (
                                    <TaskAltIcon sx={{ color: "green" }} />
                                  ) : row?.isVerified === null ? (
                                    <ScheduleIcon sx={{ color: "orange" }} />
                                  ) : (
                                    <CancelOutlinedIcon sx={{ color: "red" }} />
                                  )}
                                </TableCell>
                                <TableCell align="right">
                                  <ThreedotMenu
                                    row={row}
                                    setUpdateObj={setUpdateObj}
                                    setOpenHandler={setOpen}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {isNotFound && (
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{ py: 3 }}
                                colSpan={15}
                              >
                                No data found.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={(e, page) => setPage(page + 1)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </Box>
            </TabPanel>
          </Box>
        </Container>
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={updateRider}
          title={`${updateObj.state ? "Block" : "Active"} Confirmation`}
          subTitle={`Do you want to ${
            updateObj.state ? "block" : "unblock"
          } the user?`}
        />
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, setUpdateObj, setOpenHandler }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHandler(true);
            setUpdateObj({ id: row?.id, state: row?.isActive });
            handleClose();
          }}
        >
          <Iconify sx={{ mr: 2, width: 20, height: 20 }}>
            {!row?.isActive ? (
              <DoneIcon sx={{ color: "green" }} />
            ) : (
              <CloseIcon sx={{ color: "red" }} />
            )}
          </Iconify>
          {row.isActive ? (
            <span style={{ color: "red" }}>Deactivate</span>
          ) : (
            <span style={{ color: "green" }}> Active</span>
          )}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
