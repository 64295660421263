import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import larrow from "../../images/arrow-left.svg";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Container,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Avatar,
  Chip,
  Button,
  IconButton,
  Slider,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
  RHFRadioGroup,
  RHFMultiCheckbox,
  RHFUploadAvatar,
} from "../../components/hook-form";
import useSettings from "../../hooks/useSettings";
import { fData } from "src/utils/formatNumber";
import { grey, orange } from "@mui/material/colors";
import DestinationSelectorModal from "./destinationSelect";
import { PhotoCamera } from "@mui/icons-material";
import DaySelector from "src/components/hook-form/daySelector";
import DestinationForm from "./destinationForm";

export default function BusinessForm() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const [loading, setLoading] = useState(false);
  const cityId = localStorage.getItem("manager_city");
  const { enqueueSnackbar } = useSnackbar();
  const defaultValue = {
    avatarFile: "",
    coverPhotoFile: "",
    userId: "",
    url: "",
    name: "",
    mobile: "",
    whatsapp: "",
    latitude: "",
    longitude: "",
    cityId: cityId,
    address: "",
    bookingType: "",
    businessDays: [],
    packageTypeIds: [],
    startTime: "",
    endTime: "",
    aboutBusiness: "",
    packageBusiness: 0,
    packageCustomer: 0,
    destinationId: [],
    tagLine : "",
  };

  const [business, setBusiness] = useState(defaultValue);
  const [packageTypes, setPackageTypes] = useState([]);

  const [cities, setCities] = useState([]);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [selectedPackageIds, setSelectedPackageIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showBusinessLocation, setShowBusinessLocation] = useState(false);
  const [destinations, setDestinations] = useState([]);
  const [sliderValue, setSliderValue] = useState(70);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [editselectedDestinations, setEditSelectedDestinations] = useState([]);

  const handleOpen = () => {
    if (business?.userId) setModalOpen(true);
    else {
      enqueueSnackbar("Please enter the user id first!", {
        variant: "error",
        style: { marginTop: "100px" },
      });
    }
  };
  const handleClose = () => setModalOpen(false);

  const handleAddDestination = () => {
    setShowBusinessLocation(true);
    setModalOpen(false);
  };

  const saveAddDestination = (dest) => {
    if (!business.userId) return;
    handleCloseBusinessLocation();
    getDestinations();
    setModalOpen(true);
  };

  const handleCloseBusinessLocation = () => {
    setShowBusinessLocation(false);
    setModalOpen(true);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleSaveDestinations = (selected) => {
    setSelectedDestinations(selected);
  };

  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    defaultValues: { ...business },
  });
  useEffect(() => {
    if (id) {
      getBusiness();
    }
  }, []);

  useEffect(() => {
    getPackageTypes();
    getCity();
    getDestinations();
  }, []);

  useEffect(() => {
    if (business.userId) getDestinations();
  }, [business.userId]);

  useEffect(() => {
    if (business.userId && id && editselectedDestinations?.length) {
      const locations = editselectedDestinations.map((l) => {
        return destinations.find((e) => e.id == l);
      });
      // debugger
      setSelectedDestinations(locations);
    }
  }, [id, destinations]);

  

  const getPackageTypes = () => {
    axios
      .get(`/packageTypes/get/byCity/${cityId}`)
      .then((res) => {
        console.log("testing88......", res.data.result);
        setPackageTypes(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getDestinations = () => {
    if (!business.userId) return;
    axios
      .get(`/destinations/get/${business.userId}`)
      .then((res) => {
        setDestinations(
          res.data.result.map((e) => {
            return {
              id: e.id,
              name: e.name,
              address: e.address,
              location: e.location,
            };
          })
        );
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getCity = () => {
    axios
      .post(`/cities/get/all`, {})
      .then((res) => {
        console.log("Cities....", res.data.result);
        setCities(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  const generateTimeSlots = () => {
    const times = [];
    const periods = ["AM", "PM"];

    for (let i = 0; i < 24; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const period = periods[Math.floor(i / 12)];

      times.push(`${pad(hour)}:00 ${period}`);
      times.push(`${pad(hour)}:30 ${period}`);
    }

    return times;
  };

  const timeSlots = generateTimeSlots();

  const handleEndTimeChange = (endTime) => {
    const endDate = new Date(endTime);
    const endTimeInNumberFormat = endDate.getTime();
    const startTime = new Date(business.startTime);
    const startTimeInNumberFormat = startTime.getTime();
    if (endTimeInNumberFormat < startTimeInNumberFormat) {
      enqueueSnackbar("Invalid End Time", {
        variant: "error",
        style: { marginTop: "100px" },
      });
    } else {
      setBusiness((preState) => ({ ...preState, endTime: endTime }));
    }
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const getBusiness = () => {
    setLoading(true);
    axios
      .get(`business/get-business/${id}`)
      .then((res) => {
        console.log("testing55..........", res?.data?.result);
        const obj = res?.data?.result;
        // obj["coverPhotoFile"] = {
        //   preview: obj["coverPhoto"],
        // };
        setSelectedPackageIds(obj["packages"]?.filter(e => e.isSelected).map((o) => o.packageTypeRef._path?.segments[1]));

        setBusiness(obj || defaultValue);
        setEditSelectedDestinations(obj["destinations"]);

        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoading(false);
      });
  };

  const handleOnClick = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    if (loading) return;

    const payload = business;
    payload["packageTypeIds"] = selectedPackageIds;
    payload["packageBusiness"] = sliderValue;
    payload["packageCustomer"] = 100 - sliderValue;
    payload["destinationId"] = selectedDestinations.map((o) => o.id);
    // debugger
    console.log(payload);

    setLoading(true);
    let formData = new FormData();
    const filteredPayload = Object.keys(payload)
      .filter((key) => key in defaultValue) // Only include keys from defaultValue
      .reduce((obj, key) => {
        obj[key] = payload[key];
        return obj;
      }, {});

    for (const [key, value] of Object.entries(filteredPayload)) {
      formData.append(key, value);
    }
    if (!id) {
      axios
        .post("/business/add", formData)
        .then((res) => {
          console.log("testing", res);
          setLoading(false);
          navigate(PATH_DASHBOARD.business.businessList);
        })
        .catch((err) => {
          setLoading(false);

          enqueueSnackbar(err?.response?.data, {
            variant: "error",
            style: { marginTop: "100px" },
          });
        });
    } else {
      axios
        .put(`/business/update/${id}`, formData)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.business.businessList);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handlePackageSelect = (packageId) => {
    const isSelected = selectedPackageIds.includes(packageId);
    const updatedPackageIds = isSelected
      ? selectedPackageIds.filter((id) => id !== packageId)
      : [...selectedPackageIds, packageId];

    setSelectedPackageIds(updatedPackageIds);
    setBusiness((prev) => ({
      ...prev,
      packageTypeIds: updatedPackageIds,
    }));
  };

  // This is for upload file based the field, we can handle for multiple field.
  const handleFileDrop = useCallback(
    (acceptedFiles, key) => {
      const file = acceptedFiles[0];

      if (file) {
        setBusiness((preState) => ({
          ...preState,
          [key]: Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        }));
      }
    },
    [setBusiness]
  );

  return (
    <>
      <ThemeProvider theme={{}}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            marginTop: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0" }}
                style={{
                  display: "flex",
                  borderBottom: "1px solid #E04E2C",
                }}
              >
                <Box>
                  <img
                    src={larrow}
                    onClick={handleOnClick}
                    height={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Box>
                  {!id ? (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      CREATE NEW BUSINESS
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      EDIT BUSINESS
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
              ></Box>

              <Grid container flexWrap="wrap" sx={{ margin: "20px" }}>
                <Box container item marginBottom="30px" flexGrow="1">
                  <Box sx={{ background: "white", padding: "30px" }}>
                    <Box
                      sx={{
                        display: "grid",
                        columnGap: 3,
                        rowGap: 3,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(1, 1fr)",
                        },
                      }}
                    >
                      <Box
                        position="relative"
                        width="100%"
                        height={250}
                        sx={{ mb: 8, display: "flex" }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden",
                            borderRadius: 2,
                            backgroundSize: "cover", // Ensures the image covers the entire area
                            backgroundPosition: "center", // Centers the image
                            backgroundRepeat: "no-repeat", // Prevents tiling
                            backgroundImage: business.coverPhotoFile?.preview
                              ? `url(${business.coverPhotoFile?.preview})`
                              : "#eee", // Use backgroundImage to set the image
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                          }}
                        >
                          <IconButton
                            color="primary"
                            aria-label="upload cover photo"
                            component="label"
                            sx={{ position: "absolute", top: 10, right: 10 }}
                          >
                            <Box sx={{ fontSize: "18px", marginRight: "7px" }}>
                              Cover Photo
                            </Box>
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) =>
                                handleFileDrop(e.target.files, "coverPhotoFile")
                              }
                            />
                            <PhotoCamera />
                          </IconButton>
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -50,
                            left: 16,
                            borderRadius: "50%",
                            border: "4px solid white",
                            width: 150,
                            height: 150,
                            overflow: "hidden",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            background: "#fff",
                          }}
                        >
                          <RHFUploadAvatar
                            name="avatarFile"
                            accept="image/*"
                            maxSize={3145728}
                            onDrop={(acceptedFiles) =>
                              handleFileDrop(acceptedFiles, "avatarFile")
                            }
                            helperText={
                              <Typography
                                variant="caption"
                                sx={{
                                  mt: 2,
                                  mx: "auto",
                                  display: "block",
                                  textAlign: "center",
                                  color: "text.secondary",
                                }}
                              >
                                Allowed *.jpeg, *.jpg, *.png, *.gif
                                <br /> max size of {fData(3145728)}
                              </Typography>
                            }
                            file={business.avatarFile?.preview}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        columnGap: 3,
                        rowGap: 3,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(3, 1fr)",
                        },
                      }}
                    >
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          User Id
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="userId"
                          value={business?.userId}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              userId: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Business Name
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="name"
                          value={business?.name}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              name: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>

                      {/* <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          City
                        </Typography> */}
                      {/* <RHFSelect
                          sx={{ width: "100%" }}
                          style={{ color: "grey", fontSize: "13px" }}
                          name="cityId"
                          value={business?.cityId}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              cityId: e.target.value,
                            }))
                          }
                          required
                        >
                          {cities.map((city) => {
                            return <option value={city.id}>{city.city}</option>;
                          })}
                        </RHFSelect> */}
                      {/* </Box> */}
                      {/* <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Tag Line (Max 78 chars)
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="tagLine"
                          value={business?.tagLine}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              tagLine: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box> */}
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Tag Line (Max 78 chars)
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="tagLine"
                          value={business?.tagLine}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              tagLine: e.target.value,
                            }))
                          }
                          inputProps={{ maxLength: 78 }} // Restrict to 78 characters
                          required
                        />
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Business Location(s)
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpen}
                        >
                          {selectedDestinations.length
                            ? `(${selectedDestinations.length}) Location Selected`
                            : "Select Location"}
                        </Button>
                        <DestinationSelectorModal
                          open={modalOpen}
                          handleClose={handleClose}
                          destinations={destinations}
                          onAddDestination={handleAddDestination}
                          onSave={handleSaveDestinations}
                          selectedDestinations={selectedDestinations}
                          setSelectedDestinations={setSelectedDestinations}
                        />
                        {showBusinessLocation && (
                          <DestinationForm
                            userId={business?.userId}
                            onOpen={showBusinessLocation}
                            onClose={handleCloseBusinessLocation}
                            onSave={saveAddDestination}
                          />
                        )}
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Phone number for customers
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="mobile"
                          value={business?.mobile}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              mobile: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          WhatsApp number
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="whatsapp"
                          value={business?.whatsapp}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              whatsapp: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Working hours (From)
                        </Typography>
                        <RHFSelect
                          sx={{ width: "100%" }}
                          style={{ color: "grey", fontSize: "13px" }}
                          name="startTime"
                          value={business?.startTime}
                          defaultValue="Flat"
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              startTime: e.target.value,
                            }))
                          }
                          required
                        >
                          <option value="" selected disabled>
                            Select From
                          </option>
                          {timeSlots?.map((slot, index) => (
                            <option key={index} value={slot}>
                              {slot}
                            </option>
                          ))}
                        </RHFSelect>
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Working hours (Till)
                        </Typography>
                        <RHFSelect
                          sx={{ width: "100%" }}
                          style={{ color: "grey", fontSize: "13px" }}
                          name="endTime"
                          value={business?.endTime}
                          defaultValue="Flat"
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              endTime: e.target.value,
                            }))
                          }
                          required
                        >
                          <option value="" selected disabled>
                            Select Till
                          </option>
                          {timeSlots?.map((slot, index) => (
                            <option key={index} value={slot}>
                              {slot}
                            </option>
                          ))}
                        </RHFSelect>
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Booking Type:
                        </Typography>
                        <RHFRadioGroup
                          setData={setBusiness}
                          name="bookingType"
                          value={business?.bookingType}
                          options={["Direct Order", "Menu Order"]}
                        >
                          {" "}
                        </RHFRadioGroup>
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Package Types
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            marginBottom: "8px",
                          }}
                        >
                          {packageTypes?.map((p) => (
                            <Chip
                              key={p.id}
                              avatar={<Avatar alt={p?.type} src={p?.icon} />}
                              label={p?.type || ""}
                              onClick={() => handlePackageSelect(p.id)}
                              sx={{
                                cursor: "pointer",
                                marginRight: "5px",
                                marginBottom: "4px",

                                backgroundColor: selectedPackageIds.includes(
                                  p.id
                                )
                                  ? "#e24d1f"
                                  : "default", // Custom color when selected
                                color: selectedPackageIds.includes(p.id)
                                  ? "#FFFFFF"
                                  : "default", // Custom text color when selected
                                "& .MuiChip-avatar": {
                                  backgroundColor: selectedPackageIds.includes(
                                    p.id
                                  )
                                    ? "#FFFFFF"
                                    : "default", // Custom avatar background color
                                },
                              }}
                            />
                          ))}
                        </Box>
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Working days
                        </Typography>
                        <DaySelector
                          setData={setBusiness}
                          name="businessDays"
                          options={[
                            "Mon",
                            "Tue",
                            "Wed",
                            "Thu",
                            "Fri",
                            "Sat",
                            "Sun",
                          ]}
                          selectedDays={business.businessDays}
                        />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Package Sharing &nbsp;
                          {sliderValue.toFixed(1)} :{" "}
                          {(100 - sliderValue).toFixed(1)}
                        </Typography>
                        <box>
                          <Slider
                            defaultValue={50}
                            max={100}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={sliderValue}
                            onChange={handleSliderChange}
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Business</span>
                            <span>Customer</span>
                          </Box>
                        </box>
                      </Box>
                      <Box
                        sx={{
                          gridColumn: "1 / -1",
                        }}
                      >
                        <Typography className="para_label" variant="h5">
                          About
                        </Typography>
                        <textarea
                          placeholder="Tell about your business"
                          style={{
                            fontSize: "14px",
                            height: "100px",
                            width: "100%",
                            color: "#020202",
                            fontWeight: 500,
                            borderRadius: "8px",
                            resize: "none",
                            fontFamily: "Inter,sans-serif",
                            border: "1px solid #E2E1E5",
                            padding: "10px",
                          }}
                          name="aboutBusiness"
                          label="Message"
                          value={business?.aboutBusiness}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              aboutBusiness: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Url
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="url"
                          value={business?.url}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              url: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>

                      {/* <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Latitude
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="latitude"
                          value={business?.latitude}
                          onChange={(e) =>
                            setBusiness((preState) => ({
                              ...preState,
                              latitude: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box> */}
                    </Box>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      <LoadingButton
                        sx={{ padding: "15px 0", borderRadius: "5px" }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        loading={isSubmitting}
                      >
                        {!id ? "ADD" : "Save Changes"}
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
