import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import larrow from "../../images/arrow-left.svg";
import Plus from "../../images/Plus.svg";
import { PlanPremiumIcon } from "../../assets";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Container,
  Stack,
  Typography,
  Box,
  IconButton,
  Switch,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { ThemeProvider } from "@mui/material/styles";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { useSnackbar } from "notistack";
import useSettings from "../../hooks/useSettings";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export default function NewPackage() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const [fileSelected, setFileSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [fragile, setFragile] = useState(false);
  const [icon, setIcon] = useState(null);
  const [url, setUrl] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  useEffect(() => {
    if (id) {
      getCoupon();
    }
  }, []);

  const handleOnClick = () => {
    navigate(-1);
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const getCoupon = () => {
    setLoading(true);
    axios
      .get(`/packageTypes/get/${id}`)
      .then((res) => {
        console.log(res.data);
        const currentPackage = res?.data?.result;
        setFragile(currentPackage?.fragile);
        setType(currentPackage?.type);
        setUrl(currentPackage?.icon);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const onSubmit = () => {
    setLoading(true);
    if (!fileSelected && !isEdit) {
      enqueueSnackbar("Please select an icon.", {
        variant: "error",
        style: { marginTop: "100px" },
      });
      setLoading(false);
      return;
    }
    if (!isEdit) {
      let formData = new FormData();
      formData.append("icon", icon);
      formData.append("type", type);
      formData.append("fragile", fragile);

      axios
        .post("/packageTypes/add", formData)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.packages.packages);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let formData = new FormData();
      formData.append("icon", icon);
      formData.append("type", type);
      formData.append("fragile", fragile);
      axios
        .put(`/packageTypes/update/${id}`, formData)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.packages.packages);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleFileChange = (event) => {
    setIcon(event.target.files[0]);
    setUrl(URL.createObjectURL(event.target.files[0]));
    setFileSelected(true);
  };

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "80%",
          zIndex: 1000,
          marginTop: 350,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      <Container
        maxWidth={themeStretch ? false : "xl"}
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      >
        <Box sx={{ width: "100%" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
              className="px-0"
              sx={{ padding: "24px", paddingBottom: "0" }}
              style={{ display: "flex", borderBottom: "1px solid #E04E2C" }}
            >
              <img
                alt="arrow icon"
                src={larrow}
                onClick={handleOnClick}
                height={20}
                style={{
                  marginRight: "10px",
                  marginTop: "7px",
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
              />
              {!isEdit ? (
                <Typography
                  sx={{ color: "#030303", fontSize: "24px", fontWeight: "300" }}
                >
                  CREATE NEW PACKAGE
                </Typography>
              ) : (
                <Typography
                  sx={{ color: "#030303", fontSize: "24px", fontWeight: "300" }}
                >
                  EDIT PACKAGE
                </Typography>
              )}
            </Box>
            <Box
              className="px-0"
              sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
            >
              <HeaderBreadcrumbs
                heading={""}
                links={[
                  { name: "Packages", href: PATH_DASHBOARD.packages.packages },
                  {
                    name: "Add new",
                    href: PATH_DASHBOARD.packages.root,
                  },
                ]}
              />
            </Box>
            <Box></Box>
            <Grid
              container
              className="px-0"
              sx={{ paddingLeft: "34px", paddingRight: "34px" }}
            >
              <Box
                sx={{
                  background: "white",
                  padding: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 3,
                }}
              >
                <Box sx={{}}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                    }}
                  >
                    <Box sx={{ width: "150px" }}>
                      <IconButton
                        variant="contained"
                        component="label"
                        className="iconBtn"
                      >
                        <input
                          hidden
                          name="icon"
                          accept="image/*"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <img src={url ? url : Plus} alt="Package Type Icon" />
                      </IconButton>
                      <Typography
                        sx={{
                          color: "#909090",
                          fontSize: "14px",
                          fontWeight: "500",
                          marginBottom: "15px",
                          textAlign: "center",
                        }}
                      >
                        Icon
                      </Typography>
                    </Box>
                    <Box sx={{}}>
                      <Typography
                        className="para_label"
                        style={{ marginTop: "15px" }}
                      >
                        Package Name
                      </Typography>
                      <RHFTextField
                        name="title"
                        placeholder="Enter package name here..."
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        required
                      />
                    </Box>
                  </Box>
                  <Box sx={{}}>
                    <LabelStyle>Fragile</LabelStyle>
                    <Switch
                      name="fragile"
                      defaultChecked
                      label="Fragile"
                      checked={fragile}
                      onChange={(e) => setFragile(e.target.checked)}
                    />
                  </Box>
                  <Stack alignItems="center" sx={{ mt: 3 }}>
                    <LoadingButton
                      sx={{
                        padding: "15px 0",
                        borderRadius: "5px",
                        width: "450px",
                      }}
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                    >
                      {!isEdit ? "ADD" : "Save Changes"}
                    </LoadingButton>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    width: "534px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PlanPremiumIcon sx={{ height: 400 }} />
                  <Typography sx={{ width: 400, textAlign: "center" }}>
                    Create a new package to be added to the available packages
                    list.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </FormProvider>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
