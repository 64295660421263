import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import {
  Toolbar,
  Typography,
  InputAdornment,
  TextField,
  Grid,
  Switch,
} from "@mui/material";
// components
import Iconify from "../../../../components/Iconify";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import InputStyle from "../../../../components/InputStyle";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDeleteUsers: PropTypes.func,
};

// Month and year validation
const monthList = [
  { id: 1, name: "Jan", value: "0" },
  { id: 2, name: "Feb", value: "1" },
  { id: 3, name: "March", value: "2" },
  { id: 4, name: "April", value: "3" },
  { id: 5, name: "May", value: "4" },
  { id: 6, name: "June", value: "5" },
  { id: 7, name: "July", value: "6" },
  { id: 8, name: "Aug", value: "7" },
  { id: 9, name: "Sept", value: "8" },
  { id: 10, name: "Oct", value: "9" },
  { id: 11, name: "Nov", value: "10" },
  { id: 12, name: "Dec", value: "11" },
];

const yearList = [
  { id: 1, name: "2023", value: "2023" },
  { id: 2, name: "2024", value: "2024" },
  { id: 3, name: "2025", value: "2025" },
  { id: 4, name: "2026", value: "2026" },
  { id: 5, name: "2027", value: "2027" },
  { id: 6, name: "2028", value: "2028" },
  { id: 7, name: "2029", value: "2029" },
  { id: 8, name: "2030", value: "2030" },
  { id: 9, name: "2031", value: "2031" },
  { id: 10, name: "2032", value: "2032" },
  { id: 11, name: "2033", value: "2033" },
  { id: 12, name: "2034", value: "2034" },
  { id: 13, name: "2035", value: "2035" },
];
const d = new Date();
let currentMonth = d.getMonth();
let currentYear = d.getFullYear();

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});
export default function UserListToolbar({
  numSelected,
  filterData,
  setFilterValue,
  searchValue,
  setSearchValue,
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const [customFilterData, setCustomFilterData] = useState([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState(null);
  const [type, setType] = useState("string");
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    if (filterData) {
      const data = [];
      filterData.map((item, index) =>
        data.push({
          title: item.label,
          value: item.value,
          type: item.type,
          key: index,
        })
      );
      setCustomFilterData(data);
      setSelectedCustomFilter(data[0]);
      setType(data[0]?.type);
      setFilterValue(data[0]?.value);
    }
  }, [filterData]);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? "primary.main" : "text.primary",
          bgcolor: isLight ? "primary.lighter" : "primary.dark",
        }),
      }}
    >
      {
        <Autocomplete
          id="filter-demo"
          options={customFilterData}
          getOptionLabel={(option) => option.title}
          filterOptions={filterOptions}
          sx={{ width: "18%", outline: "none" }}
          renderInput={(params) => (
            <TextField {...params} label="Custom filter" />
          )}
          value={selectedCustomFilter}
          onChange={(e, value) => {
            setSelectedCustomFilter(value);
            setFilterValue(value.value);
            setType(value.type);

            if (value.type === "date") {
              setSearchValue(month + "," + year);
            } else if (value.type === "boolean") {
              setSearchValue(true);
            } else {
              setSearchValue("");
            }
          }}
          key="customFilter"
          defaultValue={selectedCustomFilter}
        />
      }

      {numSelected > 0 && (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      )}
      {type === "string" && (
        <InputStyle
          className={"userListToolbar"}
          style={{ marginLeft: "50px" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={
            "Search " + selectedCustomFilter?.title + "..." || "Search here..."
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      {type === "number" && (
        <InputStyle
          type="number"
          className={"userListToolbar"}
          style={{ marginLeft: "50px" }}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={
            "Search " + selectedCustomFilter?.title + "..." || "Search here..."
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={"eva:search-fill"}
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      {type === "date" && (
        <Grid style={{ width: "35%", display: "flex", marginLeft: "50px" }}>
          <Autocomplete
            id="filter-demo"
            options={monthList}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            sx={{ width: "50%", outline: "none" }}
            renderInput={(params) => <TextField {...params} label="Month" />}
            onChange={(e, value) => {
              setMonth(value.value);
              setSearchValue(value.value + "," + year);
            }}
            defaultValue={monthList.find(
              (option) => option.name === monthList[month]?.name
            )}
            key={monthList.id}
          />

          <Autocomplete
            id="filter-demo"
            options={yearList}
            getOptionLabel={(option) => option.name}
            filterOptions={filterOptions}
            sx={{ width: "50%", outline: "none", marginLeft: "20px" }}
            renderInput={(params) => <TextField {...params} label="Year" />}
            onChange={(e, value) => {
              setYear(value.value);
              setSearchValue(month + "," + value.value);
            }}
            defaultValue={yearList.find(
              (option) => option.name === year?.toString()
            )}
            key={yearList.id}
          />
        </Grid>
      )}
      {type === "boolean" && (
        <Grid style={{ width: "35%", display: "flex", marginLeft: "50px" }}>
          <Switch
            defaultChecked
            color="warning"
            value={flag}
            onChange={() => {
              setFlag((preState) => !preState);
              setSearchValue(!flag);
            }}
          />
        </Grid>
      )}
    </RootStyle>
  );
}
