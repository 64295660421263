import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchTransactions = createAsyncThunk(
  "getTransactions",
  async (payload) => {
    payload.cityId = localStorage.getItem("manager_city");
    const response = await axios.post("/transactions/get/all", payload);
    return response;
  }
);
const getTransactions = createSlice({
  name: "getTransactions",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchTransactions.rejected, (state, action) => {
      console.log("Error............", action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { reset } = getTransactions.actions;
export default getTransactions.reducer;
