import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchManagers = createAsyncThunk(
  "getManagers",
  async (payload) => {
    const response = await axios.post("/managers/get/all", payload);
    return response;
  }
);
const getManagers = createSlice({
  name: "getManagers",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchManagers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchManagers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchManagers.rejected, (state, action) => {
      console.log("Error............", action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { reset } = getManagers.actions;
export default getManagers.reducer;
