import { createSlice } from "@reduxjs/toolkit";

const getLoginUser = createSlice({
  name: "getLoginUser",
  initialState: {
    isLoading: false,
    data: null, 
    isError: false,
  },
  reducers: {
    setLoginUser: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = false;
    },
    reset: (state) => {
      state.isLoading = false;
      state.data = null; // Change this to null
      state.isError = false;
    },
  },
});

export const { setLoginUser, reset } = getLoginUser.actions;

const getLoginUserReducer = getLoginUser.reducer;
export default getLoginUserReducer;
