import { Box, Button, Divider, Typography, Stack, Dialog } from "@mui/material";
// components
import Iconify from "./Iconify";
// assets
import { SeoIllustration } from "../assets";
import { FormProvider, RHFSelect } from "./hook-form";
import { useForm } from "react-hook-form";

export default function CheckoutOrderComplete({ ...other }) {
  const defaultValues = {};
  const methods = useForm({
    resolver: null,
    defaultValues,
  });
  if (other.open)
    return (
      <Dialog
        open={other.open}
        onClose={other.onClose}
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        <Box sx={{ p: 4, maxWidth: "100%", width: "100%", margin: "auto" }}>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" paragraph>
              {other?.title}
            </Typography>

            <SeoIllustration sx={{ height: 260, my: 3 }} />
            {other?.cancelBooking && (
              <FormProvider methods={methods}>
                <RHFSelect
                  style={{ marginBottom: "30px", width: "80%" }}
                  name="reason"
                  label="Select Reason"
                  placeholder="Reason"
                  onChange={(e)=>other?.setCancelReason(e.target.value)}
                >
                  <option value="" />
                  {other?.cancelReasons?.map((option, index) => (
                    <option key={index} value={option?.reason}>
                      {option?.reason}
                    </option>
                  ))}
                </RHFSelect>
              </FormProvider>
            )}
            <Typography align="center" paragraph>
              {other?.subTitle} &nbsp;
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />
          <Stack
            direction={{ xs: "column-reverse", sm: "row" }}
            justifyContent="space-between"
            spacing={2}
          >
            <Button
              color="inherit"
              onClick={other.onClose}
              startIcon={<Iconify icon={"eva:arrow-ios-back-fill"} />}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={other.handleSubmit}>
              Yes
            </Button>
          </Stack>
        </Box>
      </Dialog>
    );
  else return null;
}
