import { useEffect, useState } from "react";
import larrow from "../../images/arrow-left.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ThemeProvider } from "@mui/material/styles";
import { Grid, Card, Button, Container, Typography, Box } from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import FormulaForm from "../common/formulaForm";
import useSettings from "../../hooks/useSettings";
import { SeoIllustration } from "../../assets";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Iconify from "../../components/Iconify";
import CloseIcon from "@mui/icons-material/Close";
import LoadingScreen from "src/components/LoadingScreen";
import moment from "moment";
import { getFields } from "./groupList";
import { getDate } from "src/utils/methods";

export default function NewGroup() {
  const { themeStretch } = useSettings();

  const [groupName, setGroupName] = useState("");
  const [field, setField] = useState("");
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState("");
  const [operator, setOperator] = useState("");
  const [formulaData, setFormulaData] = useState([]);
  const managerId = localStorage.getItem("manager_id");
  const [formulaEditData, setFormulaEditData] = useState(null);
  const [editingRow, setEditingRow] = useState(false);
  const { pathname } = useLocation();
  const formulaArray = {
    field: field,
    operator: operator,
    value: values,
  };

  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const methods = useForm({});
  const navigate = useNavigate();
  const getValue = (value) => {
    console.log("testing date............", value);
    if (typeof value === "object") {
      if (value?._seconds) return getDate(value);
      else return moment(value).format(`D MMMM, YYYY`);
    } else if (typeof value === "boolean") return value ? "true" : "false";
    else return value;
  };
  const handleOnClick = () => {
    navigate(-1);
  };

  const payload = [];
  payload.push(formulaArray);
  const onSubmit = () => {
    setLoading(true);
    if (!isEdit) {
      const data = {
        groupName: groupName,
        isAuto: true,
        managerId: managerId,
        formula: formulaData,
      };
      axios
        .post("/groups/add", data)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate("/users/groups");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      const data = {
        groupName: groupName,
        isAuto: true,
        managerId: managerId,
        formula: formulaData,
      };
      axios
        .put("/groups/update/" + id, data)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate("/users/groups");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      axios
        .get("/groups/get/" + id)
        .then((res) => {
          setFormulaData(res.data.result.formula);
          setGroupName(res.data.result.groupName);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [isEdit, id]);

  const handlerRemoveData = (id) => {
    const newFD = [...formulaData];
    newFD.splice(id, 1);
    setFormulaData(newFD);
  };

  const handlerEditData = (id) => {
    const newFD = [...formulaData];
    const e = newFD.splice(id, 1);
    setFormulaEditData({ ...e[[0]], id });
    setEditingRow(true);
  };
  const handleFormulaData = (obj) => {
    const id = obj.id;
    delete obj.id;
    const newFormulaData = [...formulaData];
    newFormulaData.splice(id, 1, obj);
    setFormulaData(newFormulaData);
  };

  console.log(operator, values, field, formulaArray);
  console.log(formulaData);
  return (
    <>
      <ThemeProvider theme={{}}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            marginTop: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <LoadingScreen />}
        </div>
        {!loading && (
          <Container
            maxWidth={themeStretch ? false : "xl"}
            style={{ paddingLeft: "16px", paddingRight: "16px" }}
          >
            <Box sx={{ width: "100%" }}>
              <FormProvider
                methods={methods}
                onSubmit={(e) => e.preventDefault()}
              >
                <Box
                  className="px-0"
                  sx={{ padding: "24px", paddingBottom: "0" }}
                  style={{ display: "flex", borderBottom: "1px solid #E04E2C" }}
                >
                  <img
                    src={larrow}
                    onClick={handleOnClick}
                    height={20}
                    alt="back"
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                  {!isEdit ? (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      CREATE A NEW GROUP
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      EDIT GROUP
                    </Typography>
                  )}
                </Box>
                <Box className="px-0">
                  <HeaderBreadcrumbs
                    heading={""}
                    links={[
                      {
                        name: "Groups",
                        href: PATH_DASHBOARD.allusers.userList,
                      },
                      {
                        name: "Add new",
                        href: PATH_DASHBOARD.group.root,
                      },
                    ]}
                  />
                </Box>

                <Box
                  sx={{
                    width: "80%",
                    maxWidth: "1235px",
                    marginBottom: "30px",
                  }}
                >
                  <Card sx={{ padding: "40px", borderRadius: "0" }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 5,
                      }}
                      className="newGroups"
                    >
                      <Box>
                        <RHFTextField
                          name="title"
                          label="Group Name"
                          value={groupName}
                          onChange={(e) => setGroupName(e.target.value)}
                          required
                        />

                        <Typography sx={{ marginTop: "10px" }}>
                          Formula{" "}
                        </Typography>
                        <FormulaForm
                          setOperator={setOperator}
                          setValues={setValues}
                          values={values}
                          setField={setField}
                          field={field}
                          onFormulaData={(data) => {
                            setFormulaData((prev) => [...prev, data]);
                          }}
                          onsetFormulaData={() => setFormulaData([])}
                          isEdit={isEdit}
                          formulaEditData={formulaEditData}
                          editingRow={editingRow}
                          setOperationEditingRow={(d) => setEditingRow(d)}
                          handleFormulaData={handleFormulaData}
                        />
                        <Button
                          sx={{ marginTop: "43px", width: "500px" }}
                          type="submit"
                          variant="contained"
                          fullWidth
                          size="large"
                          disabled={formulaData?.length === 0 ? true : false}
                          onClick={() => onSubmit()}
                        >
                          {!isEdit ? "Create Group" : "Save Changes"}
                        </Button>
                      </Box>

                      {formulaData.length > 0 && (
                        <Grid item xs={7}>
                          <Typography
                            sx={{
                              marginTop: "5px",
                              marginBottom: "20px",
                              fontSize: "24px",
                              fontWeight: 300,
                            }}
                          >
                            Formulas{" "}
                          </Typography>
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead
                                sx={{
                                  "& .MuiTableCell-root": {
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                  },

                                  boxShadow: "none !important",
                                }}
                              >
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ boxShadow: "none !important" }}
                                  >
                                    Field
                                  </TableCell>
                                  <TableCell align="center">Operator</TableCell>
                                  <TableCell align="center">Value</TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ boxShadow: "none !important" }}
                                  >
                                    Remove
                                  </TableCell>
                                  {isEdit && (
                                    <TableCell
                                      align="center"
                                      sx={{ boxShadow: "none !important" }}
                                    >
                                      Edit
                                    </TableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {formulaData.map((row, i) => (
                                  <TableRow
                                    hover
                                    key={i}
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        border:
                                          "1px solid rgba(224, 224, 224, 1)",
                                      },
                                    }}
                                  >
                                    <TableCell align="center">
                                      {getFields(row?.field)}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row?.operator}
                                    </TableCell>
                                    <TableCell align="center">
                                      {getValue(row?.value)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <span
                                        onClick={() => handlerRemoveData(i)}
                                      >
                                        <Iconify
                                          sx={{
                                            mr: 2,
                                            width: 20,
                                            height: 20,
                                          }}
                                        >
                                          <CloseIcon sx={{ color: "red" }} />
                                        </Iconify>
                                      </span>
                                    </TableCell>
                                    {isEdit && (
                                      <TableCell
                                        align="center"
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <span
                                          onClick={() => handlerEditData(i)}
                                        >
                                          <Iconify
                                            icon={"eva:edit-fill"}
                                            style={{ marginRight: "5px" }}
                                          />
                                          edit
                                        </span>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      )}
                      {formulaData.length === 0 && (
                        <Box item sx={{ alignSelf: "center" }}>
                          <SeoIllustration sx={{ height: 350 }} />
                          <Typography sx={{ mt: 3, maxWidth: 600 }}>
                            At Pickup Ninja, we make it easy for you to send
                            notifications to a specific group of people. Whether
                            you want to share updates, reminders, or important
                            information, creating a notification group is simple
                            and efficient.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Box>
              </FormProvider>
            </Box>
          </Container>
        )}
      </ThemeProvider>
    </>
  );
}
