import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import {
  Button,
  Container,
  Typography,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import Campaigns from "../campaigns/campaigns";
import NotificationTable from "./NotificationTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DefaultNotificationList() {
  const { themeStretch } = useSettings();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={{}}>
      <Page title="Notifications">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="NOTIFICATION"
                  {...a11yProps(0)}
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">
              <Button
                className="createBtn"
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.defaultNotification.newNotification}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                style={{ background: "#E04E2C" }}
              >
                New Notification
              </Button>
              <NotificationTable />
            </TabPanel>
            <TabPanel value={value} index={1} className="tabPanel">
              <Button
                className="createBtn"
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.campaigns.newCampaign}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                style={{ background: "#E04E2C" }}
              >
                New Campaign
              </Button>
              <Campaigns />
            </TabPanel>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, deleteNotification }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => deleteNotification(row?.id)}
          sx={{ color: "error.main" }}
        >
          <Iconify
            icon={"eva:trash-2-outline"}
            style={{ marginRight: "5px" }}
          />
          Delete
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.notification.editById}/${row?.id}`}
        >
          <Iconify icon={"eva:edit-fill"} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
