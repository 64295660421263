import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Conformation from "../../components/Conformation";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box,
  IconButton,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "src/components/LoadingScreen";
import { fetchNotifications, reset } from "src/redux/slices/GetNotifications";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Image from "src/components/Image";
import Label from "../../components/Label";
import { getDate } from "src/utils/methods";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

const TABLE_HEAD = [
  { id: "icon", label: "Icon", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
  { id: "created", label: "Created", alignRight: false },
  { id: "isActive", label: "Active", alignRight: true },
  { id: "Action", label: "Action", alignRight: true },
];

// ----------------------------------------------------------------------

export default function CouponList() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getNotifications);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [couponList, setCouponList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updated, setUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({ id: null, state: false });
  const [filterData, setFilterData] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = couponList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = couponList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setCouponList(deleteUsers);
  };
  const isNotFound = couponList?.length === 0 ? true : false;

  const deleteCoupon = () => {
    setOpen(false);
    const payload = new FormData();
    payload.append("isActive", !updateObj.state);
    axios
      .put(`notifications/update/${updateObj.id}`, payload)
      .then((res) => {
        console.log(res.data);
        setUpdated(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page, updated]);
  useEffect(() => {
    getCoupon();
  }, [state.data]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.managerId = localStorage.getItem("manager_id");
      payload.type = "all";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;

      await axios
        .post("/notifications/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchNotifications(payload));
          } else {
            setCouponList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getCoupon = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchNotifications(payload));
      } else {
        setCouponList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Notifications">
        <Box sx={{ width: "100%" }}>
          <Card sx={{ borderRadius: "0" }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDeleteUsers={() => handleDeleteMultiUser(selected)}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}
            />
            <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={couponList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {couponList?.map((row) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {row?.icon?.length > 0 ? (
                              <Image
                                disabledEffect
                                alt="image"
                                src={row?.icon}
                                sx={{
                                  borderRadius: "50%",
                                  width: 64,
                                  height: 64,
                                }}
                              />
                            ) : (
                              <CircleNotificationsIcon
                                sx={{
                                  fontSize: 64,
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.title}
                          </TableCell>
                          <TableCell width={"50%"}>{row?.message}</TableCell>
                          <TableCell align="right">
                            {getDate(row?.created)}
                          </TableCell>
                          <TableCell align="right">
                            <Label color={row?.isActive ? "success" : "error"}>
                              {row?.isActive ? "Yes" : "No"}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <ThreedotMenu
                              row={row}
                              setUpdateObj={setUpdateObj}
                              setOpenHandler={setOpen}
                              />
                          </TableCell>
                          
                        </TableRow>
                      );
                    })}
                    {isNotFound && (
                      <TableRow>
                        <TableCell align="center" sx={{ py: 3 }} colSpan={15}>
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={deleteCoupon}
          title={`${updateObj.state ? "Block" : "Active"} Confirmation`}
          subTitle={`Do you want to ${
            updateObj.state ? "block" : "active"
          } the Notification?`}
        />
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, setUpdateObj, setOpenHandler }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHandler(true);
            handleClose();
            setUpdateObj({ id: row?.id, state: row?.isActive });
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify
            sx={{
              mr: 2,
              width: 20,
              height: 20,
            }}
          >
            {!row?.isActive ? (
              <DoneIcon sx={{ color: "green" }} />
            ) : (
              <CloseIcon sx={{ color: "red" }} />
            )}
          </Iconify>
          {row.isActive ? (
            <span style={{ color: "red" }}> Deactivate</span>
          ) : (
            <span style={{ color: "green" }}> Active</span>
          )}
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.notification.editById}/${row?.id}`}
          state={{ fromDashboard: true }}
        >
          <Iconify icon={"eva:edit-fill"} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
