import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import {
  Card,
  Table,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Conformation from "../../components/Conformation";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import GroupList from "../users/groupList";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersBusiness, reset } from "src/redux/slices/GetUsersBusiness";
import LoadingScreen from "src/components/LoadingScreen";
import { getDateTime } from "src/utils/methods";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "company", label: "Mobile", alignRight: false },
  { id: "role", label: "Joined On", alignRight: true },
  { id: "isVerified", label: "Total Orders", alignRight: true },
  { id: "status", label: "Active", alignRight: true },
];

// ----------------------------------------------------------------------

export default function UserList({ businessId }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getUsersBusiness);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updated, setUpdated] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const type="all";
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = userList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setUserList(deleteUsers);
  };

  const isNotFound = userList?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    if (businessId) getUsersList();
  }, [state.data]);
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        if (businessId) filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    if (businessId) filterSearch();
  }, [type, rowsPerPage, page, updated]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.type = type;
      payload.filter = filterValue;
      payload.search = searchValue === "" ? null : searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("testing21........", payload);
      await axios
        .post("business/get/user/" + businessId, payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.businessId = businessId;
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchUsersBusiness(payload));
          } else {
            setUserList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUsersList = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.businessId = businessId;
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        console.log("testing22........", payload);
        dispatch(fetchUsersBusiness(payload));
      } else {
        setUserList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // testing time
  useEffect(() => {
    const timestamp = moment("5 may,2023", "D MMMM, YYYY").valueOf();
    console.log(timestamp);
    const ddate = moment(1683425677001).format(`D MMMM, YYYY`);
    console.log(ddate);
  }, []);

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="User List">
        <Box sx={{ width: "100%" }}>
          <TabPanel value={0} index={0} className="tabPanel">
            <Box sx={{ width: "100%" }}>
              <Card sx={{ borderRadius: "0" }}>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  onDeleteUsers={() => handleDeleteMultiUser(selected)}
                  filterData={filterData}
                  setFilterValue={setFilterValue}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  isMonthRequired={true}
                />
                <TableContainer sx={{ minWidth: 800, height: "calc(60vh)" }}>
                  <Scrollbar>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={userList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {userList?.map((row) => {
                          const isItemSelected =
                            selected.indexOf(row.name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  alt={row?.display_name}
                                  src={row?.photo_url}
                                  sx={{ mr: 2, fontSize: "16px" }}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.display_name?.replace(
                                    /(\w)(\w*)/g,
                                    (_, g1, g2) =>
                                      g1.toUpperCase() + g2.toLowerCase()
                                  ) || ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                {row?.phone_number}
                              </TableCell>
                              <TableCell align="right">
                                {getDateTime(row?.created_time)}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  paddingRight: "40px",
                                }}
                              >
                                {row?.orders}
                              </TableCell>
                              <TableCell align="right">
                                {row?.isActive === true ? (
                                  <DoneIcon sx={{ color: "green" }} />
                                ) : (
                                  <CloseIcon sx={{ color: "red" }} />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {isNotFound && (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ py: 3 }}
                              colSpan={8}
                            >
                              No data found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[50, 100, 250]}
                  component="div"
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Box>
          </TabPanel>
          <TabPanel  index={1} className="tabPanel">
            <Button
              className="createBtn"
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.group.newGroup}
              startIcon={<Iconify icon={"eva:plus-fill"} />}
              style={{ background: "#E04E2C" }}
            >
              New Group
            </Button>
            <GroupList />
          </TabPanel>
        </Box>

        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          title={"Block Confirmation"}
          subTitle={"Do you want to block the user?"}
        />
      </Page>
    </ThemeProvider>
  );
}
