import { useState, useEffect } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import {
  Card,
  Table,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Tabs,
  Box,
  CircularProgress,
  Tooltip,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Conformation from "../../components/Conformation";

// sections
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../../sections/@dashboard/user/list";
import GroupList from "./groupList";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, reset } from "src/redux/slices/GetUsers";
import LoadingScreen from "src/components/LoadingScreen";
import { getDate } from "../../utils/methods";
// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function abc(index) {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "company", label: "Mobile", alignRight: false },
  { id: "role", label: "Joined On", alignRight: true },
  { id: "isVerified", label: "Total Orders", alignRight: true },
  { id: "status", label: "Active", alignRight: true },
  { id: "Action", label: "Action", alignRight: true },
];

// ----------------------------------------------------------------------

export default function UserList(props) {
  const { themeStretch } = useSettings();
  const { activeTab } = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getUsers);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [userList, setUserList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(activeTab === "users" ? 0 : 1);
  const [updated, setUpdated] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [type, setType] = useState("all");
  const [filterData, setFilterData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({ id: null, state: false });
  const navigate = useNavigate();
  const nameStyle = {
    textDecoration: "none",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate("/users/users");
    else if (newValue === 1) navigate("/users/groups");
  };

  const [c_Data, setc_Data] = useState(0);

  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = userList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setUserList(deleteUsers);
  };

  const isNotFound = userList?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    getUsersList();
  }, [state.data]);
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [type, rowsPerPage, page, updated]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.type = type;
      payload.filter = filterValue;
      payload.search = searchValue === "" ? null : searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("testing21........", payload);
      await axios
        .post("/users/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchUsers(payload));
          } else {
            setUserList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUsersList = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchUsers(payload));
      } else {
        setUserList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateUser = () => {
    setOpen(false);
    const payload = new FormData();
    payload.append("isActive", !updateObj.state);
    console.log("testing91....", payload);
    axios
      .put(`/users/update/${updateObj.id}`, payload)
      .then((res) => {
        console.log("user actions............", res);
        setUpdated(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // testing time
  useEffect(() => {
    const timestamp = moment("5 may,2023", "D MMMM, YYYY").valueOf();
    console.log(timestamp);
    const ddate = moment(1683425677001).format(`D MMMM, YYYY`);
    console.log(ddate);
  }, []);

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="User List">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="USERS"
                style={{
                  color: "#030303",
                  fontSize: "32px",
                  fontWeight: "300",
                }}
              />
              <Tab
                label="GROUPS"
                style={{
                  color: "#030303",
                  fontSize: "32px",
                  fontWeight: "300",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} className="tabPanel">
            <Box sx={{ width: "100%" }}>
              <Card sx={{ borderRadius: "0" }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    background: "#f4f6f8",
                    borderRadius: "0",
                    padding: "10px 10px 0 10px",
                  }}
                >
                  <Tabs
                    value={c_Data}
                    onChange={pageChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="All"
                      {...abc(0)}
                      onClick={() => setType("all")}
                    />
                    <Tab
                      label="Active"
                      {...abc(1)}
                      onClick={() => setType("active")}
                    />
                    <Tab
                      label="Banned"
                      {...abc(2)}
                      onClick={() => setType("banned")}
                    />
                  </Tabs>
                </Box>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  onDeleteUsers={() => handleDeleteMultiUser(selected)}
                  filterData={filterData}
                  setFilterValue={setFilterValue}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  isMonthRequired={true}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={userList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {userList?.map((row, index) => {
                          const isItemSelected =
                            selected.indexOf(row.id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <Tooltip
                                title="Go to User's Details"
                                placement="bottom"
                              >
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  component={RouterLink}
                                  to={PATH_DASHBOARD.users.userDetails}
                                  state={row}
                                  style={nameStyle}
                                >
                                  <Avatar
                                    alt={"User's Photo url."}
                                    src={row?.photo_url}
                                    sx={{ mr: 2, fontSize: "16px" }}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {row?.display_name?.replace(
                                      /(\w)(\w*)/g,
                                      (_, g1, g2) =>
                                        g1.toUpperCase() + g2.toLowerCase()
                                    ) || ""}
                                  </Typography>
                                </TableCell>
                              </Tooltip>
                              <TableCell align="left">
                                {row?.phone_number}
                              </TableCell>
                              <TableCell align="right">
                                {getDate(row?.created_time)}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "right",
                                  paddingRight: "40px",
                                }}
                              >
                                {row?.orders}
                              </TableCell>
                              <TableCell align="right">
                                {row?.isActive === true ? (
                                  <DoneIcon sx={{ color: "green" }} />
                                ) : (
                                  <CloseIcon sx={{ color: "red" }} />
                                )}
                              </TableCell>

                              <TableCell align="right">
                                <UserMoreMenu
                                  setUpdateObj={setUpdateObj}
                                  setOpenHandler={setOpen}
                                  row={row}
                                  isCopyIdOpt={true}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {isNotFound && (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ py: 3 }}
                              colSpan={8}
                            >
                              No data found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[50, 100, 250]}
                  component="div"
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} className="tabPanel">
            <Button
              className="createBtn"
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.group.newGroup}
              startIcon={<Iconify icon={"eva:plus-fill"} />}
              style={{ background: "#E04E2C" }}
            >
              New Group
            </Button>
            <GroupList />
          </TabPanel>
        </Container>
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={updateUser}
          title={`${updateObj.state ? "Block" : "Active"} Confirmation`}
          subTitle={`Do you want to ${
            updateObj.state ? "block" : "active"
          } the user?`}
        />
      </Page>
    </ThemeProvider>
  );
}
