import React, { useState, useEffect } from "react";
import BookingDetailsTab from "src/components/common-component/BookingDetailsTab";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import larrow from "../../images/arrow-left.svg";
import plusSquare from "../../images/plus-square.svg";
import RiderImage from "../../images/rider.svg";
import ArrowLeft from "../../images/arrow-circle-left.svg";
import ArrowRight from "../../images/arrow-circle-right.svg";
import Label from "../../components/Label";
import {
  Card,
  Table,
  Avatar,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Tab,
  Tabs,
  Box,
  Grid,
  Rating,
  Chip,
  Badge,
  Skeleton,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import {
  GoogleMap,
  Marker,
  useLoadScript,
  OverlayView,
  Polyline,
} from "@react-google-maps/api";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import c_circle from "../../images/call-circle.svg";
import m_circle from "../../images/message-circle.svg";
import ninja_head from "../../images/ninja_head.png";
import axios from "../../utils/axiosconfig";
import verified_user from "../../images/SealCheck.png";
import Destination from "src/components/common-component/Destination";
import LoadingScreen from "src/components/LoadingScreen";
import { getDate, getDateTime } from "src/utils/methods";

// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const getChipColor = (status) => {
  if (!status) return "error";
  if (status === "pending") return "default";
  else if (status === "waiting") return "warning";
  else if (status === "accepted") return "info";
  else if (status === "completed") return "success";
  else return "error";
};
export default function BookingDetailsgs() {
  const [loading, setLoading] = useState(false);
  const [gst, setGst] = useState(0);
  const [rateList, setRateList] = useState([]);
  const hideRiderDetails = () => setDetails(true);
  const hidehandelOnClickPlus = () => setForm(false);
  const location = useLocation();
  const from = location.state.data;
  const tableData = location.state.tableData;
  const [activeBookingId, setActiveBookingId] = useState(from.id);
  const [activeIndex, setActiveIndex] = useState(location.state.index);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [value, setValue] = useState(0);
  const [riderList, setRiderList] = useState([]);
  const [libraries] = useState(["geometry"]);
  const [transactionDetails, setTransactionDetails] = useState();
  const [isRiderLoading, setIsRiderLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [details, setDetails] = useState(true);

  const [form, setForm] = useState(false);

  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const [transactionId, setTransactionId] = useState(null);

  const [price, setPrice] = useState(null);

  const handleNameChange = (event) => {
    setTransactionId(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const isButtonDisabled = !transactionId || !price;

  const handleOnClickPlus = () => {
    setForm(true);
  };

  const openRiderDetails = () => {
    setDetails(false);
  };
  const previousData = () => {
    let newIndex = activeIndex - 1;
    if (newIndex < 0) {
      newIndex = tableData.length - 1;
    }
    setActiveIndex(newIndex);
    setActiveBookingId(tableData[newIndex]?.id);
  };
  const nextData = () => {
    let newIndex = activeIndex + 1;
    if (newIndex >= tableData.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
    setActiveBookingId(tableData[newIndex]?.id);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const copyMobileNo = (mobileNo) => {
    navigator.clipboard
      .writeText(mobileNo)
      .then(() => {
        enqueueSnackbar("Copied to clipboard.", {
          style: { marginTop: "100px" },
        });
      })
      .catch((error) => {
        console.log("Failed to copy link:", error);
      });
  };
  const isNotFounds = transactionDetails?.length == 0 ? true : false;

  const addTransactions = () => {
    if (transactionId.length > 0 && price !== null) {
      setLoading(true);
      let payload = {
        bookingId: activeBookingId,
        transactionId: transactionId,
        amount: price,
        type: "offline",
        isSuccessful: true,
        status: "success",
      };
      axios
        .post(`/transactions/admin-add`, payload)
        .then((res) => {
          setLoading(false);
          setForm(false);
          setTransactionId(null);
          setPrice(null);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    }
  };

  const getGst = () => {
    axios
      .get(`/gst/get`)
      .then((res) => {
        setGst(res.data.result.gst);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getRate = () => {
    axios
      .get(`/rates/get`)
      .then((res) => {
        setRateList(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getTransaction = () => {
    axios
      .get(`/bookings/get/transactions/${activeBookingId}`)
      .then((res) => {
        setTransactionDetails(res.data.result);
      })
      .catch((e) => {
        console.log("Details Transaction error", e);
      });
  };

  // rider details from API
  const getRiderDetails = () => {
    axios
      .get(`/bookings/get/riderDetails/${activeBookingId}`)
      .then((res) => {
        console.log("qqqqq", res.data.result);
        setRiderList(res.data.result || []);
        setIsRiderLoading(false);
      })
      .catch((err) => console.log("Error in rider details", err));
  };

  //    calling customer details
  const getBookingDetails = async () => {
    try {
      const res = await axios.get(
        `/bookings/admin/dashboard/${activeBookingId}`
      );
      setUserInfo(res.data.result.user);
      setBookingInfo(res.data.result);
      setPrice(res.data?.result?.amount || null);
      setDataLoaded(false);
    } catch (e) {
      setDataLoaded(false);
      console.log(e);
    }
  };
  useEffect(() => {
    getTransaction();
    getBookingDetails();
    getRiderDetails();
  }, [form, activeIndex]);

  useEffect(() => {
    getGst();
    getRate();
  }, [activeIndex]);
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
  }));

  const containerStyle = {
    height: "calc(100vh - 120px)",
  };

  const center = {
    lat: 20.28114,
    lng: 85.79215,
  };
  const getLocationOnMap = (booking) => {
    const paths = [];
    booking?.tasksList?.map((task) => {
      if (task?.order === 0) return null;
      if (task?.startDest) {
        const location = task?.startDest?.location;
        paths.push({
          lat: location?._latitude,
          lng: location?._longitude,
        });
      }
      if (task?.endDest) {
        const location = task?.endDest?.location;
        paths.push({
          lat: location?._latitude,
          lng: location?._longitude,
        });
      }
      return null;
    });
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      scale: 4,
    };
    return (
      <>
        <Marker position={paths[0]} name="" />
        <Marker position={paths[1]} name="" />
        <Polyline
          path={paths}
          options={{
            strokeColor: "black",
            geodesic: true,
            strokeOpacity: 0,
            strokeWeight: 0.5,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "25px",
              },
            ],
          }}
        />
      </>
    );
  };
  //get all active Riders on map
  const getActiveRidersOnMap = (ridersList) => {
    if (riderList?.length === 0) return null;
    const rider = riderList[0];
    const item = rider?.riderObj;
    const location = item?.location;
    let position = {
      lat: parseFloat(location?._latitude),
      lng: parseFloat(location?._longitude),
    };
    return (
      <>
        <Marker
          position={position}
          name=""
          icon={{
            url: item?.photo_url || ninja_head,
            fillColor: "green",
            boxShadow: "10px 10px black",
            scaledSize: new window.google.maps.Size(50, 50),
            borderRadius: "50%",
          }}
        ></Marker>
        <OverlayView
          position={position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <Tooltip title={"distance"} arrow>
            <Typography
              sx={{
                backgroundColor: "black",
                padding: "10px",
                borderRadius: "5px",
                color: "white",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {item?.display_name}{" "}
              <span style={{ color: "orangered", backgroundColor: "black" }}>
                ({item?.code})
              </span>
            </Typography>
          </Tooltip>
        </OverlayView>
      </>
    );
  };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAy_K9v6abejZ2FeiUW6q87b44t0sE2QsI",
    libraries: libraries,
  });

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const getRiderStatusDetails = () => {
    return riderList?.map((item) => {
      return (
        <Box style={{ display: "flex", alignItems: "center", padding: "8px" }}>
          <Label
            style={{ marginLeft: "30px" }}
            color={getChipColor(item?.status)}
          >
            {item?.status?.replace(
              /(\w)(\w*)/g,
              (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
            ) || ""}
          </Label>
          <Typography
            style={{
              padding: "2px",
              margin: "5px",
              color: "blue",
              fontWeight: 400,
            }}
          >
            {getDateTime(item?.created)}
          </Typography>
        </Box>
      );
    });
  };
  const riderDetailCard = () => {
    return (
      <Box
        style={{ background: "white", maxWidth: "850px", marginTop: "20px" }}
      >
        {!details ? (
          <>
            <Box
              style={{
                minHeight: "480px",
                maxHeight: "480px",
                overflow: "auto",
              }}
            >
              <Box
                style={{
                  padding: "10px 4px 4px",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #E04E2C",
                }}
              >
                <img
                  alt="arrow icon"
                  src={larrow}
                  onClick={hideRiderDetails}
                  height={20}
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                />

                <Typography
                  style={{
                    color: "#030303",
                    fontWeight: 400,
                    background: "white",
                    fontSize: "15px",
                    paddingBottom: "2px",
                  }}
                >
                  RIDER STATUS DETAILS
                </Typography>
              </Box>
              <Box
                styel={{
                  minHeight: "240px",
                  maxHeight: "240px",
                  overflow: "auto",
                }}
              >
                {getRiderStatusDetails()}
              </Box>
            </Box>
          </>
        ) : (
          <>
            {" "}
            <Box>
              <Box
                style={{
                  background: "white",
                }}
              >
                <Typography
                  style={{
                    borderBottom: "1px solid #E04E2C",
                    margin: "0 26px",
                    background: "white",
                    padding: "15px 0 5px 0",
                    fontFamily: "Inter,sans-serif",
                  }}
                >
                  RIDER DETAILS
                </Typography>
              </Box>
              <Box
                style={{
                  padding: "24px 26px 14px",
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "240px",
                  boxShadow: "0 0 6px 0px #e6e6e6",
                  position: "relative",
                  background: "white",
                }}
              >
                <Box
                  className="hidescroll"
                  style={{ flex: "1", overflow: "auto" }}
                >
                  {riderList === 0 ? "No riders assigned" : riderDetailing()}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  };
  const RiderSkeletonCard = () => {
    return (
      <Box
        sx={{
          border: "1px solid #EBEBEB",
          padding: 2,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="circular"
            animation="wave"
            width="3.125vw"
            height="3.125vw"
            sx={{ marginRight: "15px" }}
          />

          <Box
            sx={{
              flexDirection: "column",
              marginRight: "15px",
            }}
          >
            <Typography variant="body1">
              <Skeleton variant="text" width="5vw" />
            </Typography>

            <Typography variant="body1">
              <Skeleton variant="text" width="4vw" sx={{ fontSize: "1rem" }} />
            </Typography>
          </Box>
          <Box>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width="5vw"
              height="2vw"
              sx={{
                borderRadius: "18px",
              }}
            />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              gap: "5px",
            }}
          >
            <Skeleton
              variant="circular"
              animation="wave"
              width="2.6vw"
              height="2.6vw"
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width="2.6vw"
              height="2.6vw"
            />
            <Skeleton
              variant="circular"
              animation="wave"
              width="2.6vw"
              height="2.6vw"
            />
          </Box>
        </Box>
      </Box>
    );
  };
  const riderDetailing = () => {
    if (isRiderLoading) {
      return <RiderSkeletonCard />;
    }
    if (riderList?.length > 0) {
      const item = riderList[0];
      const rider = item?.riderObj;
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
          key={rider?.id}
        >
          <Box style={{ display: "flex", alignItems: "center", width: "80%" }}>
            <Badge
              style={{ marginRight: "9px" }}
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<SmallAvatar src={verified_user} />}
            >
              <Avatar src={rider?.photo_url} />
            </Badge>
            <Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    fontFamily: "'Cabin', sans-serif",
                  }}
                >
                  {rider?.display_name?.replace(
                    /(\w)(\w*)/g,
                    (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                  ) || ""}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 12,
                    fontFamily: "'Cabin', sans-serif",
                    color: "#7c7c7c",
                  }}
                >
                  <Box
                    sx={{
                      minHeight: "5px",
                      width: "5px",
                      background: "#7c7c7c",
                      borderRadius: "50%",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  ></Box>
                  {rider?.code}
                </Typography>
              </Box>
              <Rating
                name="read-only"
                size="small"
                value={2.5}
                precision={0.5}
                readOnly
              />
            </Box>
            <Label
              style={{ marginLeft: "30px" }}
              color={getChipColor(item?.status)}
            >
              {item?.status?.replace(
                /(\w)(\w*)/g,
                (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
              ) || ""}
            </Label>
          </Box>
          <Box style={{ display: "flex", gap: "8px", marginLeft: "auto" }}>
            <Tooltip title={"Rider status details"} placement="bottom">
              <Box
                style={{
                  height: "37px",
                  width: "37px",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => openRiderDetails()}
              >
                <img
                  src={RiderImage}
                  alt=""
                  style={{ color: "#E3552E", height: "60%", width: "60%" }}
                />
              </Box>
            </Tooltip>
            <Tooltip title={"Copy mobile number"} placement="bottom">
              <Box
                style={{
                  height: "37px",
                  width: "37px",
                  borderRadius: "50%",
                  background: "#E3552E",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  onClick={() => copyMobileNo(rider?.phone_number)}
                  src={m_circle}
                  alt="phone icon"
                  style={{ position: "absolute", height: "50%", width: "50%" }}
                />
              </Box>
            </Tooltip>
            <Box
              style={{
                height: "37px",
                width: "37px",
                borderRadius: "50%",
                background: "#E3552E",
                cursor: "pointer",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={c_circle}
                alt=""
                style={{ position: "absolute", height: "50%", width: "50%" }}
              />
            </Box>
          </Box>
        </Box>
      );
    } else
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            height: "fit-content",
          }}
        >
          <img
            alt="Rider icon"
            src={ninja_head}
            height={70}
            width={70}
            style={{ transform: "rotate(180deg)" }}
          />
          <Typography sx={{ fontSize: "20px" }}>
            Please assign a rider for this order.
          </Typography>
        </Box>
      );
  };
  if (dataLoaded) return <LoadingScreen />;
  return (
    isLoaded && (
      <ThemeProvider theme={{}}>
        <Page title="Bookings">
          <Box padding="0 17px" style={{ marginBottom: "30px" }}>
            <Box
              style={{
                display: "flex",
                marginBottom: "27px",
                marginTop: "20px",
              }}
            >
              <img
                alt="arrow icon"
                src={larrow}
                onClick={handleOnClick}
                height={20}
                style={{
                  marginRight: "10px",
                  marginTop: "7px",
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
              />
              <Typography style={{ fontSize: "25px" }}>
                BOOKING DETAILS
              </Typography>
              <Box style={{ marginLeft: "auto", display: "flex" }}>
                <img
                  src={ArrowLeft}
                  alt=""
                  style={{ cursor: "pointer", height: "45px", width: "45px" }}
                  onClick={previousData}
                />
                <img
                  src={ArrowRight}
                  alt=""
                  onClick={nextData}
                  style={{ cursor: "pointer", height: "45px", width: "45px" }}
                />
              </Box>
            </Box>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: "1",
                  width: "700px",
                  maxWidth: "850px",
                  margin: "10px",
                }}
              >
                <BookingDetailsTab
                  data={{
                    user: userInfo,
                    booking: bookingInfo,
                    bookingDetailsPage: true,
                  }}
                  bookingDetailsPage
                />
                {riderDetailCard()}
              </div>
              <div
                style={{
                  flex: "1",
                  maxWidth: "850px",
                  margin: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "800px",
                    background: "white",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      padding: "0 30px",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="NEXT DESTINATION" {...a11yProps(0)} />
                      <Tab label="MESSAGE" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel
                    value={value}
                    index={0}
                    style={{
                      overflowY: "auto",
                      paddingLeft: "0px",
                    }}
                  >
                    <Destination data={bookingInfo} />
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    style={{ padding: "0 0 63px 0" }}
                  >
                    Coming Soon
                  </TabPanel>
                </Box>
              </div>
              <div style={{ flex: 1, margin: "10px" }}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={11}
                  options={{ zoomControl: false }}
                  onLoad={onMapLoad}
                >
                  {getLocationOnMap(bookingInfo)}
                  {getActiveRidersOnMap(riderList || [])}
                </GoogleMap>
              </div>
            </div>
            <Box sx={{ width: "100%" }}>
              {form ? (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: "500px",
                      backgroundColor: "white",
                      overflow: "hidden",
                      marginTop: "50px",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        width: "85%",
                        zIndex: 1000,
                        marginTop: 200,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {loading && <CircularProgress />}
                    </div>
                    <Box
                      style={{
                        display: "flex",
                        marginTop: "15px",
                        borderBottom: "1px solid red",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#030303",
                          fontWeight: 400,
                          background: "white",
                          paddingBottom: "3px",
                          display: "flex",
                          fontSize: "25px",
                          paddingTop: "10px",
                          spacing: "3px",
                        }}
                      >
                        <img
                          alt="arrow icon"
                          src={larrow}
                          onClick={hidehandelOnClickPlus}
                          height={20}
                          style={{
                            marginRight: "10px",
                            marginTop: "3px",
                            fontWeight: "400",
                            cursor: "pointer",
                            height: "30px",
                            width: "30px",
                          }}
                        />
                        ADD TRANSACTION
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                          width: "600px",
                          marginTop: "40px",
                        }}
                      >
                        <Box
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                          }}
                        >
                          <Typography
                            style={{
                              display: "block",
                              color: "black",
                              fontWeight: "400px",
                              fontSize: "20px",
                            }}
                          >
                            Transaction ID<sup style={{ color: "red" }}>⋆</sup>
                          </Typography>

                          <input
                            type="text"
                            name="transactionId"
                            placeholder="Enter the Transaction Id"
                            value={transactionId}
                            onChange={handleNameChange}
                            style={{
                              width: "100%",
                              color: "black",
                              padding: "12px",
                              borderColor: "green",
                              borderRadius: "2px",
                              boxSizing: "border-box",
                              marginTop: "2px",
                              marginBottom: "2px",
                              fontSize: "18px",
                              cursor: "pointer",
                              "::placeholder": {
                                color: "#000",
                                fontStyle: "italic",
                                fontSize: "15px",
                              },
                              resize: "vertical",
                            }}
                          />
                          <Typography
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            Instruction: You will get the transction Id from
                            customer and copy here.
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                          }}
                        >
                          <Typography
                            style={{
                              display: "block",
                              color: "black",
                              fontWeight: "400px",
                              fontSize: "20px",
                            }}
                          >
                            Amount<sup style={{ color: "red" }}>⋆</sup>
                          </Typography>
                          <input
                            type="number"
                            id="price"
                            name="price"
                            placeholder="₹"
                            value={price}
                            onChange={handlePriceChange}
                            style={{
                              width: "100%",
                              color: "black",
                              padding: "12px",
                              borderColor: "green",
                              borderRadius: "4px",
                              boxSizing: "border-box",
                              marginTop: "6px",
                              marginBottom: "16px",
                              resize: "vertical",
                              fontSize: "18px",
                              cursor: "pointer",
                              "::placeholder": {
                                color: "#000",
                                fontSize: "15px",
                              },
                            }}
                          />
                        </Box>
                        <Box
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button
                            class="button-add"
                            onClick={addTransactions}
                            disable={isButtonDisabled}
                            style={{
                              fontWeight: "600",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "18px",
                              padding: "0.6em 1em 0.6em 0.9em",
                              color: "white",
                              background:
                                "linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%)",
                              border: "none",
                              boxShadow: "0 0.7em 1.5em -0.5em #14a73e98",
                              borderRadius: "7px",
                              marginTop: "8px",
                              cursor: isButtonDisabled
                                ? "not-allowed"
                                : "pointer",
                              opacity: isButtonDisabled ? "0.6" : "1",
                            }}
                          >
                            ADD
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {" "}
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      style={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                        borderBottom: "1px solid red",
                        marginBottom: "12px",
                        marginTop: "35px",
                        display: "flex",
                      }}
                    >
                      TRANSACTION DETAILS&nbsp;
                      <Box
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleOnClickPlus()}
                      >
                        <img
                          src={plusSquare}
                          alt=""
                          style={{
                            marginBottom: "8px",
                            marginRight: "10px",
                            marginTop: "0px",
                            width: "35px",
                            height: "35px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Typography>
                    <Card>
                      <Scrollbar>
                        <TableContainer
                          sx={{
                            minWidth: 800,
                            height: "500px",
                            padding: "7px 0",
                            overflow: "hidden",
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableCell style={{ textAlign: "left" }}>
                                Transaction Id
                              </TableCell>

                              <TableCell style={{ textAlign: "left" }}>
                                Amount
                              </TableCell>
                              <TableCell style={{ textAlign: "left" }}>
                                Date & Time
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                Type
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                Status
                              </TableCell>
                            </TableHead>
                            <TableBody>
                              {transactionDetails?.map((row) => {
                                return (
                                  <TableRow>
                                    <TableCell style={{ textAlign: "left" }}>
                                      {row?.transactionRef}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row?.amount}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left" }}>
                                      {getDate(row?.created)}
                                    </TableCell>
                                    <TableCell align="right">
                                      {row?.type}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "right" }}>
                                      {" "}
                                      {row.isSuccessful ? (
                                        <Chip
                                          style={{
                                            background: "#A5E4B6",
                                            color: "#04562A",
                                            marginLeft: "auto",
                                          }}
                                          label={row?.status}
                                        />
                                      ) : (
                                        <Chip
                                          style={{
                                            background: "#F3C0C7",
                                            color: "#BB193B",
                                            marginLeft: "auto",
                                          }}
                                          label={row?.status}
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {isNotFounds && (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ py: 3 }}
                                    colSpan={8}
                                  >
                                    No Transactions found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                    </Card>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Page>
      </ThemeProvider>
    )
  );
}
