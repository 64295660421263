import { useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Container,
  Typography,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Campaigns from "../campaigns/campaigns";
import NotificationTable from "./NotificationTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function NotificationList() {
  const { themeStretch } = useSettings();
  const { activeTab } = useParams();
  const [value, setValue] = useState(activeTab === "notifications" ? 0 : 1);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate("/notifications/notifications");
    else if (newValue === 1) navigate("/notifications/campaigns");
  };
  return (
    <ThemeProvider theme={{}}>
      <Page title="Notifications">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="NOTIFICATION"
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
                <Tab
                  label="CAMPAIGNS"
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">
              <Button
                className="createBtn"
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.notification.newNotification}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                style={{ background: "#E04E2C" }}
              >
                New Notification
              </Button>
              <NotificationTable />
            </TabPanel>
            <TabPanel value={value} index={1} className="tabPanel">
              <Button
                className="createBtn"
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.campaigns.newCampaign}
                startIcon={<Iconify icon={"eva:plus-fill"} />}
                style={{ background: "#E04E2C" }}
              >
                New Campaign
              </Button>
              <Campaigns />
            </TabPanel>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}

