import { useState, useEffect } from "react";
import {useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import larrow from "../../images/arrow-left.svg";
import StarIcon from "@mui/icons-material/Star";
import {
  Card,
  Table,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  IconButton,
  MenuItem,
  Grid,
  Rating,
  Modal,
} from "@mui/material";
import moment from "moment";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import ninja_head from "../../images/ninja_head.png";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import verified_user from "../../images/SealCheck.svg";
import UserCircleGear from "../../images/UserCircleGear.png";
import Wallet from "../../images/Wallet.svg";
import Maptrifold from "../../images/MapTrifold (1).svg";
import Bank from "../../images/Bank.svg";
import pic from "../../images/avatar.svg";
import freelancer from "../../images/freelancer.svg";
import LoadingScreen from "src/components/LoadingScreen";
import {getDate} from '../../utils/methods';

const TABLE_HEAD = [
  { id: "name", label: "Order Number", alignRight: false },
  { id: "distance", label: "Distance", alignRight: false },
  { id: "date", label: "Date", alignRight: true },
  { id: "Amount", label: "Amount", alignRight: true },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------
export default function RiderDetails() {
  const location = useLocation();
  const riderId = location.state;
  const [from, setFrom] = useState({
    kycDocs: {
      driving_licence: {},
      profile: {},
      aadharcard: {},
      pancard: {},
    },
    totalKm: 0,
    totalTasks: 0,
    totalIncome: 0,
    walletBalance: 0,
  });
  const [loaded, setLoaded] = useState(false);
  const { themeStretch } = useSettings();

  const [bookingList, setBookingList] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [earningDetails, setEarningDetails] = useState([]);
  const [walletBalance, setWalletBalance] = useState();
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [open, setOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const handleClose = () => setOpen(false);
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };
  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = bookingList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = bookingList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setBookingList(deleteUsers);
  };
  useEffect(() => {
    setLoaded(false);
    axios
      .get(`/riders/get/detail/${riderId}`)
      .then((res) => {
        console.log("test...................", res.data.result);
        setFrom(res.data.result);
        setLoaded(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  useEffect(() => {
    getEarnings();
  }, [month, year]);
  const getEarnings = async () => {
    const payload = {};
    payload.month = month;
    payload.year = year;
    try {
      const res = await axios.post(`/earnings/get/${riderId}`, payload);
      setEarningDetails(res.data.result.earnings);
      setWalletBalance(res.data.result.walletBalance);
    } catch (e) {
      console.log(e);
    }
  };

  const Convert = (dt) => {
    const ddate = moment(dt).format(`D-MMMM-YYYY`);
    return ddate;
  };

  const starvalue = "4.00";
  let distanceSum = 0;
  let earningSum = 0;
  return (
    <ThemeProvider theme={{}}>
      {!loaded && <LoadingScreen />}
      <Page>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px",marginBottom:'30px' }}
        >
          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }} style={{display: "flex",borderBottom: "1px solid #E04E2C",}}>  
               <img
               alt="Back arrow"
                src={larrow}
                onClick={handleOnClick}
                height={20}
                style={{
                  marginRight: "10px",
                  marginTop: "7px",
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
              /> 
             <Typography
                style={{
                  color: "#030303",
                  fontSize: "24px",
                  fontWeight: "300",
                  marginBottom: "10px",
                }}
              >
                RIDER DETAILS
              </Typography>
            </Box>
            <Grid
              container
              gap={2}
              style={{
                background: "#EBEBEB",
                width: "auto",
                marginLeft: "0",
                marginTop: "15px",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                style={{ padding: "0 0 0px 0px" }}
              >
                <Box
                  style={{
                    padding: "32px 50px",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    background: "white",
                  }}
                >
                  <Box
                    sx={{
                      height: "211px",
                      width: "211px",
                      borderRadius: "50%",
                      background: "#F9F9F9",
                      margin: "0 auto",
                      filter: "drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.2))",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {from?.photo_url !== null ? (
                      <img
                        src={from?.photo_url}
                        alt=""
                        style={{
                          height: "211px",
                          width: "211px",
                          borderRadius: "50%",
                          boxShadow: "0px 10px 30px 0px #00000033",
                        }}
                      />
                    ) : (
                      <img
                        src={pic}
                        alt=""
                        style={{
                          height: "211px",
                          width: "211px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20PX",
                    }}
                  >
                    {from?.isVerified === true ? (
                      <img
                        src={verified_user}
                        alt=""
                        style={{ height: "32px", width: "32px" }}
                      />
                    ) : (
                      <></>
                    )}
                    {from?.type === "permanent" ? (
                      <img
                        src={UserCircleGear}
                        alt=""
                        style={{ height: "36px", width: "36px" }}
                      />
                    ) : from?.type === "freelancer" ? (
                      <img
                        src={freelancer}
                        alt=""
                        style={{ height: "36px", width: "36px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "700",
                      fontFamily: "'Cabin', sans-serif",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {from?.display_name}
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: "5px",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontFamily: "'Cabin', sans-serif",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {from?.code}
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <Rating
                      name="text-feedback"
                      value={starvalue}
                      readOnly
                      precision={0.5}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        fontFamily: "'Cabin', sans-serif",
                        color: "#002236",
                      }}
                    >
                      ({starvalue})
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      marginBottom: "10px",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "'Cabin', sans-serif",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Joined : {getDate(from?.created_time)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "#E3552E",
                      marginTop: "30px",
                      fontFamily: "'Cabin', sans-serif",
                      borderBottom: "1px solid #E8ECEE",
                    }}
                  >
                    KYC Documents
                  </Typography>
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    {from?.kycDocs?.aadharcard ? (
                      <Button
                        target="_blank"
                        href={from?.kycDocs?.aadharcard?.url}
                      >
                        Aadhar
                      </Button>
                    ) : (
                      <Button disabled>Aadhar</Button>
                    )}

                    {from?.kycDocs?.pancard ? (
                      <Button
                        target="_blank"
                        href={from?.kycDocs?.pancard?.url}
                      >
                        PAN Card
                      </Button>
                    ) : (
                      <Button disabled>PAN Card</Button>
                    )}
                    {from.kycDocs.driving_licence ? (
                      <Button
                        target="_blank"
                        href={from?.kycDocs?.driving_licence?.url}
                      >
                        Driving Licence
                      </Button>
                    ) : (
                      <Button disabled>Driving Licence</Button>
                    )}
                  </Box>
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    {from?.kycDocs?.aadharcard &&
                    from?.kycDocs?.aadharcard?.isVerified === false ? (
                      <div align="right">
                        {" "}
                        <Button
                          align="right"
                          variant="contained"
                          onClick={() => {
                            setOpen(true);
                            setDeletedId(from?.kycDocs?.aadharcard?.id);
                          }}
                        >
                          Verify Adhaar
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {from?.kycDocs?.pancard &&
                    from?.kycDocs?.pancard?.isVerified === false ? (
                      <div align="right">
                        {" "}
                        <Button
                          align="right"
                          variant="contained"
                          onClick={() => {
                            setOpen(true);
                            setDeletedId(from?.kycDocs?.pancard?.id);
                          }}
                        >
                          Verify PAN
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {from?.kycDocs?.driving_licence &&
                    from?.kycDocs?.driving_licence?.isVerified === false ? (
                      <div align="right">
                        {" "}
                        <Button
                          align="right"
                          variant="contained"
                          onClick={() => {
                            setOpen(true);
                            setDeletedId(from?.kycDocs?.driving_licence?.id);
                          }}
                        >
                          Verify DL
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {from?.kycDocs?.profile &&
                    from?.kycDocs?.profile?.isVerified === false ? (
                      <div align="right">
                        {" "}
                        <Button
                          align="right"
                          variant="contained"
                          onClick={() => {
                            setOpen(true);
                            setDeletedId(from?.kycDocs?.profile?.id);
                          }}
                        >
                          Verify Profile
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    <ConfirmModal
                      open={open}
                      handleClose={handleClose}
                      vid={deletedId}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs
                style={{
                  padding: "0 0 0px",
                  minHeight: "240px",
                  maxHeight: "840px",
                }}
              >
                <Box
                  style={{
                    padding: "32px 50px",
                    background: "white",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: "30%",
                        background: "#1D313F",
                        borderRadius: "20px",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          fontFamily: "'Cabin', sans-serif",
                          color: "white",
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        Kms
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "40px",
                          fontWeight: "700",
                          fontFamily: "'Cabin', sans-serif",
                          color: "#E3552E",
                          textAlign: "center",
                        }}
                      >
                        {from?.totalKm}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "120px",
                        width: "30%",
                        background: "#1D313F",
                        borderRadius: "20px",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          fontFamily: "'Cabin', sans-serif",
                          color: "white",
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        Tasks
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "40px",
                          fontWeight: "700",
                          fontFamily: "'Cabin', sans-serif",
                          color: "#E3552E",
                          textAlign: "center",
                        }}
                      >
                        {from?.totalTasks}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "120px",
                        width: "30%",
                        background: "#1D313F",
                        borderRadius: "20px",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          fontFamily: "'Cabin', sans-serif",
                          color: "white",
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        Income <span>&#8377;</span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "40px",
                          fontWeight: "700",
                          fontFamily: "'Cabin', sans-serif",
                          color: "#E3552E",
                          textAlign: "center",
                        }}
                      >
                        {from?.totalIncome}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        height: "88px",
                        position: "relative",
                        width: "40%",
                        background: "#F9F9F9",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      {from?.type === "permanent" ? (
                        <img
                          src={UserCircleGear}
                          alt=""
                          style={{
                            height: "38px",
                            width: "38px",
                            marginLeft: "10px",
                          }}
                        />
                      ) : from?.type === "freelancer" ? (
                        <img
                          src={freelancer}
                          alt=""
                          style={{
                            height: "38px",
                            width: "38px",
                            marginLeft: "10px",
                          }}
                        />
                      ) : (
                        <>&nbsp;</>
                      )}
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          Employee Type
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                            textTransform: "capitalize",
                          }}
                        >
                          {from?.type}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: "88px",
                        position: "relative",
                        width: "25%",
                        background: "#F9F9F9",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <img
                        src={Wallet}
                        alt=""
                        style={{
                          height: "38px",
                          width: "38px",
                          marginLeft: "10px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          Wallet Balance
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          <span>&#8377;</span>{" "}
                          {walletBalance ? walletBalance : 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: "88px",
                        position: "relative",
                        width: "25%",
                        background: "#E3552E",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        PAY NOW
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "6px 0",
                        position: "relative",
                        width: "47.5%",
                        background: "#F9F9F9",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <img
                        src={Maptrifold}
                        alt=""
                        style={{
                          height: "38px",
                          width: "38px",
                          marginLeft: "10px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          Current Address
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          {from?.currentAddress}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: "6px 0",
                        position: "relative",
                        width: "47.5%",
                        background: "#F9F9F9",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <img
                        src={Maptrifold}
                        alt=""
                        style={{
                          height: "38px",
                          width: "38px",
                          marginLeft: "10px",
                        }}
                      />
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "400",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          Permanent Address
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#002236",
                            fontFamily: "'Cabin', sans-serif",
                          }}
                        >
                          {from?.permanentAddress}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "#E3552E",
                      marginTop: "30px",
                      fontFamily: "'Cabin', sans-serif",
                      borderBottom: "1px solid #E8ECEE",
                    }}
                  >
                    Bank Details
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      marginTop: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Bank}
                      alt=""
                      style={{ height: "38px", width: "38px" }}
                    />
                    {from?.bankDetails === null ? (
                      <Typography
                        sx={{
                          fontSize: "20px",
                          paddingBottom: "5px",
                          fontWeight: "400",
                          color: "#002236",
                        }}
                      >
                        {from.bankDetails}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: "'Cabin', sans-serif",
                          fontWeight: "400",
                          color: "#002236",
                        }}
                      >
                        {from?.bankDetails?.name},
                        {from?.bankDetails?.accountNumber},
                        {from?.bankDetails?.ifscCode}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ paddingBottom: "0" }}>
              <Typography
                style={{
                  color: "#030303",
                  fontSize: "24px",
                  fontWeight: "300",
                  borderBottom: "1px solid #E04E2C",
                  marginBottom: "30px",
                  marginTop: "20px",
                }}
              >
                RIDER EARNINGS
              </Typography>
            </Box>
            <Card>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                onDeleteUsers={() => handleDeleteMultiUser(selected)}
                setMonth={setMonth}
                setYear={setYear}
                month={month}
                year={year}
              />

              <TableContainer sx={{ minWidth: 800, height: "500px" }}>
                <Scrollbar>
                  <Table style={{ marginTop: "10px" }}>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      //   rowCount={userList.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      dateYearpicker={true}
                    />
                    <TableBody>
                      {earningDetails?.map((row, index) => {
                        row.bookingTasks?.forEach((task) => {
                          distanceSum += parseFloat(task.distance);
                          earningSum +=
                            parseFloat(task.earning.basicPay) +
                            parseFloat(task.earning.specialPay) +
                            parseFloat(task.earning.distancePay);
                        });

                        return (
                          <TableRow
                            hover
                            key={index}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                alt="icon"
                                src={ninja_head}
                                sx={{ mr: 2, fontSize: "16px" }}
                              />
                              <Typography variant="subtitle2" noWrap>
                                {row.bookingNumber}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {distanceSum?.toFixed(1)} Km{" "}
                            </TableCell>
                            <TableCell align="right">
                              {Convert(row.created)}
                            </TableCell>
                            <TableCell align="right">₹ {earningSum}</TableCell>
                          </TableRow>
                        );
                      })}
                      {earningDetails?.length === 0 && (
                        <TableRow>
                          <TableCell align="center" sx={{ py: 3 }} colSpan={10}>
                            No data found.
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={earningDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, deleteBooking }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={() => deleteBooking(row?.id)}>
          <Iconify icon={"eva:trash-2-outline"} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export function ConfirmModal({ open, handleClose, vid }) {
  const verifyRider = (id) => {
    axios
      .put(`/kyc/update/${id}`, { isVerified: true })
      .then((res) => {
        console.log(res);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to verify?
          </Typography>
          <Box sx={{ display: "flex", gap: "40px" }}>
            {" "}
            <Button align="left" onClick={handleClose}>
              No
            </Button>{" "}
            <Button
              align="right"
              variant="contained"
              onClick={() => verifyRider(vid)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
