import { useEffect, useState } from "react";
import larrow from "../../images/arrow-left.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { MotivationIllustration } from "../../assets";
import Switch from "@mui/material/Switch";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Container,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
} from "../../components/hook-form";
import useSettings from "../../hooks/useSettings";
import { useSnackbar } from "notistack";

export default function NewManager() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { id } = useParams();
  const [cityList, setCityList] = useState([]);
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });

  useEffect(() => {
    if (cityList) {
      getCity();
    }
  }, []);

  const getCity = () => {
    axios
      .post("cities/get/all")
      .then((res) => {
        console.log(res.data);

        setCityList(res.data.result);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getManagers = () => {
    setLoading(true);
    axios
      .get(`managers/get/${id}`)
      .then((res) => {
        console.log("testing301", res.data.result);
        const currentManager = res?.data?.result;
        if (currentManager) {
          setName(currentManager.name);
          setMobile(currentManager.mobile);
          setEmail(currentManager.email);
          setCity(currentManager?.cityRef._path.segments[1]);
          setIsActive(currentManager?.isActive);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    if (id) {
      getManagers();
    }
  }, []);

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleOnClick = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    // enqueueSnackbar("This is Under development.", {
    //   variant: "error",
    //   style: { marginTop: "100px" },
    // });
    setLoading(true);
    if (!isEdit) {
      const payload = {
        cityId: city,
        uid: "testing",
        name: name,
        email: email,
        mobile: mobile,
        isActive: isActive,
      };

      axios
        .post("/managers/add", payload)
        .then((res) => {
          console.log(res);
          navigate(PATH_DASHBOARD.managers.managers);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const payload = {
        cityId: city,
        name: name,
        email: email,
        isActive: isActive,
        mobile: mobile,
      };
      axios
        .put(`/managers/update/${id}`, payload)
        .then((res) => {
          console.log(res);
          navigate(PATH_DASHBOARD.managers.managers);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleSwitchChange = (event) => {
    setIsActive(event.target.checked);
  };
  return (
    <>
      <ThemeProvider theme={{}}>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0" }}
                style={{ display: "flex", borderBottom: "1px solid #E04E2C" }}
              >
                <img
                  alt="arrow icon"
                  src={larrow}
                  onClick={handleOnClick}
                  height={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "7px",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                />
                {!isEdit ? (
                  <Typography
                    sx={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                    }}
                  >
                    ADD NEW MANAGER
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                    }}
                  >
                    EDIT MANAGER DETAILS
                  </Typography>
                )}
              </Box>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
              >
                <HeaderBreadcrumbs
                  heading={""}
                  links={[
                    {
                      name: "Managers",
                      href: PATH_DASHBOARD.managers.managers,
                    },
                    {
                      name: "Add new",
                      href: PATH_DASHBOARD.managers.root,
                    },
                  ]}
                />
              </Box>

              <Grid
                container
                className="px-0"
                sx={{ paddingLeft: "34px", paddingRight: "34px" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <Box
                    sx={{
                      width: "1169px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "white",
                        padding: "40px",
                        display: "flex",
                      }}
                    >
                      <Box style={{ marginRight: "30px" }}>
                        <Box
                          sx={{
                            display: "grid",
                            columnGap: 4,
                            rowGap: 3,
                            gridTemplateColumns: {
                              xs: "repeat(1, 1fr)",
                              sm: "repeat(2, 1fr)",
                            },
                          }}
                        >
                          <Box sx={{}}>
                            <Typography className="para_label">Name</Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </Box>
                          <Box sx={{}}>
                            <Typography className="para_label">
                              Mobile
                            </Typography>
                            <TextField
                              sx={{ width: "100%" }}
                              name="mobile"
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                              required
                          
                            />
                          </Box>
                          <Box sx={{}}>
                            <Typography className="para_label">
                              Email
                            </Typography>
                            <RHFTextField
                              sx={{ width: "100%" }}
                              name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                       
                            />
                          </Box>
                          <Box sx={{}}>
                            <Typography className="para_label">City</Typography>
                            <RHFSelect
                              sx={{ width: "100%" }}
                              name="country"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                              required                          
                            >
                              <option value="" />
                              {cityList.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.city}
                                </option>
                              ))}
                            </RHFSelect>
                          </Box>

                          <Box>
                            <Typography className="para_label" variant="h5">
                              Active
                            </Typography>
                            <Switch
                              defaultChecked
                              color="warning"
                              checked={isActive}
                              onChange={handleSwitchChange}
                            />
                          </Box>
                        </Box>
                        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                          <LoadingButton
                            sx={{ padding: "15px 0", borderRadius: "5px" }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            size="large"
                            loading={isSubmitting}
                          >
                            {!isEdit ? "ADD" : "Save Changes"}
                          </LoadingButton>
                        </Stack>
                      </Box>
                      <Box
                        sx={{
                          width: "534px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <MotivationIllustration sx={{ height: 400 }} />
                        <Typography sx={{ width: 400, textAlign: "center" }}>
                          Create a new manager and assign a new city to them.
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
