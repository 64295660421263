import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { getDate } from "../../utils/methods";
import { Box, Typography, Grid, CircularProgress } from "@mui/material";
import axios from "../../utils/axiosconfig";
import Conformation from "../../components/Conformation";

const PackageTypes = () => {
  const [packageTypes, setPackageTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const cityId = localStorage.getItem("manager_city");
  const [updateObj, setUpdateObj] = useState({ id: null, state: false });
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    getPackageTypes();
  }, [update]);
  const getPackageTypes = () => {
    setLoading(true);
    axios
      .get(`/packageTypes/get/byCity/${cityId}`)
      .then((res) => {
        console.log("testing88......", res.data.result);
        setPackageTypes(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("error", err);
      });
  };
  const handleSelectRemovePackage = () => {
    setOpen(false);
    console.log("testing01.........", updateObj);
    setLoading(true);
    const payload = updateObj;
    payload.cityId = cityId;
    payload.state = !updateObj.state;
    axios
      .post(`/packageTypes/select/package`, payload)
      .then((res) => {
        setUpdate((preState) => !preState);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  return (
    <>
      <Box sx={{ padding: "0", marginBottom: "25px" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            marginTop: 200,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <Box sx={{ borderBottom: "1px solid #E04E2C" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#030303",
                fontSize: "24px",
                fontWeight: "300",
                marginRight: "15px",
              }}
            >
              Select Packages
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "30px",
                  width: "40px",
                  border: "1px solid black",
                  background: "#D3D3D3",
                  marginRight: "10px",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#030303",
                  fontSize: "18px",
                  fontWeight: "300",
                  marginRight: "10px",
                }}
              >
                Selected
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "30px",
                  width: "40px",
                  border: "1px solid black",
                  background: "white",
                  marginRight: "10px",
                }}
              ></Box>
              <Typography
                sx={{
                  color: "#030303",
                  fontSize: "18px",
                  fontWeight: "300",
                  marginRight: "10px",
                }}
              >
                Available
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container flexWrap="wrap" gap={1.8} justifyContent="flex-start">
        {packageTypes?.map((packageType, index) => (
          <PackageCard
            key={index}
            packageType={packageType}
            setOpen={setOpen}
            setUpdateObj={setUpdateObj}
          />
        ))}
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={handleSelectRemovePackage}
          title={`${
            updateObj.state ? "Remove Package" : "Select New Package"
          } Confirmation`}
          subTitle={`Do you want to ${
            updateObj.state ? "Remove" : "Select"
          } the Package?`}
        />
      </Grid>
    </>
  );
};

export default PackageTypes;
const PackageCard = ({ packageType, setOpen, setUpdateObj }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
    setUpdateObj({ id: packageType?.id, state: packageType?.isSelected });
    setOpen(true);
    console.log("Card clicked........");
  };
  return (
    <Box
      sx={{
        boxShadow:
          "0 0 6px 1px rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)",
        backgroundColor: packageType?.isSelected ? "#D3D3D3" : "white",
        borderRadius: "20px",
        padding: "24px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        gap: 3,
        ".borderCard": {
          border: "1px solid #E04E2C",
        },
      }}
      className={isClicked ? "borderCard" : null}
      onClick={handleClick}
    >
      <Box>
        <img src={packageType?.icon} alt="medicine" height={100} width={100} />
      </Box>
      <Box>
        <Typography
          style={{
            color: "#212B36",
            fontSize: "1.125rem",
            fontWeight: "700",
          }}
        >
          {packageType?.type}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {packageType?.isActive ? (
            <CheckCircleIcon
              sx={{ color: "green", height: "30px", width: "30px" }}
            />
          ) : (
            <UnpublishedIcon
              sx={{ color: "red", height: "30px", width: "30px" }}
            />
          )}
          &nbsp;
          {packageType?.isActive ? (
            <Typography
              style={{
                color: "#212B36",
                fontSize: "1rem",
                fontWeight: "700",
              }}
            >
              Active
            </Typography>
          ) : (
            <Typography
              style={{
                color: "#212B36",
                fontSize: "1rem",
                fontWeight: "700",
              }}
            >
              Inactive
            </Typography>
          )}
        </Box>
        <Typography
          style={{
            color: packageType?.isSelected ? "black" : "#637381",
            fontSize: "0.875rem",
          }}
        >
          Created at : {getDate(packageType?.created)}
        </Typography>
        <Typography
          style={{
            color: packageType?.isSelected ? "black" : "#637381",
            fontSize: "0.875rem",
          }}
        >
          Modified at : {getDate(packageType?.modified)}
        </Typography>
      </Box>
    </Box>
  );
};
