import { useState, useEffect} from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "src/components/LoadingScreen";
import { fetchBusinessBookings,reset } from "src/redux/slices/GetBusinessBookings";
import { getDateTime } from "src/utils/methods";

const TABLE_HEAD = [
  { id: "name", label: "Booking Number", alignRight: false },
  { id: "company", label: "Customer", alignRight: false },
  { id: "role", label: "ScheduleTime", alignRight: true },
  { id: "distance", label: "Distance", alignRight: true },
  { id: "amount", label: "Amount", alignRight: true },
  { id: "packageType", label: "Package Type", alignRight: false },
  { id: "fragile", label: "Fragile", alignRight: true },
  { id: "status", label: "Status", alignRight: true },
];

export default function BookingList({businessId}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getBusinessBookings);
  const [bookingList, setBookingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = bookingList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = bookingList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setBookingList(deleteUsers);
  };

  const isNotFound = bookingList?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);

  useEffect(() => {
    if(businessId)
    getBookings();
  }, [state.data]);

  useEffect(() => {
    if (filterValue?.length > 0 && businessId) {
      const timeoutId = setTimeout(() => {
        console.log("useEffect...........search", searchValue);
        
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  
  useEffect(() => {
    if(businessId)
    filterSearch();
  }, [rowsPerPage, page]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.businessId=businessId;
      payload.cityId = localStorage.getItem("manager_city");
      payload.type = "";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("all bookings payload........", payload.search);
      await axios
        .post("/bookings/get/all/" + businessId, payload)
        .then((res) => {
          console.log("from search..........", payload.search);
          setLoading(false);
          setBookingList(res?.data?.result || []);
          setTotalItems(res?.data?.pagination?.totalItems || 0);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getBookings = () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.businessId=businessId;
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchBusinessBookings(payload));
      } else {
        setBookingList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 200,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Bookings">
        <Box sx={{ width: "100%" }}>
          <Card sx={{ borderRadius: "0" }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDeleteUsers={() => handleDeleteMultiUser(selected)}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}
              isMonthRequired={true}
            />

            <TableContainer sx={{ minWidth: 800, height: "calc(60vh)" }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={bookingList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {bookingList?.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          component={RouterLink}
                          to={PATH_DASHBOARD.bookings.bookingsDetails}
                          state={{
                            index: index,
                            data: row,
                            tableData: bookingList,
                          }}
                          sx={{ textDecoration: "none" }}
                        >
                          <TableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                            }}
                          >
                           
                            <Typography variant="subtitle2" noWrap>
                              {row?.bookingNumber}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {row?.userObj?.display_name?.replace(
                              /(\w)(\w*)/g,
                              (_, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
                            ) || ""}
                          </TableCell>
                          <TableCell align="right">
                            {getDateTime(row?.scheduleDateTime)}
                          </TableCell>
                          <TableCell align="right">
                            {row?.distance} km
                          </TableCell>
                          <TableCell align="right">₹{row?.amount}</TableCell>
                          <TableCell>{row?.packageTypeObj?.type}</TableCell>

                          <TableCell align="right">
                            <Label
                              color={
                                row?.isFragile === false ? "error" : "success"
                              }
                            >
                              {row?.isFragile ? "Yes" : "No"}
                            </Label>
                          </TableCell>
                          <TableCell align="right">{row?.stageObj?.stage}</TableCell>
                        </TableRow>
                      );
                    })}
                    {isNotFound && (
                      <TableRow>
                        <TableCell align="center" sx={{ py: 3 }} colSpan={15}>
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </Page>
    </ThemeProvider>
  );
}
