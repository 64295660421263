import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  Radio,
  Button,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BusinessLocationApp from "./businessLocation";

const DestinationSelectorModal = ({
  open,
  handleClose,
  destinations,
  onAddDestination,
  onSave,
  selectedDestinations,
  setSelectedDestinations
}) => {
  // const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleToggle = (value) => () => {
    const currentIndex = selectedDestinations.indexOf(value);
    const newSelectedDestinations = [...selectedDestinations];

    if (currentIndex === -1) {
      newSelectedDestinations.push(value);
    } else {
      newSelectedDestinations.splice(currentIndex, 1);
    }

    setSelectedDestinations(newSelectedDestinations);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSave = () => {
    onSave(selectedDestinations);
    handleClose();
  };

  const filteredDestinations = destinations.filter((destination) =>
    destination.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 5,
          backgroundColor: "white",
          borderRadius: 2,
          width: 800,
          mx: "auto",
          mt: "10%",
          
        }}
      >
        <Typography variant="h6" gutterBottom>
          Select Destinations
        </Typography>
        {/* <TextField
          fullWidth
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
        /> */}
        <Grid container spacing={2} sx={{ maxHeight: "300px", overflow: "auto" }}>
          {filteredDestinations.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="40px" // Large padding
              width="100%" // Ensure it takes up the available height
              height="100%" // Ensure it takes up the available height
              textAlign="center" // Center the text
            >
              No Destination found for the user
            </Box>
          )}
          {filteredDestinations.map((destination) => (
            <Grid item xs={12} key={destination.id}>
              <Card
                onClick={handleToggle(destination)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "4px",
                  cursor: "pointer",
                  backgroundColor:
                    selectedDestinations.indexOf(destination) !== -1
                      ? "#e3783e24"
                      : "white",
                  border: "2px",
                  borderStyle: "solid",
                  borderColor: "#e2e2e2",
                  borderRadius: "4px",
                }}
              >
                <CardContent sx={{ padding: "8px" }}>
                  <Typography variant="body1">{destination.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {destination.address}
                  </Typography>
                </CardContent>
                <Radio
                  checked={selectedDestinations.indexOf(destination) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <IconButton color="primary" onClick={onAddDestination}>
            <AddIcon />
          </IconButton>
          <Button variant="contained" onClick={handleSave}>
            Done
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DestinationSelectorModal;
