import { Link as RouterLink } from "react-router-dom";
import { Box, Stack, Chip, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Page from "../../components/Page";
import { LoginForm } from "../../sections/auth/login";
import useSettings from "../../hooks/useSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  SeoIllustration,
  OrderCompleteIllustration,
  MotivationIllustration,
  MaintenanceIllustration,
} from "../../assets";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { themeStretch } = useSettings();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const InfoCard1 = ({ title, subTitle }) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <OrderCompleteIllustration sx={{ height: 400, width: 600 }} />
        <Typography variant="h3" sx={{ pt:5,px: 5, textAlign: "center" }}>
          Your one-stop work<div>platform</div>
        </Typography>
        <Typography
          sx={{ textAlign: "center", color: "#696F78", fontSize: 18 }}
        >
          Manage Customers, Bookings and<div>Riders, all in one place.</div>
        </Typography>
      </Box>
    );
  };
  const InfoCard2 = ({ title, subTitle }) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <SeoIllustration sx={{ height: 400, width: 600 }} />
        <Typography variant="h3" sx={{pt:5, px: 5, textAlign: "center" }}>
          New Version 2.0.1 is <div>available</div>
        </Typography>
        <Typography
          sx={{ textAlign: "center", color: "#696F78", fontSize: 18 }}
        >
          More new features are<div>available in new version.</div>
        </Typography>
      </Box>
    );
  };

  const InfoCard3 = ({ title, subTitle }) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <MaintenanceIllustration sx={{ height: 400, width: 600 }} />
        <Typography variant="h3" sx={{pt:5, px: 5, textAlign: "center" }}>
          New settings are <div>available</div>
        </Typography>
        <Typography
          sx={{ textAlign: "center", color: "#696F78", fontSize: 18 }}
        >
          You can change your Panel with <div>multiple settings.</div>
        </Typography>
      </Box>
    );
  };
  const InfoCard4 = ({ title, subTitle }) => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <MotivationIllustration sx={{ height: 400, width: 600 }} />
        <Typography variant="h3" sx={{pt:5, px: 5, textAlign: "center" }}>
        Elevate Your Potential
        </Typography>
        <Typography
          sx={{ textAlign: "center", color: "#696F78", fontSize: 18 }}
        >
           Embrace Growth with Exciting<div> New Features.</div>
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Page title="Login">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            display: "flex",
            justifyContent: "center",
          }}
          sx={{
            background: "#EDF0F7",
            height: "100vh",
          }}
        >
          <HeaderStyle>
            <Chip label="V-2.0.0" size="medium" color="primary" />
          </HeaderStyle>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 20,
            }}
          >
            <Slider
              style={{
                width: "600px",
                height: "600px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              {...settings}
            >
              <InfoCard1 />
              <InfoCard2 />
              <InfoCard3 />
              <InfoCard4 />
            </Slider>
            <Box>
              <Box
                sx={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: 5,
                  background: "#FFFFFF",
                  borderRadius: "18px",
                }}
              >
                <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                  <Box sx={{}}>
                    <Typography variant="h4" gutterBottom>
                      Welcome to Pickup Ninja
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                      Enter your details below.
                    </Typography>
                  </Box>
                </Stack>
                <LoginForm />
              </Box>
            </Box>
          </Box>
        </Container>
      </Page>
    </>
  );
}
