import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Drawer, Box } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../utils/config";
// components
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import { useSelector } from "react-redux";
//
import { adminNavConfig, managerNavConfig } from "./NavConfig";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const getLoginUser = useSelector((state) => state.getLoginUser.data);
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "xs");
  const managerId = localStorage.getItem("manager_id");
  console.log("testing33..........", getLoginUser);
  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#1D313F",
      },
    },
  });

  const renderContent = (
    <Scrollbar>
      <NavSectionVertical
        navConfig={
          getLoginUser?.role === "ADMIN_ROLE" && managerId === null
            ? adminNavConfig
            : managerNavConfig
        }
        isCollapse={isCollapse}
        className="sideBar-menus"
      />
    </Scrollbar>
  );

  return (
    <ThemeProvider theme={theme}>
      <RootStyle className="sideBar">
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
          >
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Box className="sideBar-Drawer">
            {renderContent}
          </Box>
        )}
      </RootStyle>
    </ThemeProvider>
  );
}
