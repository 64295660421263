
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/dashboard"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/activity"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/add"),
    conversation: path(ROOTS_DASHBOARD, "/chat/:conversationKey"),
  },
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    edit: path(ROOTS_DASHBOARD, "/user/edit/:id"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    editById: path(ROOTS_DASHBOARD, `/user/edit`),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  reason: {
    root: path(ROOTS_DASHBOARD, "/reasons"),
    reasons: path(ROOTS_DASHBOARD, "/reasons"),
    newReason: path(ROOTS_DASHBOARD, "/reasons/add"),
  },
  notification: {
    root: path(ROOTS_DASHBOARD, "/notifications/notifications"),
    notifications: path(ROOTS_DASHBOARD, "/notifications/notifications"),
    newNotification: path(ROOTS_DASHBOARD, "/notifications/notifications/add"),
    editById: path(ROOTS_DASHBOARD, "/notifications/notifications/edit"),
  },
  defaultNotification: {
    root: path(ROOTS_DASHBOARD, "/default-notifications"),
    notifications: path(ROOTS_DASHBOARD, "/default-notifications"),
    newNotification: path(ROOTS_DASHBOARD, "/default-notifications/add"),
    editById: path(ROOTS_DASHBOARD, "/default-notifications/edit"),
  },

  rate: {
    root: path(ROOTS_DASHBOARD, "/settings"),
    rates: path(ROOTS_DASHBOARD, "/settings"),
    newRate: path(ROOTS_DASHBOARD, "/settings/add"),
    editById: path(ROOTS_DASHBOARD, "/settings/edit"),
  },
  coupon: {
    root: path(ROOTS_DASHBOARD, "/coupons"),
    coupons: path(ROOTS_DASHBOARD, "/coupons"),
    newCoupon: path(ROOTS_DASHBOARD, "/coupons/add"),
    editById: path(ROOTS_DASHBOARD, "/coupons/edit"),
  },
  group: {
    root: path(ROOTS_DASHBOARD, "/groups"),
    groups: path(ROOTS_DASHBOARD, "/groups"),
    newGroup: path(ROOTS_DASHBOARD, "/groups/add"),
    editById: path(ROOTS_DASHBOARD, "/groups/edit"),
  },
  allusers: {
    root: path(ROOTS_DASHBOARD, "/users/users"),
    userList: path(ROOTS_DASHBOARD, "/users/users"),
  },
  business: {
    businessList: path(ROOTS_DASHBOARD, "/business"),
    businessDetails: path(ROOTS_DASHBOARD, "/business/details"),
    BusinessForm: path(ROOTS_DASHBOARD, "/business/form"),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, "/transactions"),
    transactionList: path(ROOTS_DASHBOARD, "/transactions"),
  },

  cities: {
    root: path(ROOTS_DASHBOARD, "/cities"),
    cities: path(ROOTS_DASHBOARD, "/cities"),
    newCity: path(ROOTS_DASHBOARD, "/cities/add"),
    editById: path(ROOTS_DASHBOARD, "/cities/edit"),
  },
  managers: {
    root: path(ROOTS_DASHBOARD, "/managers"),
    managers: path(ROOTS_DASHBOARD, "/managers"),
    newManager: path(ROOTS_DASHBOARD, "/managers/add"),
    editById: path(ROOTS_DASHBOARD, "/managers/edit"),
  },
  packages: {
    root: path(ROOTS_DASHBOARD, "/packages"),
    packages: path(ROOTS_DASHBOARD, "/packages"),
    newPackage: path(ROOTS_DASHBOARD, "/packages/add"),
    editById: path(ROOTS_DASHBOARD, "/packages/edit"),
  },

  bookings: {
    root: path(ROOTS_DASHBOARD, "/bookings"),
    bookingsList: path(ROOTS_DASHBOARD, "/bookings"),
    bookingsDetails: path(ROOTS_DASHBOARD, "/bookings/details"),
  },
  campaigns: {
    root: path(ROOTS_DASHBOARD, "/campaigns"),
    campaignList: path(ROOTS_DASHBOARD, "/campaigns"),
    newCampaign: path(ROOTS_DASHBOARD, "/campaigns/add"),
    editById: path(ROOTS_DASHBOARD, "/campaigns/edit"),
  },
  riders: {
    root: path(ROOTS_DASHBOARD, "/riders"),
    riderDetails: path(ROOTS_DASHBOARD, "/riders/details"),
    newRider: path(ROOTS_DASHBOARD, "/riders/add"),
    editById: path(ROOTS_DASHBOARD, "/riders/edit"),
  },
  users: {
    root: path(ROOTS_DASHBOARD, "/users"),
    userDetails: path(ROOTS_DASHBOARD, "/users/details"),
  },
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
