import { format } from "date-fns";
export const getDate = (dateObj) => {
  if (!dateObj) return "";
  let timestamp = null;
  if (dateObj?.seconds) {
    timestamp = new Date(
      dateObj.seconds * 1000 + dateObj.nanoseconds / 1000000
    );
  } else {
    timestamp = new Date(
      dateObj._seconds * 1000 + dateObj._nanoseconds / 1000000
    );
  }
  const formattedDate = format(timestamp, "d MMMM, yyyy");
  return formattedDate;
};
export const getDateTime = (dateObj) => {
  if (!dateObj) return "";
  let timestamp = null;
  if (dateObj?.seconds) {
    timestamp = new Date(
      dateObj.seconds * 1000 + dateObj.nanoseconds / 1000000
    );
  } else {
    timestamp = new Date(
      dateObj._seconds * 1000 + dateObj._nanoseconds / 1000000
    );
  }
  const formattedDateTime = format(timestamp, "d MMMM, yyyy hh:mm a");
  return formattedDateTime;
};

export const Convert = (dt) => {
  if (!dt) return "";
  let timestampInMilliseconds = null;
  if (dt.seconds)
    timestampInMilliseconds = dt.seconds * 1000 + dt.nanoseconds / 1e6;
  else timestampInMilliseconds = dt._seconds * 1000 + dt._nanoseconds / 1e6;

  let m = new Date(timestampInMilliseconds).getHours();
  let n = m >= 12 ? "PM" : "AM";
  const mins = new Date(timestampInMilliseconds).getMinutes();
  const zero = mins < 10 ? "0" : "";
  const h = m > 12 ? m - 12 : m;
  const formattedTime = `${h}:${zero}${mins} ${n}`;
  return formattedTime;
};

export const ConvertDt = (dt) => {
  if (!dt) return "";
  let timestampInMilliseconds = null;
  if (dt.seconds)
    timestampInMilliseconds = dt.seconds * 1000 + dt.nanoseconds / 1e6;
  else timestampInMilliseconds = dt._seconds * 1000 + dt._nanoseconds / 1e6;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = new Date(timestampInMilliseconds).getDate();
  const monthIndex = new Date(timestampInMilliseconds).getMonth();
  const month = months[monthIndex];

  let suffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  }

  const formattedDate = day + suffix + " " + month;
  return formattedDate;
};
