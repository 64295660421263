import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchUsers = createAsyncThunk("getUsers", async (payload) => {
  const response = await axios.post("/users/get/all",payload);
  return response;
});
const getUsers = createSlice({
  name: "getUsers",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      console.log("Error............", action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { reset } = getUsers.actions;
export default getUsers.reducer;
