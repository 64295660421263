import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import larrow from "../../images/arrow-left.svg";

import {
  Container,
  Typography,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  Grid,
} from "@mui/material";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import MenuPopover from "src/components/MenuPopover";
import verified_user from "../../images/SealCheck.png";
import UserCircleGear from "../../images/UserCircleGear.png";
import MopedIcon from "@mui/icons-material/Moped";
import pic from "../../images/avatar.svg";
import { getDate } from "../../utils/methods";
import UserBookingList from "./bookingList";

// tab start
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ----------------------------------------------------------------------
export default function BusinessDetails() {
  const location = useLocation();
  const [user, setUser] = useState(location.state);
  console.log("user", user);
  const { themeStretch } = useSettings();
  const [value, setValue] = useState(0);
  React.useEffect(() => {
    setUser(location.state);
  }, [location.state]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };
  return (
    <ThemeProvider theme={{}}>
      <Page>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              className="px-0"
              sx={{ padding: "24px", paddingBottom: "0" }}
              style={{ display: "flex", borderBottom: "1px solid #E04E2C" }}
            >
              <img
                alt="This is an Icon."
                src={larrow}
                onClick={handleOnClick}
                height={20}
                style={{
                  marginRight: "10px",
                  marginTop: "7px",
                  width: "25px",
                  height: "25px",
                  cursor: "pointer",
                }}
              />
              <Typography
                style={{
                  color: "#030303",
                  fontSize: "24px",
                  fontWeight: "300",
                  marginBottom: "15px",
                }}
              >
                USER DETAILS
              </Typography>
            </Box>
            <Grid
              container
              spacing={3}
              style={{
                background: "#EBEBEB",
                width: "auto",
                marginLeft: "0",
                marginTop: "15px",
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                lg={4}
                style={{ padding: "0 0 0px 0px" }}
              >
                <Box
                  style={{
                    padding: "32px 50px",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    background: "white",
                  }}
                >
                  <Box
                    sx={{
                      height: "211px",
                      width: "211px",
                      borderRadius: "50%",
                      background: "#F9F9F9",
                      margin: "0 auto",
                      filter: "drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.2))",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {user?.photo_url ? (
                      <img
                        src={user?.photo_url}
                        alt=" user profile"
                        style={{
                          height: "211px",
                          width: "211px",
                          borderRadius: "50%",
                        }}
                      />
                    ) : (
                      <img
                        src={pic}
                        alt="user profile icon"
                        style={{
                          height: "180px",
                          width: "180px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    {user?.isVerified? (
                      <img
                        src={verified_user}
                        alt="user verified icon"
                        style={{ height: "32px", width: "32px" }}
                      />
                    ) : (
                      <img
                        src={UserCircleGear}
                        alt="circler user icon"
                        style={{
                          height: "30px",
                          width: "30px",
                          position: "relative",
                          top: "-60px",
                          right: "-70px",
                        }}
                      />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: "700",
                      fontFamily: "'Cabin', sans-serif",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {user?.display_name}
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      marginBottom: "6px",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "'Cabin', sans-serif",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Joined : {getDate(user?.created_time)}
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: "6px",
                      fontSize: "16px",
                      fontWeight: "600",
                      fontFamily: "'Cabin', sans-serif",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Mobile Number : {user?.phone_number}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={8}
                style={{
                  padding: "0 0 0px 15px",
                  minHeight: "240px",
                  maxHeight: "840px",
                }}
              >
                <Box
                  style={{
                    padding: "32px 50px",
                    background: "white",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        height: "120px",
                        width: "30%",
                        background: "#1D313F",
                        borderRadius: "20px",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          fontFamily: "'Cabin', sans-serif",
                          color: "white",
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        Total Orders
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "30px",
                          fontWeight: "700",
                          fontFamily: "'Cabin', sans-serif",
                          color: "#E3552E",
                          textAlign: "center",
                        }}
                      >
                        {user?.orders}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "120px",
                        width: "30%",
                        background: "#1D313F",
                        borderRadius: "20px",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "400",
                          fontFamily: "'Cabin', sans-serif",
                          color: "white",
                          textAlign: "center",
                          marginTop: "15px",
                        }}
                      >
                        Status
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "30px",
                          fontWeight: "700",
                          fontFamily: "'Cabin', sans-serif",
                          color: "#E3552E",
                          textAlign: "center",
                        }}
                      >
                        {user?.isActive ? "Active" : "Blocked"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                      position: "relative",
                    }}
                  ></Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "#E3552E",
                      marginTop: "30px",
                      fontFamily: "'Cabin', sans-serif",
                      borderBottom: "1px solid #E8ECEE",
                    }}
                  >
                    Latest Booking
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      marginTop: "20px",
                      alignItems: "center",
                    }}
                  >
                    <MopedIcon
                      style={{
                        height: "50px",
                        width: "50px",
                        color: "#E3552E",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="ORDERS"
                  {...a11yProps(0)}
                  style={{
                    color: "#030303",
                    fontSize: "32px",
                    fontWeight: "300",
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} className="tabPanel">
              <UserBookingList userId={user?.id} />
            </TabPanel>
          </Box>
        </Container>
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, deleteBooking }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem onClick={() => deleteBooking(row?.id)}>
          <Iconify icon={"eva:trash-2-outline"} />
          Delete
        </MenuItem>
      </MenuPopover>
    </>
  );
}
