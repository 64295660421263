import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Conformation from "../../components/Conformation";
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import useSettings from "../../hooks/useSettings";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "src/components/LoadingScreen";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { getDate } from "src/utils/methods";
import { fetchManagers, reset } from "src/redux/slices/GetManagers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "city", label: "City", alignRight: false },
  { id: "email", label: "email", alignRight: false },
  { id: "mobile", label: "Mobile", alignRight: false },
  { id: "created", label: "Created", alignRight: false },
  { id: "isActive", label: "Active", alignRight: true },
  { id: "Action", label: "Action", alignRight: true },
];

// ----------------------------------------------------------------------

export default function CouponList() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getManagers);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [couponList, setCouponList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [value, setValue] = useState(0);
  const [updated, setUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({ id: null, state: false });
  const [filterData, setFilterData] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = couponList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = couponList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setCouponList(deleteUsers);
  };
  const isNotFound = couponList?.length === 0 ? true : false;

    const deleteCoupon = () => {
    setOpen(false);

    axios
      .put(`managers/update/${updateObj.id}`, { isActive: !updateObj.state })
      .then((res) => {
        console.log(res.data);
        setUpdated(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page, updated]);
  useEffect(() => {
    getCoupon();
  }, [state.data]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.type = "all";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      await axios
        .post("/managers/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchManagers(payload));
          } else {
            setCouponList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getCoupon = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchManagers(payload));
      } else {
        setCouponList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleManagerClick = (manager) => {
    localStorage.setItem("manager_city", manager?.cityId);
    localStorage.setItem("manager_id", manager?.id);
    localStorage.setItem("manager_uid", manager?.uid);
  };
  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Managers">
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box className="px-0" sx={{ padding: "24px", paddingBottom: "0" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="MANAGERS"
                {...a11yProps(0)}
                style={{
                  color: "#030303",
                  fontSize: "32px",
                  fontWeight: "300",
                }}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} className="tabPanel">
            <Button
              className="createBtn"
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.managers.newManager}
              startIcon={<Iconify icon={"eva:plus-fill"} />}
              style={{ background: "#E04E2C" }}
            >
              New Manager
            </Button>
            <Box style={{ padding: "0" }}>
              <Card sx={{ borderRadius: "0" }}>
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                  onDeleteUsers={() => handleDeleteMultiUser(selected)}
                  setFilterValue={setFilterValue}
                  setSearchValue={setSearchValue}
                  searchValue={searchValue}
                  filterData={filterData}
                />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 600, height: "calc(80vh)" }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={couponList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {couponList?.map((row) => {
                          const isItemSelected =
                            selected.indexOf(row.id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={row.id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >

                              <Tooltip title="Go to Manager's Panel" placement="bottom">
                              <TableCell
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  textDecoration: "none",
                                }}
                                component={RouterLink}
                                to={PATH_DASHBOARD.general.app}
                                onClick={() => handleManagerClick(row)}
                              >
                                <Avatar
                                  alt={"Manager Photo"}
                                  src={row?.photo_url}
                                  sx={{ mr: 2, fontSize: "16px" }}
                                />
                                <Typography variant="subtitle2" noWrap>
                                  {row?.name?.replace(
                                    /(\w)(\w*)/g,
                                    (_, g1, g2) =>
                                      g1.toUpperCase() + g2.toLowerCase()
                                  ) || ""}
                                </Typography>
                              </TableCell>
                              </Tooltip>

                              <TableCell>{row?.cityObj?.city}</TableCell>
                              <TableCell>{row?.email}</TableCell>
                              <TableCell>{row?.mobile}</TableCell>
                              <TableCell>{getDate(row?.created)}</TableCell>
                              <TableCell align="right">
                                {row?.isActive === true ? (
                                  <DoneIcon sx={{ color: "green" }} />
                                ) : (
                                  <CloseIcon sx={{ color: "red" }} />
                                )}
                              </TableCell>
                              <TableCell align="right">
                                <ThreedotMenu
                                  row={row}
                                  setUpdateObj={setUpdateObj}
                                  setOpenHandler={setOpen}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {isNotFound && (
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={{ py: 3 }}
                              colSpan={15}
                            >
                              No data found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[50, 100, 250]}
                  component="div"
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={(e, page) => setPage(page + 1)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </Box>
          </TabPanel>
        </Container>
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={deleteCoupon}
          title={`${updateObj.state ? "Block" : "Active"} Confirmation`}
          subTitle={`Do you want to ${
            updateObj.state ? "block" : "active"
          } the Manager?`}
        />
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, setUpdateObj, setOpenHandler }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHandler(true);
            handleClose();
            setUpdateObj({ id: row?.id, state: row?.isActive });
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify
            sx={{
              mr: 2,
              width: 20,
              height: 20,
            }}
          >
            {!row?.isActive ? (
              <DoneIcon sx={{ color: "green" }} />
            ) : (
              <CloseIcon sx={{ color: "red" }} />
            )}
          </Iconify>
          {row.isActive ? (
            <span style={{ color: "red" }}> Deactivate</span>
          ) : (
            <span style={{ color: "green" }}> Active</span>
          )}
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.managers.editById}/${row?.id}`}
          state={{ fromDashboard: true }}
        >
          <Iconify icon={"eva:edit-fill"} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
