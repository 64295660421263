import React, { useState } from 'react';
import { Chip, Box } from '@mui/material';

const DaySelector = ({ setData, name, options, selectedDays }) => {
  const handleDaySelect = (day) => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter((selectedDay) => selectedDay !== day)
      : [...selectedDays, day];

    setData((prevData) => ({
      ...prevData,
      [name]: updatedDays,
    }));
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {options.map((day) => (
        <Chip
          key={day}
          label={day}
          onClick={() => handleDaySelect(day)}
          sx={{
            cursor: 'pointer',
            marginRight: '5px',
            marginBottom: '4px',
            backgroundColor: selectedDays.includes(day)
              ? '#e24d1f'
              : 'default',
            color: selectedDays.includes(day) ? '#FFFFFF' : 'default',
          }}
        />
      ))}
    </Box>
  );
};

export default DaySelector;
