import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { RHFTextField } from "src/components/hook-form";
import axios from "../../utils/axiosconfig";

const DestinationForm = ({ userId, onOpen, onClose, onSave }) => {
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [flat, setFlat] = useState("");
  const [area, setArea] = useState("");
  const [landmark, setLandmark] = useState("");

  const handleDestSubmit = (e) => {
    e.preventDefault();

    const destinationData = {
      userId: userId,
      latitude,
      longitude,
      name: businessName,
      address,
      mobile: phoneNumber,
      area,
      landmark,
      Flat: flat,
    };
    axios
      .post("/destinations/add/", destinationData)
      .then((res) => {
        console.log("Success:", res.data);
        onSave(res.data);
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {userId && (
        <Dialog open={onOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogContent>
            <Box>
              <Typography variant="h5" gutterBottom>
                Add New Destination
              </Typography>

              <Box
                component="form"
                sx={{
                  display: "grid",
                  columnGap: 3,
                  rowGap: 3,
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  },
                }}
              >
                <Box>
                  <Typography>Latitude</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="latitude"
                    value={latitude}
                    type="number"
                    onChange={(e) => setLatitude(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Longitude</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="longitude"
                    value={longitude}
                    type="number"
                    onChange={(e) => setLongitude(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Business Name</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="businessName"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Address</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </Box>

                <Box>
                  <Typography>Phone number</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="phoneNumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Flat / House no / Floor / Building</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="address"
                    value={flat}
                    onChange={(e) => setFlat(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Area / Sector / Locality</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="address"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                    required
                  />
                </Box>
                <Box>
                  <Typography>Landmark</Typography>
                  <RHFTextField
                    sx={{ width: "100%" }}
                    name="address"
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                    required
                  />
                </Box>
                <Box></Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button variant="contained" onClick={handleDestSubmit}>
                    Save
                  </Button>
                  <Button
                    sx={{ float: "right", marginLeft: 1 }}
                    variant="outlined"
                    onClick={handleClose}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DestinationForm;
