import PropTypes from "prop-types";
import { useState } from "react";
import { MenuItem, IconButton } from "@mui/material";
import Iconify from "../../../../components/Iconify";
import MenuPopover from "../../../../components/MenuPopover";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { CopyAll, Edit } from "@mui/icons-material";

UserMoreMenu.propTypes = {
  onDelete: PropTypes.func,
  userName: PropTypes.string,
};

export default function UserMoreMenu({
  row,
  setUpdateObj,
  onEdit,
  setOpenHandler,
  isCopyIdOpt = false,
  isEditable = false,
}) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const EDIT_ICON = {
    marginRight: "3px",
    width: 18,
    height: 18,
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHandler(true);
            setUpdateObj({ id: row?.id, state: !row?.isActive });
            handleClose();
          }}
        >
          <Iconify sx={{ ...ICON }}>
            {!row?.isActive ? (
              <DoneIcon sx={{ color: "green" }} />
            ) : (
              <CloseIcon sx={{ color: "red" }} />
            )}
          </Iconify>

          {row.isActive ? (
            <span style={{ color: "red" }}>Deactivate </span>
          ) : (
            <span style={{ color: "green" }}> Active</span>
          )}
        </MenuItem>
        {isCopyIdOpt && (
          <MenuItem
            onClick={() => {
              setOpenHandler(false);
              handleClose();
              navigator.clipboard.writeText(row.id)
            }}
          >
            <Iconify sx={{ ...ICON }}>
              <CopyAll sx={{ color: "orange" }} />
            </Iconify>

            <span style={{ color: "orange" }}>Copy Id </span>
          </MenuItem>
        )}
        {isEditable && (
          <MenuItem
            onClick={() => {
              onEdit(row);
              setOpenHandler(false);
              handleClose();

            }}
          >
            <Iconify sx={{ ...EDIT_ICON }}>
              <Edit sx={{ color: "orange" }} />
            </Iconify>

            <span style={{ color: "orange" }}>Edit </span>
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}
