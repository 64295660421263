import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import larrow from "../../images/arrow-left.svg";
import { SentIcon } from "../../assets";

import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Card,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
// components
import { FormProvider, RHFTextField } from "../../components/hook-form";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Group from "../../images/Group.svg";
import Plus from "../../images/Plus.svg";
// hooks
import useSettings from "../../hooks/useSettings";

export default function DefaultNewNotification() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [flieSelected, setFileSelected] = useState(false);
  const [icon, setIcon] = useState(null);
  const [iconimage, setIconImage] = useState();
  const [url, setUrl] = useState(null);
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes("edit");
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });
  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      axios
        .get(`/notifications/get/${id}`)
        .then((res) => {
          const data = res.data?.result;
          setTitle(data?.title || "");
          setMessage(data?.message || "");
          setType(data?.type || "");
          setUrl(data?.icon || null);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const handleOnClick = () => {
    navigate(-1);
  };

  const handleFileChange = (event) => {
    setIcon(event.target.files[0]);
    setIconImage(URL.createObjectURL(event.target.files[0]));
    setUrl(URL.createObjectURL(event.target.files[0]));
    setFileSelected(true);
  };
  const onSubmit = () => {
    setLoading(true);

    if (!flieSelected && !isEdit) {
      enqueueSnackbar("Please select an icon.", {
        variant: "error",
        style: { marginTop: "100px" },
      });
      setLoading(false);
      return;
    }
    const path = isEdit ? `update/${id}` : "add";

    let formData = new FormData();
    formData.append("icon", icon);
    formData.append("title", title);
    formData.append("type", type);
    formData.append("message", message);
    formData.append("isDefault", true);

    axios({
      method: isEdit ? "put" : "post",
      url: `/notifications/${path}`,
      data: formData,
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        navigate(PATH_DASHBOARD.defaultNotification.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ThemeProvider theme={{}}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            marginTop: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                className="px-0"
                sx={{
                  padding: "24px",
                  paddingBottom: "0",
                  display: "flex",
                  borderBottom: "1px solid #E04E2C",
                }}
              >
                <img
                alt="arrow icon"
                  src={larrow}
                  onClick={handleOnClick}
                  height={20}
                  style={{
                    marginRight: "10px",
                    marginTop: "7px",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                />
                {!isEdit ? (
                  <Typography
                    sx={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                    }}
                  >
                    ADD NEW NOTIFICATION
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "#030303",
                      fontSize: "24px",
                      fontWeight: "300",
                      borderBottom: "1px solid #E04E2C",
                    }}
                  >
                    EDIT NOTIFICATION
                  </Typography>
                )}
              </Box>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
              >
                <HeaderBreadcrumbs
                  heading={""}
                  links={[
                    {
                      name: "Notifications",
                      href: PATH_DASHBOARD.notification.notifications,
                    },
                    {
                      name: "Add new",
                      href: PATH_DASHBOARD.notification.root,
                    },
                  ]}
                />
              </Box>
              <Grid
                container
                spacing={3}
                className="px-0"
                sx={{ paddingLeft: "24px", paddingRight: "24px" }}
              >
                <Grid item xs={12} md={6} lg={6} sx={{ gap: "100px" }}>
                  <Card sx={{ padding: "40px", borderRadius: "0" }}>
                    <Grid container spacing={3}>
                      <Box
                      sx={{
                        display:"flex",
                        gap:2,
                        paddingTop:"20px"
                      }}
                      >
                      <Box
                        sx={{
                          marginLeft:"25px"
                        }} 
                      >
                        <IconButton
                          variant="contained"
                          component="label"
                          className="iconBtn"
                        >
                          <input
                            hidden
                            name="icon"
                            accept="image/*"
                            type="file"
                            onChange={handleFileChange}
                          />
                          <img src={url ? url : Plus} alt="Plus icon" />
                        </IconButton>
                        <Typography
                          sx={{
                            color: "#909090",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "15px",
                            textAlign: "center",
                          }}
                        >
                          Icon
                        </Typography>
                      </Box>
                      <Box >
                        <Typography
                          className="para_label"
                          style={{ marginTop: "15px" }}
                        >
                          Notification Title
                        </Typography>
                        <RHFTextField
                          name="title"
                          placeholder="Enter the Notification Title here"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </Box>
                      </Box>
                      <Grid item xs={12} md={12} lg={12}>
                        <Typography className="para_label">
                          Notification Description
                        </Typography>
                        <textarea
                          placeholder="Type Notification Description here"
                          style={{
                            height: "155px",
                            fontSize: "16px",
                            width: "100%",
                            fontFamily: "Inter,sans-serif",
                            borderRadius: "8px",
                            resize: "none",
                            border: "1px solid #E2E1E5",
                            padding: "10px",
                          }}
                          name="title"
                          label="Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography
                          className="para_label"
                        >
                          Notification Type
                        </Typography>
                        <RHFTextField
                          name="type"
                          placeholder="Enter the Notification Type here"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <LoadingButton
                          sx={{borderRadius: "5px",marginTop:'15px' }}
                          type="submit"
                          fullWidth
                          variant="contained"
                          size="large"
                          loading={isSubmitting}
                        >
                          {!isEdit ? "ADD" : "Save Changes"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Card
                    sx={{ p: 3, mb: 3, padding: "30px", borderRadius: "0" }}
                  >
                    <Typography className="para_label">Preview</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        boxShadow: "0 0 7px 0px #e3e3e3",
                        borderRadius: "30px",
                        padding: "15px 16px",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box
                        style={{
                          marginRight: "15px",
                          width: "80px",
                          borderRadius: "50%",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={iconimage ? iconimage : Group}
                          alt="icon"
                          style={{ height: "60px" }}
                        />
                      </Box>
                      <Box sx={{ flex: "1" }}>
                        <Typography
                          sx={{
                            color: "#020202",
                            fontSize: "16px",
                            fontWeight: 700,
                            marginBottom: "10px",
                          }}
                        >
                          {title === "" ? "Notification title" : title}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#020202",
                            fontSize: "16px",
                            fontWeight: 300,
                          }}
                        >
                          {message === "" ? "Notification description" : message}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>

                  <Box
                    sx={{
                      display: "flex",
                      boxShadow: "0 0 7px 0px #e3e3e3",
                      borderRadius: "30px",
                      padding: "15px 16px",
                      justifyContent: "center",
                      background: "white",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <SentIcon sx={{ height: 350 }} />
                      <Typography
                        sx={{ mb: 2, width: 400, textAlign: "center" }}
                      >
                        Create a new notification to alert users about offers or
                        share relevant information.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
