import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axiosconfig";
export const fetchCities = createAsyncThunk("getCities", async (payload) => {
  const response = await axios.post("/cities/get/all", payload);
  return response;
});
const getCities = createSlice({
  name: "getCities",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCities.rejected, (state, action) => {
      console.log("Error............", action.payload);
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { reset } = getCities.actions;
export default getCities.reducer;
