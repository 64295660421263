import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import larrow from "../../images/arrow-left.svg";
import Switch from "@mui/material/Switch";
import { ComingSoonIllustration } from "../../assets";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Card,
  Container,
  Stack,
  TextField,
  Typography,
  Box,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import axios from "../../utils/axiosconfig";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import {
  FormProvider,
  RHFTextField,
  RHFSelect,
} from "../../components/hook-form";
import couponIcon from "../../images/coupon.png";
import useSettings from "../../hooks/useSettings";

export default function NewCoupon() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  const [loading, setLoading] = useState(false);
  const cityId = localStorage.getItem("manager_city");
  const { enqueueSnackbar } = useSnackbar();
  const defaultValue = {
    cityId: cityId,
    title: "",
    code: "",
    type: "Flat",
    discount: null,
    maximum: null,
    promoted: false,
    description: "",
    userLimit: null,
    totalLimit: null,
    startTime: new Date(),
    endTime: new Date(Date.now() + 24 * 60 * 60 * 1000),
  };
  const [coupon, setCoupon] = useState(defaultValue);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const methods = useForm({
    // resolver: yupResolver(NewUserSchema),
    // defaultValues,
  });
  useEffect(() => {
    if (id) {
      getCoupon();
    }
  }, []);
  const handleStartTimeChange = (startTime) => {
    const startDate = new Date(startTime);
    const startTimeInNumberFormat = startDate.getTime();
    const endTime = new Date(coupon.endTime);
    const endTimeInNumberFormat = endTime.getTime();
    if (
      startTimeInNumberFormat > endTimeInNumberFormat ||
      startTimeInNumberFormat < new Date().getTime()
    ) {
      enqueueSnackbar("Invalid Start Time", {
        variant: "error",
        style: { marginTop: "100px" },
      });
    } else {
      setCoupon((preState) => ({ ...preState, startTime: startTime }));
    }
  };

  const handleEndTimeChange = (endTime) => {
    const endDate = new Date(endTime);
    const endTimeInNumberFormat = endDate.getTime();
    const startTime = new Date(coupon.startTime);
    const startTimeInNumberFormat = startTime.getTime();
    if (endTimeInNumberFormat < startTimeInNumberFormat) {
      enqueueSnackbar("Invalid End Time", {
        variant: "error",
        style: { marginTop: "100px" },
      });
    } else {
      setCoupon((preState) => ({ ...preState, endTime: endTime }));
    }
  };

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const getCoupon = () => {
    setLoading(true);
    axios
      .get(`/coupons/get/${id}`)
      .then((res) => {
        console.log("testing55..........", res?.data?.result);
        const obj = res?.data?.result;
        obj.startTime = new Date(
          obj?.startTime?._seconds * 1000 + obj?.startTime?._nanoseconds / 1e6
        );
        obj.endTime = new Date(
          obj?.endTime?._seconds * 1000 + obj?.endTime?._nanoseconds / 1e6
        );
        setCoupon(obj || defaultValue);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleOnClick = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    setLoading(true);
    if (!isEdit) {
      axios
        .post("/coupons/add", coupon)
        .then((res) => {
          console.log("testing", res);
          setLoading(false);
          navigate(PATH_DASHBOARD.coupon.coupons);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .put(`/coupons/update/${id}`, coupon)
        .then((res) => {
          console.log(res);
          setLoading(false);
          navigate(PATH_DASHBOARD.coupon.coupons);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <ThemeProvider theme={{}}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1000,
            marginTop: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <Container
          maxWidth={themeStretch ? false : "xl"}
          style={{ paddingLeft: "16px", paddingRight: "16px" }}
        >
          <Box sx={{ width: "100%" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0" }}
                style={{
                  display: "flex",
                  borderBottom: "1px solid #E04E2C",
                }}
              >
                <Box>
                  <img
                    src={larrow}
                    onClick={handleOnClick}
                    height={20}
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "25px",
                      height: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
                <Box>
                  {!isEdit ? (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      CREATE NEW COUPON
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#030303",
                        fontSize: "24px",
                        fontWeight: "300",
                      }}
                    >
                      EDIT COUPON
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                className="px-0"
                sx={{ padding: "24px", paddingBottom: "0", paddingTop: "0" }}
              >
                <HeaderBreadcrumbs
                  heading={""}
                  links={[
                    { name: "Coupons", href: PATH_DASHBOARD.coupon.coupons },
                    {
                      name: "Add new",
                      href: PATH_DASHBOARD.coupon.root,
                    },
                  ]}
                />
              </Box>

              <Grid container flexWrap="wrap">
                <Box container item marginBottom="30px" flexGrow="1">
                  <Box sx={{ background: "white", padding: "30px" }}>
                    <Box
                      sx={{
                        display: "grid",
                        columnGap: 2,
                        rowGap: 3,
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(2, 1fr)",
                        },
                      }}
                    >
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Type
                        </Typography>
                        <RHFSelect
                          sx={{ width: "100%" }}
                          style={{ color: "grey", fontSize: "13px" }}
                          name="type"
                          value={coupon?.type}
                          defaultValue="Flat"
                          onChange={(e) =>
                            setCoupon((preState) => ({
                              ...preState,
                              type: e.target.value,
                            }))
                          }
                          required
                        >
                          <option value="percentage">Percent</option>
                          <option value="Flat" selected>
                            Flat
                          </option>
                        </RHFSelect>
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Discount
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="title"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {coupon?.type === "Flat" ? "₹" : "%"}
                              </InputAdornment>
                            ),
                          }}
                          value={coupon?.discount}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (coupon?.type === "percentage") {
                              if (inputValue <= 100) {
                                setCoupon((preState) => ({
                                  ...preState,
                                  discount: inputValue,
                                }));
                              } else {
                                enqueueSnackbar("Invalid percentage value.", {
                                  variant: "error",
                                  style: { marginTop: "100px" },
                                });
                              }
                            } else {
                              setCoupon((preState) => ({
                                ...preState,
                                discount: inputValue,
                              }));
                            }
                          }}
                          type="number"
                          required
                        />
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Title
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="title"
                          value={coupon?.title}
                          onChange={(e) =>
                            setCoupon((preState) => ({
                              ...preState,
                              title: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Start Time
                        </Typography>
                        <DateTimePicker
                          format="dd/MM/yyyy hh:mm a"
                          name="startTime"
                          label="Start Time"
                          value={coupon?.startTime}
                          onChange={handleStartTimeChange}
                          className="custom-calendar"
                          calendarClassName="rasta-stripes"
                        />
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          End Time
                        </Typography>
                        <DateTimePicker
                          format="dd/MM/yyyy hh:mm a"
                          name="endTime"
                          label="Start Time"
                          value={coupon?.endTime}
                          onChange={handleEndTimeChange}
                          className="custom-calendar"
                          calendarClassName="rasta-stripes"
                        />
                      </Box>
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Code
                        </Typography>
                        <TextField
                          sx={{ width: "100%" }}
                          name="code"
                          value={coupon?.code}
                          onChange={(e) =>
                            setCoupon((preState) => ({
                              ...preState,
                              code: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      {coupon?.type === "percentage" && (
                        <Box>
                          <Typography className="para_label" variant="h5">
                            Maximum
                          </Typography>
                          <RHFTextField
                            sx={{ width: "100%" }}
                            name="maximum"
                            value={coupon?.maximum}
                            onChange={(e) =>
                              setCoupon((preState) => ({
                                ...preState,
                                maximum: e.target.value,
                              }))
                            }
                            type="number"
                          />
                        </Box>
                      )}
                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          User Limit
                        </Typography>
                        <RHFTextField
                          sx={{ width: "100%" }}
                          name="userLimit"
                          type="number"
                          value={coupon?.userLimit}
                          onChange={(e) =>
                            setCoupon((preState) => ({
                              ...preState,
                              userLimit: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      <Box>
                        <Box sx={{}}>
                          <Typography className="para_label" variant="h5">
                            Total Limit
                          </Typography>
                          <RHFTextField
                            sx={{ width: "100%" }}
                            name="totalLimit"
                            type="number"
                            value={coupon?.totalLimit}
                            required
                            onChange={(e) =>
                              setCoupon((preState) => ({
                                ...preState,
                                totalLimit: e.target.value,
                              }))
                            }
                          />
                        </Box>
                      </Box>

                      <Box sx={{}}>
                        <Typography className="para_label" variant="h5">
                          Description
                        </Typography>
                        <textarea
                          placeholder=" Information about the Coupon"
                          style={{
                            fontSize: "14px",
                            height: "100px",
                            width: "100%",
                            color: "#020202",
                            fontWeight: 500,
                            borderRadius: "8px",
                            resize: "none",
                            fontFamily: "Inter,sans-serif",
                            border: "1px solid #E2E1E5",
                            padding: "10px",
                          }}
                          name="description"
                          label="Message"
                          value={coupon?.description}
                          onChange={(e) =>
                            setCoupon((preState) => ({
                              ...preState,
                              description: e.target.value,
                            }))
                          }
                          required
                        />
                      </Box>
                      <Box>
                        <Typography className="para_label" variant="h5">
                          Promoted
                        </Typography>
                        <Switch
                          name="promoted"
                          checked={coupon?.promoted}
                          onChange={(e) =>
                            setCoupon((preState) => ({
                              ...preState,
                              promoted: e.target.checked,
                            }))
                          }
                          color="warning"
                        />
                      </Box>
                    </Box>
                    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                      <LoadingButton
                        sx={{ padding: "15px 0", borderRadius: "5px" }}
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        loading={isSubmitting}
                      >
                        {!isEdit ? "ADD" : "Save Changes"}
                      </LoadingButton>
                    </Stack>
                  </Box>
                </Box>
                <Grid item sx={{ ml: 3, width: "30%" }} flexGrow="1">
                  <Card sx={{ p: 3, mb: 3 }}>
                    <Typography className="para_label" variant="h5">
                      Preview
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        boxShadow: "0 0 7px 0px #e3e3e3",
                        borderRadius: "30px",
                        padding: "15px 16px",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        style={{
                          marginRight: "15px",
                          height: "70px",
                          width: "70px",
                          borderRadius: "50%",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={couponIcon}
                          alt="Coupon Icon"
                          style={{ height: "3.64vw" }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: "#020202",
                            fontSize: "16px",
                            fontWeight: 700,
                            marginBottom: "10px",
                          }}
                        >
                          {coupon?.title}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#020202",
                            fontSize: "16px",
                            fontWeight: 700,
                            marginBottom: "10px",
                          }}
                        >
                          Discount: {coupon?.type === "Flat" && "₹ "}
                          {coupon?.discount}
                          {coupon?.type === "https://us-central1-pickupdotninja.cloudfunctions.net/app/api/coupons/add" && " %"}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#020202",
                            fontSize: "16px",
                            fontWeight: 700,
                            marginBottom: "10px",
                          }}
                        >
                          Coupon Code: {coupon?.code}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#020202",
                            fontSize: "16px",
                            fontWeight: 700,
                            marginBottom: "10px",
                            wordBreak: "break-all",
                          }}
                        >
                          {coupon?.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>

                  <Box
                    sx={{
                      display: "flex",
                      boxShadow: "0 0 7px 0px #e3e3e3",
                      borderRadius: "30px",
                      padding: "15px 16px",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "white",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <ComingSoonIllustration sx={{ height: 350 }} />
                      <Typography sx={{ mb: 2 }}>
                        Create a new coupon to offer discounts to users.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
