import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import larrow from "../../images/arrow-left.svg";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { FormProvider } from "../../components/hook-form";
import useSettings from "../../hooks/useSettings";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Notifications from "./Notifications";
import Groups from "./Groups";
import Image from "src/components/Image";
import avatar from "../../images/avatar.svg";
import Iconify from "../../components/Iconify";
import GroupsIcon from "@mui/icons-material/Groups";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import axios from "../../utils/axiosconfig";

export default function NewCampaign() {
  const { themeStretch } = useSettings();
  const [data, setData] = useState({ notification: null, groups: [] });
  const navigate = useNavigate();
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [status, setStatus] = useState("pending");
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEdit = pathname.includes("edit");
  useEffect(() => {
    if (id) {
      getCampaign();
    }
  }, [id]);

  const getCampaign = () => {
    setLoading(true);
    axios
      .get(`/campaigns/get/${id}`)
      .then((res) => {
        const obj = res?.data?.result;
        setData({
          notification: obj?.notificationObj || null,
          groups: obj?.groups || [],
        });
        setStatus(obj?.status || "pending");
        setSelectedNotification([obj?.notificationObj.id]);
        setSelectedGroups(obj?.groupsIds || []);
        setGroupsList(obj?.groups || []);
        const dateObj = obj?.scheduleTime;
        setScheduleTime(
          new Date(dateObj._seconds * 1000 + dateObj._nanoseconds / 1000000)
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const handleScheduleTimeChange = (scheduleTime) => {
    const scheduleDate = new Date(scheduleTime);
    setScheduleTime(scheduleDate);
  };
  const handleOnClick = () => {
    navigate(-1);
  };
  const handleCreateCampaign = () => {
    setLoading(true);
    const groupsIds = data?.groups?.map((obj) => obj.id);
    const payload = {
      managerId: localStorage.getItem("manager_id"),
      notificationId: data?.notification.id,
      groupsIds: groupsIds,
      scheduleTime: scheduleTime,
    };
    if (isEdit) {
      if (scheduleTime > new Date() && status === "completed") {
        payload.status = "pending";
      }
      delete payload.managerId;
      axios
        .put(`/campaigns/update/${id}`, payload)
        .then((res) => {
          console.log("testing", res);
          setLoading(false);
          navigate("/notifications/campaigns");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
      return;
    }

    axios
      .post("/campaigns/add", payload)
      .then((res) => {
        console.log("testing", res);
        setLoading(false);
        navigate("/notifications/campaigns");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  return (
    <ThemeProvider theme={{}}>
      <Container
        maxWidth={themeStretch ? false : "xl"}
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      >
        <div
          style={{
            position: "absolute",
            width: "85%",
            zIndex: 1000,
            marginTop: 350,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading && <CircularProgress />}
        </div>
        <FormProvider>
          <Box
            sx={{
              padding: "24px",
              paddingBottom: "0",
              display: "flex",
              borderBottom: "1px solid #E04E2C",
            }}
          >
            <img
              alt="arrow icon"
              src={larrow}
              onClick={handleOnClick}
              height={20}
              style={{
                marginRight: "10px",
                marginTop: "7px",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
            />
            <Typography
              sx={{
                color: "#030303",
                fontSize: "24px",
                fontWeight: "300",
              }}
            >
              {isEdit ? "EDIT CAMPAIGN" : "CREATE NEW CAMPAIGN"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              paddingBottom: "24px",
            }}
          >
            <Box style={{ marginTop: 20, width: "60%" }}>
              <HorizontalLinearStepper
                setData={setData}
                selectedNotification={selectedNotification}
                setSelectedNotification={setSelectedNotification}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                groupsList={groupsList}
                setGroupsList={setGroupsList}
              />
            </Box>
            <Box style={{ marginTop: 20, width: "40%" }}>
              <CampaignCard
                isEdit={isEdit}
                status={status}
                data={data}
                scheduleTime={scheduleTime}
                handleScheduleTimeChange={handleScheduleTimeChange}
                handleCreateCampaign={handleCreateCampaign}
              />
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </ThemeProvider>
  );
}

const steps = ["Select Notification", "Select Groups"];

export function HorizontalLinearStepper({
  selectedNotification,
  setData,
  setSelectedNotification,
  selectedGroups,
  setSelectedGroups,
  groupsList,
  setGroupsList,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 1) return;
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
        <Box sx={{ display: "flex", flexDirection: "row", pl: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ pt: 2 }}>
            {activeStep === 0 && (
              <Notifications
                setData={setData}
                selectedNotification={selectedNotification}
                setSelectedNotification={setSelectedNotification}
              />
            )}
            {activeStep === 1 && (
              <Groups
                setData={setData}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                groupsList={groupsList}
                setGroupsList={setGroupsList}
              />
            )}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}

const CampaignCard = ({
  data,
  scheduleTime,
  handleScheduleTimeChange,
  handleCreateCampaign,
  isEdit,
  status,
}) => {
  const { notification, groups } = data;
  return (
    <Box
      sx={{
        mt: 6,
        ml: 3,
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>Notification</Typography>
      {notification ? (
        <NotificationCard notification={notification} />
      ) : (
        <Typography sx={{ mt: 3 }}>Please Select Notification</Typography>
      )}
      <Typography sx={{ mt: 3, fontWeight: "bold" }}>Groups</Typography>
      {groups.length === 0 && (
        <Typography sx={{ mt: 3 }}>Please Select Groups</Typography>
      )}
      <Box sx={{ maxHeight: "600px", overflow: "auto" }}>
        {groups?.map((group) => (
          <GroupCard group={group} />
        ))}
      </Box>
      {data.notification !== null && data.groups.length > 0 && (
        <>
          <Box sx={{ mt: 3, display: "flex", alignItems: "center", gap: 2 }}>
            <Typography>Schedule Time</Typography>
            <Box>
              <DateTimePicker
                format="dd/MM/yyyy hh:mm a"
                name="scheduleTime"
                label="Schedule Time"
                value={scheduleTime}
                onChange={handleScheduleTimeChange}
                className="custom-calendar"
                calendarClassName="rasta-stripes"
              />
            </Box>
          </Box>
          <Box sx={{ mt: 5, mb: 5, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              startIcon={isEdit ? null : <Iconify icon={"eva:plus-fill"} />}
              style={{ background: "#E04E2C", width: "80%" }}
              onClick={handleCreateCampaign}
            >
              {isEdit ? "Save" : "Create Campaign"}{" "}
              {isEdit &&
                scheduleTime > new Date() &&
                status === "completed" &&
                " and Restart"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

const NotificationCard = ({ notification }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 0 7px 0px #e3e3e3",
        padding: "15px 16px",
        background: "white",
        marginTop: "20px",
        width: "90%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        {notification?.icon?.length > 0 ? (
          <Image
            disabledEffect
            alt="image"
            src={notification?.icon}
            sx={{
              borderRadius: "50%",
              width: 64,
              height: 64,
            }}
          />
        ) : (
          <Image
            disabledEffect
            alt="image"
            src={avatar}
            sx={{
              borderRadius: "50%",
              width: 64,
              height: 64,
            }}
          />
        )}
        <Typography>{notification?.title}</Typography>
      </Box>
      <Typography sx={{ mb: 2, mt: 2 }}>{notification?.message}</Typography>
    </Box>
  );
};

const GroupCard = ({ group }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 0 7px 0px #e3e3e3",
        padding: "10px",
        background: "white",
        marginTop: "20px",
        width: "90%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <GroupsIcon sx={{ fontSize: "25px" }} />
        <Typography>{group?.groupName}</Typography>
      </Box>
    </Box>
  );
};
