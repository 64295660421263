import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Conformation from "../../components/Conformation";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Tabs,
  Box,
  IconButton,
  MenuItem,
  CircularProgress,
  Avatar,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import Page from "../../components/Page";
import Label from "../../components/Label";
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import MenuPopover from "src/components/MenuPopover";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups, reset } from "src/redux/slices/GetGroups";
import LoadingScreen from "src/components/LoadingScreen";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups";
import { getDateTime } from "src/utils/methods";

function abc(index) {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Group Name", alignRight: false },
  { id: "company", label: "Formula", alignRight: false },
  { id: "role", label: "Auto", alignRight: false },
  { id: "status", label: "Active", alignRight: true },
  { id: "Action", label: "Action", alignRight: true },
];
export const getFields = (value) => {
  if (value === "isActive") return "Active";
  else if (value === "display_name") return "Name";
  else if (value === "phone_number") return "Mobile";
  else if (value === "created_time") return "Created";
  else return value;
};

export default function GroupList() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getGroups);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [groupList, setGroupList] = useState([]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updated, setUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [type, setType] = useState("all");
  const [c_Data, setc_Data] = useState(0);
  const [open, setOpen] = useState(false);
  const [updateObj, setUpdateObj] = useState({ id: null, state: false });
  const pageChange = (event, newc_Data) => {
    setc_Data(newc_Data);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const getValue = (value) => {
    if (typeof value === "object") return getDateTime(value);
    else if (typeof value === "boolean") return value ? "true" : "false";
    else return value;
  };
  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = groupList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleDeleteMultiUser = (selected) => {
    const deleteUsers = groupList.filter(
      (user) => !selected.includes(user.name)
    );
    setSelected([]);
    setGroupList(deleteUsers);
  };

  const isNotFound = groupList?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    if (filterValue?.length > 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [type, rowsPerPage, page, updated]);
  useEffect(() => {
    getGroup();
  }, [state.data]);
  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.managerId = localStorage.getItem("manager_id");
      payload.type = type;
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("groups payload........", payload);
      await axios
        .post("/groups/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchGroups(payload));
          } else {
            setGroupList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("error in group", err);
        });
    }
  };

  const deleteGroup = () => {
    setOpen(false);
    if (updateObj.id !== null) {
      axios
        .put(`groups/update/${updateObj.id}`, { isActive: !updateObj.state })
        .then((res) => {
          console.log("delete res.......", res.data);
          setUpdated(true);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const getGroup = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchGroups(payload));
      } else {
        setGroupList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Groups">
        <Box sx={{ width: "100%" }}>
          <Card sx={{ borderRadius: "0" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                background: "#f4f6f8",
                borderRadius: "0",
                padding: "10px 10px 0 10px",
              }}
            >
              <Tabs
                value={c_Data}
                onChange={pageChange}
                aria-label="basic tabs example"
              >
                <Tab label="All" {...abc(0)} onClick={() => setType("all")} />
                <Tab
                  label="Active"
                  {...abc(1)}
                  onClick={() => setType("active")}
                />
                <Tab
                  label="Banned"
                  {...abc(2)}
                  onClick={() => setType("banned")}
                />
              </Tabs>
            </Box>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDeleteUsers={() => handleDeleteMultiUser(selected)}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}
            />

            <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={groupList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {groupList?.map((row) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ mr: 2, fontSize: "16px" }}>
                              <GroupsIcon />
                            </Avatar>
                            <Typography variant="subtitle2" noWrap>
                              {row.groupName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {row.formula.map((item) => (
                              <div>
                                {getFields(item?.field)} {item.operator}{" "}
                                {getValue(item?.value)}
                              </div>
                            ))}
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              color={row.isAuto === false ? "error" : "success"}
                            >
                              {row.isAuto ? "Yes" : "No"}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            {row?.isActive === true ? (
                              <DoneIcon sx={{ color: "green" }} />
                            ) : (
                              <CloseIcon sx={{ color: "red" }} />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <ThreedotMenu
                              row={row}
                              setUpdateObj={setUpdateObj}
                              setOpenHandler={setOpen}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {isNotFound && (
                      <TableRow>
                        <TableCell align="center" sx={{ py: 3 }} colSpan={8}>
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
        <Conformation
          open={open}
          onClose={() => setOpen(false)}
          handleSubmit={deleteGroup}
          title={`${updateObj.state ? "Block" : "Active"} Confirmation`}
          subTitle={`Do you want to ${
            updateObj.state ? "block" : "active"
          } this group?`}
        />
      </Page>
    </ThemeProvider>
  );
}

export function ThreedotMenu({ row, setUpdateObj, setOpenHandler }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };
  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={"eva:more-vertical-fill"} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 160,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenHandler(true);
            setUpdateObj({ id: row?.id, state: row?.isActive });
            handleClose();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify sx={{ ...ICON }}>
            {!row?.isActive ? (
              <DoneIcon sx={{ color: "green" }} />
            ) : (
              <CloseIcon sx={{ color: "red" }} />
            )}
          </Iconify>
          {row.isActive ? (
            <span style={{ color: "red" }}> Deactivate</span>
          ) : (
            <span style={{ color: "green" }}> Active</span>
          )}
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to={`${PATH_DASHBOARD.group.editById}/${row?.id}`}
        >
          <Iconify icon={"eva:edit-fill"} style={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
