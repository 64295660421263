import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgIconStyle from "../../../components/SvgIconStyle";

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 45, height: 45 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("chatnew"),
  mail: getIcon("ic_mail"),
  user: getIcon("User"),
  kanban: getIcon("storenew"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  analytics: getIcon("ic_analytics"),
  package: getIcon("package-thin"),
  dashboardd: getIcon("house-simple-thinn"),
  bookings: getIcon("ClipboardText"),
  booking: getIcon("ic_booking"),
  city: getIcon("buildings-thin"),
  setting: getIcon("GearSix"),
  bell: getIcon("Belllll"),
  article: getIcon("Article"),
  rider: getIcon("PersonSimpleBike"),
  coupon: getIcon("Ticket"),
  manager: getIcon("Users"),
};

const managerNavConfig = [
  {
    subheader: "",
    items: [
      { path: PATH_DASHBOARD.general.app, icon: ICONS.dashboardd },
      { path: PATH_DASHBOARD.general.booking, icon: ICONS.bookings },
      { path: PATH_DASHBOARD.allusers.userList, icon: ICONS.user },
      { path: PATH_DASHBOARD.riders.root, icon: ICONS.rider },
      { path: PATH_DASHBOARD.chat.new, icon: ICONS.chat },
      { path: PATH_DASHBOARD.transaction.transactionList, icon: ICONS.article },
      { path: PATH_DASHBOARD.coupon.coupons, icon: ICONS.coupon },
      { path: PATH_DASHBOARD.notification.notifications, icon: ICONS.bell },
      { path: PATH_DASHBOARD.business.businessList, icon: ICONS.kanban },
      { path: PATH_DASHBOARD.rate.rates, icon: ICONS.setting },
    ],
  },
];

const adminNavConfig = [
  {
    subheader: "",
    items: [
      { path: PATH_DASHBOARD.general.app, icon: ICONS.dashboardd },
      { path: PATH_DASHBOARD.cities.cities, icon: ICONS.city },
      { path: PATH_DASHBOARD.managers.managers, icon: ICONS.manager },
      { path: PATH_DASHBOARD.packages.packages, icon: ICONS.package },
      { path: PATH_DASHBOARD.defaultNotification.notifications, icon: ICONS.bell },
      
    ],
  },
];

export { managerNavConfig, adminNavConfig };
