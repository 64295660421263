import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import { TextField, Autocomplete, Button, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "../../utils/axiosconfig";
import { RHFSelect } from "../../components/hook-form";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

const formulas = [
  { label: "(=) Equal to ", value: "==" },
  { label: "(<) LessThan", value: "<" },
  { label: "(>) GreaterThan", value: ">" },
  { label: "(<=) LessThan Equal to", value: "<=" },
  { label: "(=>) GreaterThan Equal to", value: "=>" },
];

function Filter({
  setIsFormula,
  onFormulaData,
  onsetFormulaData,
  isEdit,
  formulaEditData,
  handleFormulaData,
  editingRow,
  setOperationEditingRow,
}) {
  const [field, setField] = useState("display_name");
  const [value, setValue] = useState("");
  const [operator, setOperator] = useState("");
  const [operatorLabel, setOperatorLabel] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [fieldList, setFieldList] = useState([]);
  const [createdDate, setCreatedDate] = useState(new Date());
  const handleScheduleTimeChange = (scheduleTime) => {
    const scheduleDate = new Date(scheduleTime);
    setCreatedDate(scheduleDate);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setField("display_name");
    setOperator("");
    setOperatorLabel("");
    setValue("");
  };

  useEffect(() => {
    axios
      .get("/users/get/fields")
      .then((res) => {
        setFieldList(res.data.result);
        console.log(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (isEdit && formulaEditData) {
      setOperator(formulaEditData?.operator);
      setField(formulaEditData?.field);
      if (formulaEditData?.field === "created_time") {
        const dateObj = formulaEditData?.value;
        if (dateObj)
          setCreatedDate(
            new Date(dateObj._seconds * 1000 + dateObj._nanoseconds / 1000000)
          );
        else setCreatedDate(new Date());
      } else if (formulaEditData?.field === "isActive") {
        setIsActive(formulaEditData?.value);
      } else {
        setValue(formulaEditData?.value);
      }
      const formula = formulas.filter(
        (f) => f.value === formulaEditData?.operator
      )[0];
      setOperatorLabel(formula.label);
    }
  }, [isEdit, formulaEditData]);

  const handleFormulaChange = (event, value) => {
    const formula = formulas.filter(
      (formula) => formula.value === value.value
    )[0];
    setOperatorLabel(formula.label);
    setOperator(value.value);
  };

  const handleAddFormula = () => {
    console.log("Testing ...........", field, operator, value);
    if (operator === "") {
      enqueueSnackbar("Please select operator.", {
        variant: "error",
        style: { marginTop: "100px" },
      });
      return;
    } else if (
      value === "" &&
      field !== "isActive" &&
      field !== "created_time"
    ) {
      enqueueSnackbar("Please enter value for filter.", {
        variant: "error",
        style: { marginTop: "100px" },
      });
      return;
    }
    const getValue = (field) => {
      if (field === "isActive") return isActive;
      else if (field === "created_time") return createdDate;
      else return value;
    };
    if (editingRow) {
      onsetFormulaData([]);
      handleFormulaData({
        ...formulaEditData,
        field: field,
        operator: operator,
        value: getValue(field),
      });
      setOperationEditingRow(false);
    } else {
      onFormulaData({
        field: field,
        operator: operator,
        value: getValue(field),
      });
    }
    setOperator("");
    setValue("");
    setField("display_name");
    setOperatorLabel("");
  };

  const clearFilter = () => {
    setOperator("");
    setValue("");
    setField("display_name");
    onsetFormulaData([]);
  };
  return (
    <>
      <Box>
        <FilterListIcon
          style={{
            position: "relative",
            top: "0px",
            left: "13px",
          }}
          value={true}
        />
        <Box sx={{ width: "500px" }}>
          <Typography sx={{ marginBottom: "5px" }}>Field</Typography>
          <Box style={{ width: "324px", BorderBottom: "1px solid #7a7a7a" }}>
            <RHFSelect
              sx={{ width: "100%", marginBottom: "10px" }}
              style={{ color: "grey", fontSize: "13px" }}
              name="field"
              value={field}
              defaultValue="flat"
              onChange={(e) => setField(e.target.value)}
              required
            >
              {fieldList.length > 0 &&
                fieldList.map((item) => (
                  <option value={item?.value}>{item?.label}</option>
                ))}
            </RHFSelect>
          </Box>
          <Typography sx={{ margin: "5px 0px" }}>Operator</Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={formulas}
            sx={{ width: 300 }}
            onChange={handleFormulaChange}
            renderInput={(params) => <TextField {...params} label="Formula" />}
            value={operatorLabel || ""}
            style={{
              color: "#6e6e6e",
              fontSize: "14px",
              fontWeight: "500",
              marginBottom: "20px",
              width: "500px",
            }}
          />
          {field === "isActive" && (
            <Box>
              <Typography className="para_label" variant="h5">
                {isActive ? "Active" : "Inactive"}
              </Typography>
              <Switch
                name="promoted"
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                  setValue(e.target.value);
                }}
                color="warning"
              />
            </Box>
          )}{" "}
          {(field === "display_name" || field === "phone_number") && (
            <TextField
              sx={{ width: "500px" }}
              type={"text"}
              placeholder={
                field === "display_name"
                  ? "Enter Value for filter"
                  : "Enter mobile number with code: +91"
              }
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          )}
          {field === "created_time" && (
            <Box sx={{ mt: 3, display: "flex", alignItems: "center", gap: 2 }}>
              <Typography>Created Date Time</Typography>
              <Box>
                <DateTimePicker
                  format="dd/MM/yyyy"
                  name="created"
                  label="Created Date Time"
                  value={createdDate}
                  onChange={handleScheduleTimeChange}
                  className="custom-calendar"
                  calendarClassName="rasta-stripes"
                />
              </Box>
            </Box>
          )}
          <Box
            style={{
              marginTop: "20px",
              height: "43px",
              backgroundColor: "#ffff",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <button className="filter_button" onClick={() => clearFilter()}>
                Clear
              </button>
            </Box>
            <Box>
              <button
                className="filter_button"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </button>
              <Button variant="contained" onClick={handleAddFormula}>
                {editingRow ? "Save" : "Add"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default Filter;
