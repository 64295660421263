import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups, reset } from "src/redux/slices/GetGroups";
import LoadingScreen from "src/components/LoadingScreen";
import { getFields } from "../users/groupList";
import { getDateTime } from "src/utils/methods";

const TABLE_HEAD = [
  { id: "checkbox", label: "", alignRight: false },
  { id: "name", label: "Group Name", alignRight: false },
  { id: "company", label: "Formula", alignRight: false },
  { id: "role", label: "Auto", alignRight: false },
];

export default function GroupList({
  groupsList,
  selectedGroups,
  setData,
  setSelectedGroups,
  setGroupsList,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getGroups);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [groupList, setGroupList] = useState([]);
  const [page, setPage] = useState(1);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updated, setUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const order="asc";
  const orderBy="name";
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleClick = (row) => {
    const selectedIndex = selectedGroups.indexOf(row?.id);
    let newSelected = [];
    let newGroupsList = [];

    if (selectedIndex === -1) {
      // If the row is not selected, add it to the newSelected array
      newSelected = [...selectedGroups, row?.id];
      newGroupsList = [...groupsList, row];
      setGroupsList(newGroupsList);
    } else {
      // If the row is selected, remove it from both arrays
      newSelected = selectedGroups.filter((id) => id !== row?.id);
      newGroupsList = groupsList.filter((group) => group.id !== row.id);
      setGroupsList(newGroupsList);
    }

    setData((prevState) => ({ ...prevState, groups: newGroupsList }));
    setSelectedGroups(newSelected);
  };

  const isNotFound = groupList?.length === 0 ? true : false;
  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    if (filterValue?.length > 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page]);
  useEffect(() => {
    getGroup();
  }, [state.data]);
  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.managerId = localStorage.getItem("manager_id");
      payload.type = "all";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;
      console.log("groups payload........", payload);
      await axios
        .post("/groups/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchGroups(payload));
          } else {
            setGroupList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("error in group", err);
        });
    }
  };
  const getValue = (value) => {
    if (typeof value === "object") return getDateTime(value);
    else if (typeof value === "boolean") return value ? "true" : "false";
    else return value;
  };
  const getGroup = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchGroups(payload));
      } else {
        setGroupList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Groups">
        <Box sx={{ width: "100%" }}>
          <Card sx={{ borderRadius: "0" }}>
            <UserListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}
            />

            <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={groupList.length}
                  />
                  <TableBody>
                    {groupList?.map((row) => {
                      const isItemSelected =
                        selectedGroups.indexOf(row.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={() => handleClick(row)}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {row.groupName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {row.formula.map((item) => (
                              <div>
                                {getFields(item?.field)} {item.operator}{" "}
                                {getValue(item?.value)}
                              </div>
                            ))}
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              color={row.isAuto === false ? "error" : "success"}
                            >
                              {row.isAuto ? "Yes" : "No"}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {isNotFound && (
                      <TableRow>
                        <TableCell align="center" sx={{ py: 3 }} colSpan={8}>
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </Page>
    </ThemeProvider>
  );
}
