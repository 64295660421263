import React,{useState ,useMemo} from 'react';

export const  MainContext =React.createContext();
export default function MainContextWrapper({children , value}){
    const [globalState , setGlobalState] =useState(value);

    const payLoad= useMemo(()=>{
        return{...globalState,setGlobalState}
    })
    return(
        <MainContext.Provider value={payLoad}>{children}</MainContext.Provider>
    )
}