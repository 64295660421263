import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import axios from "../../utils/axiosconfig";
import {
  UserListHead,
  UserListToolbar,
} from "../../sections/@dashboard/user/list";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "src/components/LoadingScreen";
import { fetchNotifications, reset } from "src/redux/slices/GetNotifications";
import Image from "src/components/Image";
import avatar from "../../images/avatar.svg";

const TABLE_HEAD = [
  { id: "checkbox", label: "", alignRight: false },
  { id: "icon", label: "Icon", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
];

// ----------------------------------------------------------------------

export default function CouponList({
  selectedNotification,
  setData,
  setSelectedNotification,
}) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.getNotifications);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [couponList, setCouponList] = useState([]);
  const [page, setPage] = useState(1);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [updated, setUpdated] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const order = "asc";
  const orderBy = "name";
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  const handleFilterByName = (filterName) => {
    setFilterName(filterName);
    setPage(1);
  };

  const handleClick = (row) => {
    const isSelected = selectedNotification.includes(row?.id);

    setData((preState) => ({
      ...preState,
      notification: isSelected ? null : row,
    }));

    // If the row is already selected, remove it; otherwise, add it
    setSelectedNotification((prevSelected) =>
      isSelected ? prevSelected.filter((id) => id !== row?.id) : [row?.id]
    );
  };

  const isNotFound = couponList?.length === 0 ? true : false;

  useEffect(() => {
    dispatch(reset([]));
  }, [dispatch]);
  useEffect(() => {
    if (filterValue?.length > 0 && searchValue?.length >= 0) {
      const timeoutId = setTimeout(() => {
        filterSearch();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [searchValue]);
  useEffect(() => {
    filterSearch();
  }, [rowsPerPage, page]);
  useEffect(() => {
    getCoupon();
  }, [state.data]);

  const filterSearch = async () => {
    if (state.data.length !== 0 && !initialLoad) {
      setLoading(true);
      const payload = {};
      payload.managerId = localStorage.getItem("manager_id");
      payload.type = "all";
      payload.filter = filterValue;
      payload.search = searchValue;
      payload.page = page;
      payload.limit = rowsPerPage;

      await axios
        .post("/notifications/get/all", payload)
        .then((res) => {
          if (updated) {
            const payload = {};
            payload.type = "all";
            payload.filter = "";
            payload.search = "";
            payload.page = page;
            payload.limit = rowsPerPage;
            dispatch(fetchNotifications(payload));
          } else {
            setCouponList(res?.data?.result || []);
            setTotalItems(res?.data?.pagination?.totalItems || 0);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getCoupon = async () => {
    try {
      if (state.data.length === 0) {
        const payload = {};
        payload.type = "all";
        payload.filter = "";
        payload.search = "";
        payload.page = page;
        payload.limit = rowsPerPage;
        dispatch(fetchNotifications(payload));
      } else {
        setCouponList(state?.data?.data?.result || []);
        setTotalItems(state?.data?.data?.pagination?.totalItems || 0);
        setFilterData(state?.data?.data?.filters || []);
        setInitialLoad(false);
        setLoading(false);
        if (updated) setUpdated(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          zIndex: 1000,
          marginTop: 400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading && <CircularProgress />}
      </div>
      {state.isLoading && initialLoad && <LoadingScreen />}
      <Page title="Notifications">
        <Box sx={{ width: "100%" }}>
          <Card sx={{ borderRadius: "0" }}>
            <UserListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              setFilterValue={setFilterValue}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              filterData={filterData}
            />
            <TableContainer sx={{ minWidth: 800, height: "calc(80vh)" }}>
              <Scrollbar>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={couponList.length}
                  />
                  <TableBody>
                    {couponList?.map((row) => {
                      const isItemSelected =
                        selectedNotification.indexOf(row.id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={row.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={() => handleClick(row)}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {row?.icon?.length > 0 ? (
                              <Image
                                disabledEffect
                                alt="image"
                                src={row?.icon}
                                sx={{
                                  borderRadius: "50%",
                                  width: 64,
                                  height: 64,
                                }}
                              />
                            ) : (
                              <Image
                                disabledEffect
                                alt="image"
                                src={avatar}
                                sx={{
                                  borderRadius: "50%",
                                  width: 64,
                                  height: 64,
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.title}
                          </TableCell>
                          <TableCell width={"50%"}>{row?.message}</TableCell>
                        </TableRow>
                      );
                    })}
                    {isNotFound && (
                      <TableRow>
                        <TableCell align="center" sx={{ py: 3 }} colSpan={15}>
                          No data found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 100, 250]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={(e, page) => setPage(page + 1)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </Page>
    </ThemeProvider>
  );
}
