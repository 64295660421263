import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import GetUsers from "./slices/GetUsers";
import GetBookings from "./slices/GetBookings";
import GetGroups from "./slices/GetGroups";
import GetTransactions from "./slices/GetTransactions";
import GetRiders from "./slices/GetRiders";
import GetCoupons from "./slices/GetCoupons";
import GetBusinesses from "./slices/GetBusinesses";
import GetBusinessBookings from "./slices/GetBusinessBookings";
import GetUsersBusiness from "./slices/GetUsersBusiness";
import GetUserBookings from "./slices/GetUserBookings";
import GetCities from "./slices/GetCities";
import GetPackages from "./slices/GetPackages";
import GetLoginUser from "./slices/GetLoginUser";
import GetManagers from "./slices/GetManagers";
import GetNotifications from "./slices/GetNotifications";
import GetDefaultNotifications from "./slices/GetDefaultNotifications";
import GetCampaigns from "./slices/GetCampaigns";
import GetRates from "./slices/GetRates";
// ----------------------------------------------------------------------

// const rootPersistConfig = {
//   key: "root",
//   storage,
//   keyPrefix: "redux-",
//   whitelist: [
//     "getUsers",
//     "getGroups",
//     "getRiders",
//     "getBookings",
//     "getTransactions",
//     "getBusinesses",
//     "getCoupons",
//   ],
// };

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["getLoginUser"],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  getUsers: GetUsers,
  getBookings: GetBookings,
  getCampaigns: GetCampaigns,
  getGroups: GetGroups,
  getTransactions: GetTransactions,
  getBusinessBookings: GetBusinessBookings,
  getUserBookings: GetUserBookings,
  getUsersBusiness: GetUsersBusiness,
  getRiders: GetRiders,
  getCoupons: GetCoupons,
  getNotifications: GetNotifications,
  getDefaultNotifications: GetDefaultNotifications,
  getCities: GetCities,
  getRates: GetRates,
  getManagers: GetManagers,
  getPackages: GetPackages,
  getBusinesses: GetBusinesses,
  getLoginUser: GetLoginUser,
});

export { rootPersistConfig, rootReducer };
