import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useForm } from "react-hook-form";
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH } from "../../../routes/paths";
import Iconify from "../../../components/Iconify";
import axios from "../../../utils/axiosconfig";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { setLoginUser } from "src/redux/slices/GetLoginUser";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = useState(false);
  const methods = useForm({
    // resolver: yupResolver(LoginSchema),
    // defaultValues,
  });
  const history = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const storedEmail = localStorage.getItem("storeUsername");
    const storePassword = localStorage.getItem("storePassword");
    if (storedEmail && storePassword) {
      setChecked(true);
      setEmail(storedEmail);
      setPassword(storePassword);
    }
  }, []);
  useEffect(() => {
    if (checked) {
      localStorage.setItem("storeUsername", email);
      localStorage.setItem("storePassword", password);
    }
  }, [checked,email, password]);
  const handleCheckClick = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("storeUsername", email);
      localStorage.setItem("storePassword", password);
    } else {
      localStorage.removeItem("storeUsername");
      localStorage.removeItem("storePassword");
    }
  };
  const getManager = (managerId) => {
    axios
      .get(`managers/get/uId/${managerId}`)
      .then((res) => {
        const result = res.data.result;
        dispatch(setLoginUser(result));
        console.log("testing88........", result.data, result.role);
        if (result?.role === "MANAGER_ROLE") {
          localStorage.setItem("manager_city", result?.data?.cityId);
          localStorage.setItem("manager_id", result?.data?.id);
          localStorage.setItem("isAuthenticated", true);
          setLoading(false);
          history("/dashboard");
        } else if (result?.role === "ADMIN_ROLE") {
          setLoading(false);
          localStorage.setItem("isAuthenticated", true);
          history("/dashboard");
        } else {
          setLoading(false);
          return;
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong.", { variant: "error" });
        setLoading(false);
        console.log("error", err);
      });
  };
  const handleLogin = (e) => {
    setLoading(true);
    localStorage.clear();
    if (checked) {
      localStorage.setItem("storeUsername", email);
      localStorage.setItem("storePassword", password);
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((obj) => {
        obj.user?.getIdToken(true).then((idToken) => {
          console.log(idToken);
          localStorage.setItem("pickup-ninja-token", idToken);
          getManager(obj.user?.uid);
        });
      })
      .catch((err) => {
        setLoading(false);
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabeled":
          case "auth/user-not-found":
            enqueueSnackbar(err.message, { variant: "error" });
            break;
          case "auth/wrong-password":
            enqueueSnackbar(err.message, { variant: "error" });
            break;
          default:
            enqueueSnackbar(err.message, { variant: "error" });
            break;
        }
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleLogin)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />

        <RHFTextField
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox
          name="remember"
          label="Remember me"
          value={checked}
          onChange={handleCheckClick}
        />
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
